<template>
    <v-container class="ma-0 pa-0 full-width d-inline">
        <v-container class="widget-last-trans-container-card pa-0">
            <v-row class="ma-2 pa-4">
                <div class="d-flex float-left"><img src="@/assets/icons/rewatch.svg" /></div>
                <div class="d-flex flex-auto flex-column ml-4 title-font">
                    {{ $t('bo.dashboard.widgets.historyWidget.title') }}
                </div>
                <div class="d-flex flex-auto flex-column text-right">
                    <router-link :to="{ name: 'account:transactions' }" class="complete-history-title"
                        >{{ $t('bo.dashboard.widgets.historyWidget.completeHistory') }} ></router-link
                    >
                </div>
            </v-row>
            <v-row class="ma-2 pa-0 mt-4 ">
                <last-transactions-history-table
                    class="full-width"
                    :wallets="walletsList"
                    :walletsLoading="!walletsListFetched"
                    :fetch-error="transactionsError"
                    :items="transactions"
                    :loading="!transactionsFetched"
                />
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import LastTransactionsHistoryTable from '@/components/ui/tables/LastTransactionsHistoryTable';
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    export default {
        name: 'VtWidgetLastTransactions',

        components: {
            LastTransactionsHistoryTable
        },

        props: {
            full: Boolean
        },

        data: () => ({
            transactionsTable: {
                preAppliedFilters: {
                    currency: {
                        EFK: true,
                        USDT: true,
                        CZK: true
                    }
                },
                customDefaultFilters: {
                    currency: {
                        EFK: true,
                        USDT: true,
                        CZK: true
                    }
                },
                filters: {
                    currency: {
                        EFK: true,
                        USDT: true,
                        CZK: true
                    }
                },
                page: 1
            }
        }),

        computed: {
            ...mapGetters('widgets', {
                walletsList: 'data:balances',
                walletsListFetched: 'dataFetched'
            }),

            transactions() {
                return this.$store.getters['widgets/data:transactions'];
            },
            transactionsFetched() {
                return this.$store.getters['widgets/dataFetched'];
            },
            transactionsError() {
                return this.$store.getters['widgets/dataError'];
            }
        },

        methods: {
            fetchTransactions(page) {
                this.transactionsTable.page = page;

                this.$store.dispatch('transactions/transactions:clear').then(() => {
                    this.$store
                        .dispatch('transactions/transactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },
            filterTransactions(filters) {
                this.transactionsTable.page = 1;
                this.transactionsTable.filters = filters;

                this.$store.dispatch('transactions/transactions:clear').then(() => {
                    this.$store
                        .dispatch('transactions/transactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.transactionsFilter) {
                this.$set(this.transactionsTable, 'preAppliedFilters', this.transactionsFilter);
            }

            if (!this.transactionsFetched) {
                this.fetchTransactions();
            }
        }
    };
</script>

<style scoped lang="scss"></style>
