<template>
    <div>
        <validation-observer ref="formCurrencyWithdrawalDialogCZK">
            <app-row justify="center">
                <app-registration-col>
                    <p>
                        {{ $t('bo.wallets.currencyWithdrawalDialog.minimumAmount') }}
                        <mark>{{ formatAmount(minimalAmountWithdraw, currency) }} {{ currency }} </mark>.
                    </p>
                    <p>
                        {{ $t('bo.wallets.currencyWithdrawalDialog.walletBalance') }}
                        <mark>
                            {{ formatAmount(walletBalance, currency) }}
                            {{ currency }} </mark
                        >.
                    </p>
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col>
                    <app-text-field
                        :label="$t('bo.wallets.currencyWithdrawalDialog.amount')"
                        v-model="withdrawal.amount"
                        @input="$emit('change', withdrawal)"
                        :rules="{
                            required: true,
                            regex: numberRegex,
                            min_value: [minimalAmountWithdraw, currency],
                            max_value: [walletBalance, currency],
                            decimals: 2
                        }"
                    >
                        <template #append>
                            {{ currency }}
                        </template>
                    </app-text-field>
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col>
                    <p>{{ $t('bo.wallets.currencyWithdrawalDialog.bankAccount') }}</p>
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col>
                    <app-card text-white increased-shadow narrow v-color:broker>
                        <app-title text-white justify="center">{{ bankNumber.getBankAccount() }}</app-title>

                        <app-row justify="center">
                            <app-registration-col>
                                <p style="color: #f0f0f0">{{ bankNumber.getBankName() }}</p>
                                <p style="color: #f0f0f0">{{ bankNumber.getBankIBAN() }}</p>
                                <p style="color: #f0f0f0">{{ bankNumber.getBankSwift() }}</p>
                            </app-registration-col>
                        </app-row>
                    </app-card>
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col>
                    <button-secondary small inline @click="redirectToAccount">
                        <app-logo logo-only size="xs" class="mr-2" />

                        <span v-color:world>
                            {{ $t('bo.wallets.currencyWithdrawalDialog.editBankAccount') }}
                        </span>
                    </button-secondary>
                </app-registration-col>
            </app-row>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { mapGetters } from 'vuex';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import { getAppUrlFormat } from '@/utils';

    import AppTextField from '@/components/ui/form/AppTextField';

    export default {
        name: 'WithdrawalCZK',

        components: { AppTextField, ValidationObserver },

        props: ['minimalAmountWithdraw', 'walletBalance', 'currency', 'withdrawal'],

        computed: {
            ...mapGetters('user', {
                bankNumber: 'user:bankAccount'
            }),

            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            }
        },

        methods: {
            redirectToAccount() {
                window.open(getAppUrlFormat('world', '/account/account'));
            }
        }
    };
</script>

<style scoped></style>
