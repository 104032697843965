<template>
    <div class="full-width">
        <v-container class="ma-0 pa-0">
            <currency-withdrawal-dialog :dialog="currencyWithdrawalDialog" @withdrawal:successful="fetchWallets" />
            <currency-transfer-dialog :dialog="currencyTransferDialog" @transfer:successful="fetchWallets" />
            <currency-purchase-dialog :dialog="currencyPurchaseDialog" @transfer:successful="fetchWallets" />
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                    <wallets-container
                        dashboard
                        @open:withdrawal="openWithdrawalDialog"
                        @open:transfer="openTransferDialog"
                        @open:purchase="openPurchaseDialog"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { BASE_CRYPTO_CURRENCY, BASE_CURRENCY } from '@/constants';

    import { mapGetters } from 'vuex';

    import CurrencyWithdrawalDialog from '@/components/module-user/wallets/CurrencyWithdrawalDialog';
    import CurrencyTransferDialog from '@/components/module-user/wallets/CurrencyTransferDialog';
    import CurrencyPurchaseDialog from '@/components/module-user/wallets/CurrencyPurchaseDialog';
    import WalletsContainer from '@/components/module-user/wallets/VtWalletsContainer';

    export default {
        name: 'Wallets',

        components: {
            WalletsContainer,
            CurrencyTransferDialog,
            CurrencyPurchaseDialog,
            CurrencyWithdrawalDialog
        },

        computed: mapGetters('wallets', {
            walletsFetched: 'wallets:fetched',
            walletsData: 'wallets:data',
            walletChartData: 'wallet:chartData'
        }),

        data: () => ({
            currencyWithdrawalDialog: {
                open: false
            },
            currencyPurchaseDialog: {
                open: false
            },
            currencyTransferDialog: {
                open: false
            }
        }),

        methods: {
            openWithdrawalDialog(params, confirmationRequired) {
                this.currencyWithdrawalDialog.params = params;
                this.currencyWithdrawalDialog.confirmationRequired = confirmationRequired;
                this.currencyWithdrawalDialog.open = true;
            },
            openTransferDialog(params) {
                this.currencyTransferDialog.params = params;
                this.currencyTransferDialog.open = true;
            },
            openPurchaseDialog(params) {
                this.currencyPurchaseDialog.params = params;
                this.currencyPurchaseDialog.open = true;
            },
            getWalletGraphRates() {
                for (const wallet of this.walletsData) {
                    if (wallet.currency === 'CZK') {
                        this.$store
                            .dispatch('exchangeRates/history:fetch', {
                                quoteCurrency: BASE_CURRENCY,
                                baseCurrency: wallet.currency
                            })
                            .catch(error => {
                                EventBus.$emit('snackbar:show:danger', error);
                            });
                    } else if (wallet.currency === 'EFK') {
                        this.$store
                            .dispatch('exchangeRates/history:fetch', {
                                quoteCurrency: wallet.currency,
                                baseCurrency: BASE_CRYPTO_CURRENCY
                            })
                            .catch(error => {
                                EventBus.$emit('snackbar:show:danger', error);
                            });
                    }
                }
            },
            fetchWallets() {
                this.$store.dispatch('wallets/clear').then(() => {
                    this.$store
                        .dispatch('wallets/fetch')
                        .then(() => {
                            this.$store.dispatch('exchangeRates/clear').then(() => {
                                this.$store.dispatch('exchangeRates/fetch');
                            });

                            this.getWalletGraphRates();
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (!this.walletsFetched) {
                this.fetchWallets();
            }
        }
    };
</script>

<style scoped></style>
