<template>
    <app-section-navigation-col bigger-col class="pl-0">
        <app-card class="py-9 app-card-style create-transaction-style">
            <app-loader v-if="!productFetched" />

            <validation-observer ref="formEFKPurchase" v-slot="{ invalid }" v-else>
                <app-row>
                    <app-col class="mb-0" full-space>
                        <app-text-field
                            v-model="parameters.purchaseAmount"
                            type="number"
                            :step="product.purchaseAmountStep"
                            :rules="{
                                required: true,
                                max_value: [product.maximalPurchaseAmount, currency],
                                min_value: [product.minimalPurchaseAmount, currency],
                                multiple: product.purchaseAmountStep
                            }"
                            :label="$t('bo.products.efkPurchase.create.purchaseAmount')"
                        >
                            <template #append> {{ currency }} </template>v
                        </app-text-field>

                        <div class="mt-4">
                            <app-text-field
                                v-model="parameters.maxPurchaseRate"
                                type="number"
                                :step="product.purchaseRateStep"
                                :rules="{
                                    required: true,
                                    decimals: 4,
                                    regex: rateRegexp,
                                    min_value: 0.01,
                                    max_value: 0.2
                                }"
                                :label="$t('bo.products.common.maxPurchaseRate')"
                            >
                                <template #append>
                                    USD/EFK
                                </template>
                            </app-text-field>
                        </div>
                    </app-col>
                </app-row>

                <app-text note justify="start">
                    <span
                        v-if="product.minimalPurchaseAmount"
                        v-html="
                            $t('bo.products.efkPurchase.create.minimalPurchaseAmount', {
                                amount: formatAmount(product.minimalPurchaseAmount)
                            })
                        "
                    />

                    <span
                        v-if="product.maximalPurchaseAmount"
                        v-html="
                            $t('bo.products.efkPurchase.create.maximalPurchaseAmount', {
                                amount: formatAmount(product.maximalPurchaseAmount)
                            })
                        "
                    />
                </app-text>

                <app-text note justify="start">
                    <span
                        v-html="
                            $t('bo.products.efkPurchaseStake.create.feePurchaseLabel', {
                                fee: 6
                            })
                        "
                    />
                </app-text>

                <app-divider />

                <app-row>
                    <app-col full-space>
                        <app-switch-field
                            :centered="$vuetify.breakpoint.xsOnly"
                            button-events
                            v-model="acceptTerms"
                            @button:click="openProductDocument"
                            :button-loading="productDocumentButtonLoading"
                        >
                            {{ $t('bo.products.efkPurchase.create.agree') }}

                            <template v-slot:button>
                                {{ $t('bo.products.download.efkPurchaseStakeProductConditions') }}
                            </template>
                        </app-switch-field>
                    </app-col>
                </app-row>

                <app-row>
                    <app-col full-space>
                        <button-wrapper :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'start'">
                            <vt-button-primary
                                full-width
                                @click="submit"
                                :disabled="invalid || !acceptTerms || !userVerified"
                                :loading="submitButtonLoading"
                            >
                                {{ $t('bo.products.efkPurchaseStake.create.submit') }}
                            </vt-button-primary>
                        </button-wrapper>
                    </app-col>
                </app-row>
            </validation-observer>
        </app-card>
    </app-section-navigation-col>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import { ValidationObserver } from 'vee-validate';

    import { DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE_STAKE } from '@/constants';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    import AppCard from '../../../ui/AppCard';
    import AppTextField from '../../../ui/form/AppTextField';
    import AppCol from '../../../ui/layout/AppCol';
    import AppSwitchField from '../../../ui/form/AppSwitchField';
    import AppSectionNavigationCol from '@/components/ui/layout/AppSectionNavigationCol';

    export default {
        name: 'CreateTransaction',

        components: {
            AppSectionNavigationCol,
            AppSwitchField,
            AppCol,
            AppTextField,
            AppCard,
            ValidationObserver
        },

        props: ['productId', 'product', 'productFetched'],

        data: () => ({
            parameters: {
                purchaseAmount: '',
                maxPurchaseRate: ''
            },
            productDocumentButtonLoading: false,
            submitButtonLoading: false,
            acceptTerms: false,
            currency: 'CZK',
            rateRegexp: getDecimalNumberRegex()
        }),

        computed: {
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },

            userVerified() {
                return this.$store.getters['auth/token:role'](UserRoles.VERIFIED);
            }
        },

        methods: {
            hasLimitAvailable(remaining) {
                return remaining === null || remaining - this.parameters.purchaseAmount > 0;
            },
            openProductDocument() {
                this.productDocumentButtonLoading = true;

                const windowRef = window.open();

                this.$store
                    .dispatch('documents/document:request', DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE_STAKE  + '--' + this.$i18n.locale.substring(0, 2))
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.productDocumentButtonLoading = false;
                    });
            },
            processPurchase() {
                const productId = this.productId;
                const parameters = this.deepCopy(this.parameters);

                parameters.purchaseAmount = this.convertLocaleToNumber(parameters.purchaseAmount);

                this.$store
                    .dispatch('products/PA:open', {
                        parameters,
                        productId
                    })
                    .then(product => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                        this.$store.dispatch('widgets/fetch');

                        this.$emit('transaction:successful', {
                            product: {
                                productName: 'efkPurchaseStake',
                                productId: product.id,
                                openedAt: product.openedAt,
                                purchaseAmount: product.purchaseAmount,
                                purchaseCurrency: product.purchaseCurrency,
                                maxPurchaseRate: product.maxPurchaseRate
                            },
                            payment: {
                                paymentIdentifier: product.paymentIdentifier
                            }
                        });

                        this.$refs.formEFKPurchase.reset();

                        this.parameters.purchaseAmount = '';
                        this.parameters.maxPurchaseRate = '';

                        this.acceptTerms = false;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formEFKPurchase
                    .validate()
                    .then(result => {
                        if (result) {
                            if (this.product?.checkAMLLimit) {
                                this.$log.debug('[AML] This product requires AML check');

                                this.$store
                                    .dispatch('aml/userLimit:fetch')
                                    .then(userLimit => {
                                        const remainingLimit = userLimit?.remainingLimit;

                                        if (this.hasLimitAvailable(remainingLimit)) {
                                            this.processPurchase();
                                        } else if (!this.hasLimitAvailable(remainingLimit)) {
                                            this.$emit('limitDialog:open', {
                                                purchaseAmount: this.parameters.purchaseAmount,
                                                remainingLimit
                                            });

                                            this.submitButtonLoading = false;
                                        } else {
                                            EventBus.$emit('snackbar:show:danger', this.$t('general_error'));

                                            this.submitButtonLoading = false;
                                        }
                                    })
                                    .catch(error => {
                                        EventBus.$emit('snackbar:show:danger', error);

                                        this.submitButtonLoading = false;
                                    });
                            } else {
                                this.processPurchase();
                            }
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.submitButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
