<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('bo.wallets.currencyWithdrawalDialog.title')" :dialog="dialog" />

            <div v-if="dialog.confirmationRequired">
                <app-dialog-tabs centered :i18n-prefix="steps.i18nPrefix" :tabs="steps" :current-tab-index="tabIndex" />

                <component
                    :is="currentTab"
                    class="py-6"
                    :withdrawal="withdrawal"
                    :currency="currency"
                    :balance="balance"
                    @update:data="withdrawal = $event"
                    @process:prev="tabIndex -= 1"
                    @process:next="processNext"
                    @process:finished="processFinished"
                />
            </div>

            <withdrawal-data-step
                v-else
                :withdrawal="withdrawal"
                :currency="currency"
                :balance="balance"
                @update:data="withdrawal = $event"
                @process:next="processFinished"
            />
        </app-card>
    </v-dialog>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppCard from '@/components/ui/AppCard';
    import WithdrawalCZK from '@/components/module-user/wallets/withdrawalDialog/WithdrawalCZK';
    import WithdrawalEFK from '@/components/module-user/wallets/withdrawalDialog/WithdrawalEFK';
    import AppDialogTabs from '@/components/ui/tabs/AppDialogTabs';
    import WithdrawalConfirmationStep from '@/components/module-user/wallets/withdrawalDialog/WithdrawalConfirmationStep';
    import WithdrawalDataStep from '@/components/module-user/wallets/withdrawalDialog/WithdrawalDataStep';

    export default {
        name: 'CurrencyWithdrawalDialog',

        props: ['dialog'],

        components: {
            WithdrawalDataStep,
            AppDialogTabs,
            AppCard,

            EFK: WithdrawalEFK,
            CZK: WithdrawalCZK,

            stepConfirmation: WithdrawalConfirmationStep,
            stepData: WithdrawalDataStep,

            ValidationObserver
        },

        data: () => ({
            tabIndex: 0,

            withdrawal: null,
            currency: '',
            balance: '',

            steps: {
                type: 'steps',
                i18nPrefix: 'bo.wallets.currencyWithdrawalDialog',
                data: [{ name: 'stepData' }, { name: 'stepConfirmation' }]
            }
        }),

        methods: {
            controlWalletAmount(withdrawal) {
                return withdrawal.amount <= this.dialog.params.currencyBalance;
            },

            processNext(data) {
                this.withdrawal = data;

                if (this.dialog.confirmationRequired) {
                    this.tabIndex += 1;
                } else {
                    this.processFinished();
                }
            },

            processFinished() {
                const withdrawal = this.deepCopy(this.withdrawal);

                withdrawal.amount = this.convertLocaleToNumber(withdrawal.amount);

                if (this.controlWalletAmount(withdrawal)) {
                    this.$store
                        .dispatch('wallets/withdrawal', {
                            ...withdrawal,
                            currency: this.dialog?.params?.currency
                        })
                        .then(() => {
                            EventBus.$emit('snackbar:show:success', this.$t('success.currencyWithdrawal'));

                            this.$store.dispatch('widgets/clear').then(() => {
                                this.$store.dispatch('widgets/fetch');
                            });

                            this.$emit('withdrawal:successful');

                            this.dialog.open = false;
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        })
                        .finally(() => {
                            this.withdrawButtonLoading = false;
                        });
                } else {
                    EventBus.$emit('snackbar:show:danger', 'insufficient_balance_payment');

                    this.withdrawButtonLoading = false;
                }
            }
        },

        computed: {
            ...mapGetters('wallets', ['feesFetched']),

            currentTab() {
                return this.steps.data[this.tabIndex].name;
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.currency = this.dialog.params?.currency;

                        if (this.currency) {
                            switch (this.currency) {
                                case 'EFK':
                                    this.withdrawal = { amount: '', address: '' };
                                    break;

                                case 'CZK':
                                    this.withdrawal = { amount: '' };
                                    break;
                            }
                        }

                        this.balance = this.dialog.params?.currencyBalance;
                    }
                }
            }
        },

        mounted() {
            if (!this.feesFetched) {
                this.$store.dispatch('wallets/fee:fetch');
            }
        }
    };
</script>

<style scoped></style>
