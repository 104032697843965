import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import { formatAppliedFilters } from '@lb-world/core/public/components/tables/utils';

import TableDataset from '@lb-world/core/public/models/store/TableDataset';

import WalletRepository from '@lb-world/core/public/api/repositories/WalletRepository';

const state = {
    transactions: new TableDataset()
};

const getters = {
    transactions: state => state.transactions.data.items ?? [],
    'transactions:fetched': state => state.transactions.fetched,
    'transactions:filter': state => state.transactions.filter,
    'transactions:error': state => state.transactions.fetchError,
    'transactions:pages': state => state.transactions.getPages()
};

const actions = {
    'transactions:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'transactions');

            resolve();
        });
    },
    'transactions:fetch': ({ commit, rootGetters }, { filter, orderBy, page = 1, searchText = '' }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const userId = rootGetters['user/user:id'];

            const filters = { ...formatAppliedFilters(filter), user: userId };

            // TODO: orderBy and searchText not working at the moment, check with BE
            WalletRepository.getTransactions({ columnOptions: { filters }, apiOptions: { page, orderBy, searchText } })
                .then(response => {
                    const transactions = response.data;

                    Vue.$log.debug('[ACTION] Received response', transactions);

                    if (transactions) {
                        commit('store', { key: 'transactions', data: transactions, filter });

                        resolve();
                    } else {
                        commit('error', 'transactions');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'transactions');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data, filter }) => {
        state[key].storeData(data, filter);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
