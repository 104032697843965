<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('bo.aml.limitDialog.title')" :dialog="dialog" />

            <app-card dialog>
                <app-text justify="center">
                    {{ $t('bo.aml.limitDialog.limitNotSufficient') }}
                </app-text>

                <app-divider />

                <app-row justify="center">
                    <app-registration-col whole-screen>
                        <app-text-stack justify="center" bigger-value>
                            <template #key>
                                {{ $t('bo.aml.limitDialog.purchaseAmount') }}
                            </template>

                            {{ formatAmount(purchase.purchaseAmount, 'eur') }} CZK
                        </app-text-stack>
                    </app-registration-col>
                </app-row>

                <app-row>
                    <app-registration-col whole-screen>
                        <app-text-stack justify="center" bigger-value>
                            <template #key>
                                {{ $t('bo.aml.limitDialog.remainingLimit') }}
                            </template>

                            <span v-if="purchase.remainingLimit === null">
                                {{ $t('bo.aml.limitDialog.unlimited') }}
                            </span>

                            <span v-else>{{ formatAmount(purchase.remainingLimit, 'eur') }} CZK</span>
                        </app-text-stack>
                    </app-registration-col>
                </app-row>

                <app-row>
                    <app-registration-col whole-screen>
                        <app-text justify="center">
                            {{ $t('bo.aml.limitDialog.continueDescription') }}
                        </app-text>

                        <button-wrapper justify="center">
                            <button-primary @click="redirectToLimits(true)">
                                {{ $t('bo.aml.limitDialog.applyForLimit') }}
                            </button-primary>
                        </button-wrapper>
                    </app-registration-col>
                </app-row>

                <app-divider />

                <app-text justify="center">
                    {{ $t('bo.aml.limitDialog.managingLimits') }}
                </app-text>

                <app-row justify="center">
                    <app-col class="pt-0">
                        <button-secondary small @click="redirectToLimits">
                            <app-logo logo-only size="xs" class="mr-1"></app-logo>
                            <span v-color:world>
                                {{ $t('bo.aml.limitDialog.button') }}
                            </span>
                        </button-secondary>
                    </app-col>
                </app-row>
            </app-card>
        </app-card>
    </v-dialog>
</template>

<script>
    import { getAppUrlFormat } from '@/utils';

    import AppCard from '../../ui/AppCard';
    import AppCol from '../../ui/layout/AppCol';

    export default {
        name: 'LimitNotAvailableDialog',

        props: ['dialog'],

        components: {
            AppCol,
            AppCard
        },

        data: () => ({
            purchase: {
                purchaseAmount: 0,
                remainingLimit: 0
            }
        }),

        methods: {
            redirectToLimits(requestLimits) {
                const suffix = requestLimits ? `?limitRequest=${this.purchase.purchaseAmount}` : '';

                if (requestLimits) {
                    this.dialog.open = false;
                }

                window.open(getAppUrlFormat('world', `/account/purchase-limits${suffix}`));
            }
        },

        watch: {
            'dialog.open': {
                handler(opened) {
                    if (opened) {
                        if (this.dialog.purchase) {
                            this.purchase = this.dialog.purchase;
                        }

                        if (this.$refs.formWithdrawalConfirmationDialog) {
                            this.$refs.formWithdrawalConfirmationDialog.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
