<template>
    <div class="full-width">
        <activate-account-banner v-if="!hideTop" />
        <v-container class="ma-0 ma-md-5 pa-0">
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                    <v-container class="ma-0 pa-0 full-width">
                        <v-container class="container-card">
                            <div class="d-flex ma-4">
                                <img src="@/assets/icons/rewatch.svg" />
                                <div class="ml-3 align-self-center transaction-history-font">
                                    {{ $t('bo.transactions.transactionsHistory') }}
                                </div>
                            </div>
                            <div class="d-flex ma-4">
                                <transactions-history-table
                                    class="full-width"
                                    :wallets="walletsList"
                                    :walletsLoading="!walletsListFetched"
                                    :fetch-error="transactionsError"
                                    :items="transactions"
                                    :loading="!transactionsFetched"
                                    :pages="transactionsPages"
                                    :filters.sync="transactionsTable.filters"
                                    :pre-applied="transactionsTable.preAppliedFilters"
                                    :custom-default="transactionsTable.customDefaultFilters"
                                    @searchText:change="fetchTransactions(transactionsTable.page, $event)"
                                    @orderBy:change="fetchTransactions(transactionsTable.page, $event)"
                                    @pagination:change="fetchTransactions"
                                    @filter:update="filterTransactions"
                                    @filter:reset="filterTransactions"
                                />
                            </div>
                        </v-container>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import TransactionsHistoryTable from '@/components/ui/tables/TransactionsHistoryTable';
    import ActivateAccountBanner from '@/components/ui/ActivateAccountBanner';
    import { pagination } from '@lb-world/core/public/components/tables/mixins';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    export default {
        name: 'Transactions',

        components: {
            TransactionsHistoryTable,
            ActivateAccountBanner
        },
        props: {
            hideTop: Boolean
        },
        mixins: [pagination],

        data: () => ({
            orderBy: 'createdAt=desc',
            transactionsTable: {
                searchText: 'searchText',
                preAppliedFilters: {
                    currency: {
                        EFK: true,
                        USDT: true,
                        CZK: true
                    }
                },
                customDefaultFilters: {
                    currency: {
                        EFK: true,
                        USDT: true,
                        CZK: true
                    }
                },
                filters: {
                    currency: {
                        EFK: true,
                        USDT: true,
                        CZK: true
                    }
                },
                page: 1
            }
        }),

        computed: {
            ...mapGetters('widgets', {
                walletsList: 'data:balances',
                walletsListFetched: 'dataFetched'
            }),

            transactions() {
                return this.$store.getters['transactions/transactions'];
            },
            transactionsFilter() {
                return this.$store.getters['transactions/transactions:filter'];
            },
            transactionsFetched() {
                return this.$store.getters['transactions/transactions:fetched'];
            },
            transactionsError() {
                return this.$store.getters['transactions/transactions:error'];
            },
            transactionsPages() {
                return this.$store.getters['transactions/transactions:pages'];
            }
        },

        methods: {
            fetchTransactions(page, orderBy) {
                this.transactionsTable.page = page;

                this.$store.dispatch('transactions/transactions:clear').then(() => {
                    this.$store
                        .dispatch('transactions/transactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters,
                            orderBy,
                            searchText: this.transactionsTable.searchText
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },
            filterTransactions(filters) {
                this.transactionsTable.page = 1;
                this.transactionsTable.filters = filters;

                this.$store.dispatch('transactions/transactions:clear').then(() => {
                    this.$store
                        .dispatch('transactions/transactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.transactionsFilter) {
                this.$set(this.transactionsTable, 'preAppliedFilters', this.transactionsFilter);
            }

            if (!this.transactionsFetched) {
                this.fetchTransactions();
            }
        }
    };
</script>

<style scoped lang="scss"></style>
