import Vue from 'vue';

export const MAINTENANCE_ROUTE = { name: 'maintenance' };
export const MAINTENANCE_STATUS = 503;

export const SERVER_ERROR_ROUTE = { name: 'serverError' };

export const checkForMaintenance = statusCode => {
    Vue.$log.debug('[MAINTENANCE] checkForMaintenance', statusCode);

    return statusCode === MAINTENANCE_STATUS;
};

export const checkForServerError = statusCode => {
    Vue.$log.debug('[MAINTENANCE] checkForServerError', statusCode);

    return statusCode >= 400 && statusCode !== 401 && statusCode !== 404;
};
