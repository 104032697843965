<template>
    <div class="ma-0 boxing">
        <div class="d-flex mx-2" :class="{ 'flex-wrap': $vuetify.breakpoint.mdAndDown }">
            <div class="d-flex flex-auto">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <div class="d-inline-flex">
                                <l-icon class="product-icon-dimension" secondary>EN</l-icon>
                                <div class="product-title-font pl-4">
                                    {{ $t('bo.products.efkPurchase.title') }}
                                </div>
                            </div>
                            <div class="ml-14 mt-4 about-font">
                                {{ $t('bo.products.efkPurchase.widget.about') }}
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <div v-for="benefit in benefits" :key="benefit" class="d-flex mb-3 items-baseline">
                                <img src="@/assets/icons/CircleCheckedPrimary.svg" />
                                <div class="ml-2 guarantees-font">
                                    {{ benefit.name }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <div
                                class="mx-2 flex-auto"
                                :class="{
                                    'text-center': $vuetify.breakpoint.xs,
                                    'text-right': $vuetify.breakpoint.lgAndUp
                                }"
                            >
                                <create-transaction-dialog
                                    iconText="EN"
                                    :heading="$t('bo.products.efkPurchase.title')"
                                    :product-id="productId"
                                >
                                    <app-card full-width v-if="!productFetched" class="py-9">
                                        <app-loader>{{ $t('common.loadingProduct') }}</app-loader>
                                    </app-card>
                                    <create-transaction
                                        v-else
                                        :product-id="productId"
                                        :product="product"
                                        :product-fetched="productFetched"
                                        show-transaction-subtype
                                        @transaction:successful="$emit('transaction:successful', $event)"
                                        @limitDialog:open="$emit('limitDialog:open', $event)"
                                        @editProductDialog:open="$emit('editProductDialog:open', $event)"
                                    />
                                </create-transaction-dialog>
                                <vt-button-secondary
                                    class="mt-2 ml-2"
                                    v-on="on"
                                    @click="openPDFTeaser"
                                    :loading="pdfTeaserButton.loading"
                                    icon
                                >
                                    <img height="17" class="mr-7" src="@/assets/icons/products-document.svg" />
                                    <span class="mr-7">
                                        {{ $t('bo.products.digiRent.widget.morePdfBtn') }}
                                    </span>
                                </vt-button-secondary>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <v-row class="ma-3">
            <v-col cols="12" md="6">
                <div class="smaller-title">
                    {{ $t('bo.products.efkPurchase.widget.forWho') }}
                </div>
                <div class="about-font">
                    {{ $t('bo.products.efkPurchase.widget.forWhoText') }}
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="smaller-title">
                    {{ $t('bo.products.efkPurchase.widget.fees') }}
                </div>
                <div class="about-font">
                    {{ $t('bo.products.efkPurchase.widget.feesText') }}
                </div>
            </v-col>
        </v-row>

        <v-row class="ma-3 text-center text-sm-left">
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/note.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkPurchase.sidebar.duration') }}
                    </div>
                    <div class="sidebar-value-font">
                        {{ $t('bo.products.efkPurchase.sidebar.durationText') }}
                    </div>
                </div>
            </v-col>
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/percentage.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkPurchase.sidebar.interestRate') }}
                    </div>
                    <div class="sidebar-value-font">
                        -
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row class="ma-3 text-center text-sm-left">
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/leaf.svg" style="width: 45px; height: 45px" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkPurchase.sidebar.minimalBuyPrice') }}
                    </div>
                    <div class="sidebar-value-font">
                        50.000 CZK
                    </div>
                </div>
            </v-col>
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/calendar.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkPurchase.sidebar.subscriptionPeriod') }}
                    </div>
                    <div class="sidebar-value-font">
                        -
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <img src="@/assets/icons/bag.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkPurchase.sidebar.maximalClientPurchase') }}
                    </div>
                    <div class="sidebar-value-font">
                        {{ $t('bo.products.efkPurchase.sidebar.notFixed') }}
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { DOCUMENT_PRODUCT_INTRO_EFK_PURCHASE, PRODUCT_ID_EFK_PURCHASE } from '@/constants';
    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import CreateTransactionDialog from '../VtCreateTransactionDialog';
    import CreateTransaction from '../../../../components/module-user/products/efkPurchase/CreateTransaction';

    export default {
        name: 'VtEfkPurchase',

        components: {
            CreateTransactionDialog,
            CreateTransaction
        },

        data: () => ({
            productId: PRODUCT_ID_EFK_PURCHASE,

            pdfTeaserButton: {
                loading: false
            }
        }),

        computed: {
            ...mapGetters('products', {
                productFilter: 'products:filter',
                productFetched: 'products:fetched',
                productError: 'products:error'
            }),
            benefits() {
                return [
                    {
                        name: this.$t('bo.products.efkPurchase.widget.guarantees.one')
                    },
                    {
                        name: this.$t('bo.products.efkPurchase.widget.guarantees.two')
                    },
                    {
                        name: this.$t('bo.products.efkPurchase.widget.guarantees.three')
                    },
                    {
                        name: this.$t('bo.products.efkPurchase.widget.guarantees.four')
                    },
                    {
                        name: this.$t('bo.products.efkPurchase.widget.guarantees.five')
                    },
                    {
                        name: this.$t('bo.products.efkPurchase.widget.guarantees.six')
                    },
                    {
                        name: this.$t('bo.products.efkPurchase.widget.guarantees.seven')
                    }
                ];
            },
            product() {
                return this.productFilter(PRODUCT_ID_EFK_PURCHASE);
            },
            productFetched() {
                return this.$store.getters['products/products:fetched'];
            }
        },

        methods: {
            openPDFTeaser() {
                this.pdfTeaserButton.loading = true;
                const windowRef = window.open();
                this.$store
                    .dispatch('documents/document:request', DOCUMENT_PRODUCT_INTRO_EFK_PURCHASE)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.pdfTeaserButton.loading = false;
                    });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
