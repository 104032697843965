<template>
    <div class="full-width">
        <activate-account-banner />
        <v-container class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
                <v-container class="container-card pa-0">
                    <app-error v-if="paDetailError" no-padding horizontal fetching />
                    <app-loader v-if="!paDetailFetched">
                        {{ $t('common.loadingProduct') }}
                    </app-loader>
                    <v-row v-else>
                        <v-col cols="12" sm="10" class="d-flex flex-wrap">
                            <div class="d-flex my-2 min-width-230">
                                <div class="d-flex place-self-center">
                                    <div class="d-inline-flex px-2">
                                        <img
                                            v-if="paDetail.state === 'open'"
                                            src="@/assets/icons/detail/state_green.svg"
                                        />
                                        <img v-else src="@/assets/icons/detail/state_red.svg" />
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-auto">
                                    <div class="mini-title">
                                        {{ $t('bo.products.portfolio.detail.status') }}
                                    </div>
                                    <div
                                        :class="{
                                            'state-product-open': paDetail.state === 'open',
                                            'state-product-closed': paDetail.state !== 'open'
                                        }"
                                    >
                                        {{ $t('bo.products.common.accountState.' + paDetail.state) }}
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex my-2 min-width-230">
                                <div class="d-flex place-self-center">
                                    <div class="d-inline-flex px-2">
                                        <img src="@/assets/icons/detail/id.svg" />
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-auto">
                                    <div class="mini-title">
                                        ID
                                    </div>
                                    <div class="mini-title-main">{{ id }}</div>
                                </div>
                            </div>
                            <div class="d-flex my-2 min-width-230">
                                <div class="d-flex place-self-center">
                                    <div class="d-inline-flex px-2">
                                        <img src="@/assets/icons/detail/calendar.svg" />
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-auto">
                                    <div class="mini-title">
                                        {{ $t('bo.products.portfolio.detail.open') }}
                                    </div>
                                    <div class="mini-title-main">{{ formatTimestamp(paDetail.openedAt) }}</div>
                                </div>
                            </div>
                            <div class="d-flex my-2 min-width-230">
                                <div class="d-flex place-self-center">
                                    <div class="d-inline-flex px-2">
                                        <img src="@/assets/icons/detail/amount.svg" />
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-auto">
                                    <div class="mini-title">
                                        {{ $t('bo.products.portfolio.detail.length') }}
                                    </div>
                                    <div class="mini-title-main">
                                        {{ paDetail.duration }}
                                        {{
                                            paDetail.duration
                                                ? $tc('common.durations.' + paDetail.durationUnit, paDetail.duration)
                                                : '-'
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex my-2 min-width-230">
                                <div class="d-flex place-self-center">
                                    <div class="d-inline-flex px-2">
                                        <img src="@/assets/icons/detail/efk.svg" />
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-auto">
                                    <div class="mini-title">
                                        {{ $t('bo.products.portfolio.detail.invested') }}
                                    </div>
                                    <div class="mini-title-main">
                                        {{ paDetail.purchaseAmount }} {{ paDetail.purchaseCurrency }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="paDetail.phase" class="d-flex my-2 min-width-230">
                                <div class="d-flex place-self-center">
                                    <div class="d-inline-flex px-2">
                                        <img src="@/assets/icons/detail/efk.svg" />
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-auto">
                                    <div class="mini-title">
                                        {{ $t('bo.products.portfolio.detail.phase.title') }}
                                    </div>
                                    <div class="mini-title-main">
                                        {{ $t('bo.products.portfolio.detail.phase.' + paDetail.phase) }}
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="2" class="d-flex flex-wrap">
                            <vt-button-primary v-if="false" class="ma-2" small>aa</vt-button-primary>
                            <vt-button-secondary v-if="false" class="ma-2" small>bb</vt-button-secondary>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
            <v-row class="ma-0 pa-0">
                <product-transactions-history-table
                    :loading="!PATransactionsFetched"
                    :items="PATransactions"
                    :pages="PATransactionsPages"
                    :fetch-error="PATransactionsError"
                    :show-transaction-subtype="true"
                    @pagination:change="fetchPATransactions"
                />
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import ActivateAccountBanner from '@/components/ui/ActivateAccountBanner';
    import EventBus from '@lb-world/core/public/plugins/eventBus';
    import ProductTransactionsHistoryTable from '@/components/ui/tables/ProductTransactionsHistoryTable';
    import { mapGetters } from 'vuex';

    export default {
        name: 'PortfolioDetail',
        props: {
            item: Object,
            id: String,
            productId: String
        },
        components: {
            ProductTransactionsHistoryTable,
            ActivateAccountBanner
        },
        computed: {
            paDetail() {
                return this.$store.getters['products/PADetail'];
            },
            paDetailFetched() {
                return this.$store.getters['products/PADetail:fetched'];
            },
            paDetailError() {
                return this.$store.getters['products/PADetail:error'];
            },
            ...mapGetters('products', {
                PATransactions: 'PATransactions',
                PATransactionsFetched: 'PATransactions:fetched',
                PATransactionsError: 'PATransactions:error',
                PATransactionsPages: 'PATransactions:pages'
            })
        },

        methods: {
            fetchPADetail() {
                this.$store.dispatch('products/PADetail:clear').then(() => {
                    this.$store
                        .dispatch('products/PADetail:fetch', {
                            productId: this.id
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            fetchPATransactions(page) {
                this.$store.dispatch('products/PA:clear').then(() => {
                    this.$store
                        .dispatch('products/PATransactions:fetch', {
                            productId: this.id,
                            page
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            console.log('kwamba');
            this.$store.dispatch('products/PADetail:clear').then(() => {
                this.fetchPADetail();
            });

            this.$store.dispatch('products/PATransactions:clear').then(() => {
                this.fetchPATransactions();
            });
        }
    };
</script>

<style scoped lang="scss"></style>
