var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',_vm._g(_vm._b({staticClass:"vt-button-secondary",class:[
        'justify-' + _vm.justify,
        'v-btn--secondary',
        {
            'full-width': _vm.fullWidth,
            inline: _vm.inline,
            form: _vm.form,
            cardSelect: _vm.cardSelect,
            iconButton: _vm.iconButton
        }
    ],attrs:{"small":_vm.small,"text":""}},'v-btn',_vm.$attrs,false),_vm.$listeners),[(_vm.icon)?_c('font-awesome-icon',{class:{ 'mr-2': !_vm.iconButton },attrs:{"icon":[_vm.iconSet, _vm.icon]}}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }