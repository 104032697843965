<template>
    <div class="ma-0 boxing">
        <div class="d-flex mx-2" :class="{ 'flex-wrap': $vuetify.breakpoint.mdAndDown }">
            <div class="d-flex flex-auto">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <div class="d-inline-flex">
                                <l-icon class="product-icon-dimension" secondary>ES</l-icon>
                                <div class="product-title-font pl-4">
                                    {{ $t('bo.products.efkStake.widget.title') }}
                                </div>
                            </div>
                            <div class="ml-14 mt-4 about-font">
                                {{ $t('bo.products.efkStake.widget.about') }}
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <div class="d-flex mb-3 items-baseline">
                                <img src="@/assets/icons/CircleCheckedPrimary.svg" />
                                <div class="ml-2 guarantees-font">
                                    {{ $t('bo.products.efkStake.widget.guarantees.knownYield') }}
                                </div>
                            </div>
                            <div class="d-flex mb-3 items-baseline">
                                <img src="@/assets/icons/CircleCheckedPrimary.svg" />
                                <div class="ml-2 guarantees-font">
                                    {{ $t('bo.products.efkStake.widget.guarantees.freeAccount') }}
                                </div>
                            </div>
                            <div class="d-flex mb-3 items-baseline">
                                <img src="@/assets/icons/CircleCheckedPrimary.svg" />
                                <div class="ml-2 guarantees-font">
                                    {{ $t('bo.products.efkStake.widget.guarantees.withdrawalPossibility') }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <div
                                class="mx-2 flex-auto"
                                :class="{
                                    'text-center': $vuetify.breakpoint.xs,
                                    'text-right': $vuetify.breakpoint.lgAndUp
                                }"
                            ></div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <v-row class="ma-3">
            <v-col cols="12" md="6">
                <div class="smaller-title">
                    {{ $t('bo.products.efkStake.widget.forWho') }}
                </div>
                <div class="about-font">
                    {{ $t('bo.products.efkStake.widget.forWhoText') }}
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="smaller-title">
                    {{ $t('bo.products.efkStake.widget.fees') }}
                </div>
                <div class="about-font">
                    {{ $t('bo.products.efkStake.widget.feesText') }}
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-3 text-center text-sm-left">
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/note.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkStake.sidebar.duration') }}
                    </div>
                    <div class="sidebar-value-font">
                        {{ $t('bo.products.efkStake.sidebar.durationValue') }}
                    </div>
                </div>
            </v-col>
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/percentage.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkStake.sidebar.interestRate') }}
                    </div>
                    <div class="sidebar-value-font">
                        1,12 %/ 2,24 % p.a.
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row class="ma-3 text-center text-sm-left">
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/leaf.svg" style="width: 45px; height: 45px" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkStake.sidebar.maximalBuyPrice') }}
                    </div>
                    <div class="sidebar-value-font">
                        10.000 EFK
                    </div>
                </div>
            </v-col>
            <v-col cols="6" sm="3">
                <img src="@/assets/icons/calendar.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkStake.sidebar.subscriptionPeriod') }}
                    </div>
                    <div class="sidebar-value-font">
                        -
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <img src="@/assets/icons/bag.svg" />

                <div class="ml-2">
                    <div class="about-font">
                        {{ $t('bo.products.efkStake.sidebar.maximalClientPurchase') }}
                    </div>
                    <div class="sidebar-value-font">
                        {{ $t('bo.products.efkStake.sidebar.notFixed') }}
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { DOCUMENT_PRODUCT_INTRO_EFK_STAKE, PRODUCT_ID_EFK_STAKE } from '@/constants';
    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'VtEfkStake',

        data: () => ({
            productId: PRODUCT_ID_EFK_STAKE,

            pdfTeaserButton: {
                loading: false
            }
        }),

        computed: {
            ...mapGetters('products', {
                productFilter: 'products:filter',
                productFetched: 'products:fetched',
                productError: 'products:error'
            }),
            product() {
                return this.$store.getters['products/products:filter'](PRODUCT_ID_EFK_STAKE);
            },
            productFetched() {
                return this.$store.getters['products/products:fetched'];
            }
        },

        methods: {
            openPDFTeaser() {
                this.pdfTeaserButton.loading = true;
                const windowRef = window.open();
                this.$store
                    .dispatch('documents/document:request', DOCUMENT_PRODUCT_INTRO_EFK_STAKE)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.pdfTeaserButton.loading = false;
                    });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
