<template>
    <base-overlay
        icon="wrench"
        :title="$t('overlays.maintenance.title')"
        :description="$t('overlays.maintenance.description')"
        :button-title="$t('overlays.maintenance.button')"
        :button-loading="buttonLoading"
        @button:click="checkConnection"
    />
</template>

<script>
    import BaseOverlay from '@/views/error/BaseOverlay';

    import { checkForMaintenance } from '@/overlays';

    export default {
        name: 'Maintenance',

        components: { BaseOverlay },

        data: () => ({
            buttonLoading: false
        }),

        methods: {
            checkConnection() {
                this.$log.debug('[MAINTENANCE] Running maintenance route');

                this.buttonLoading = true;

                this.$store
                    .dispatch('auth/token:store')
                    .then(() => {
                        this.$router.push('/');
                    })
                    .catch(({ statusCode }) => {
                        if (!checkForMaintenance(statusCode)) {
                            this.$router.replace('/');
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.buttonLoading = false;
                        }, 1000);
                    });
            }
        },

        mounted() {
            this.checkConnection();
        }
    };
</script>

<style scoped></style>
