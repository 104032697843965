<template>
    <app-container id="sign-in" fill-height-mobile :shadow="$vuetify.breakpoint.smAndUp">
        <app-row justify="center">
            <app-sign-col>
                <app-row>
                    <app-sign-col half class="apps-view-col">
                        <div class="apps-view-col__wrapper">
                            <app-slogan-integrations-view />

                            <app-language-picker floating />

                            <p class="app-version">
                                <span>v</span>
                                <span class="app-version__current">{{ appVersion }}</span>
                            </p>
                        </div>
                    </app-sign-col>
                    <app-sign-col half class="form-col">
                        <app-row justify="center">
                            <app-sign-col full>
                                <div class="form-col__top-background"></div>
                                <div class="form-col__logo-wrapper">
                                    <app-logo size="xl" :logo-id="appLogo" :logo-only="$vuetify.breakpoint.smAndDown">
                                    </app-logo>
                                </div>
                            </app-sign-col>
                        </app-row>

                        <div class="form-col__inner">
                            <div v-if="userFetched && !userRegistered">
                                <app-row justify="center">
                                    <app-sign-col full>
                                        <p class="section-title text-center">
                                            {{ $t('signIn.registrationNotCompleted') }}
                                        </p>
                                    </app-sign-col>
                                </app-row>

                                <app-row justify="center">
                                    <app-sign-col full>
                                        <p class="text-center" v-html="$t('signIn.accountNotCompleted')"></p>
                                    </app-sign-col>
                                </app-row>

                                <app-row justify="center">
                                    <app-sign-col full>
                                        <button-wrapper justify="center">
                                            <button-primary @click="redirectToRegistration">
                                                {{ $t('signIn.finishRegistration') }}
                                            </button-primary>
                                        </button-wrapper>
                                    </app-sign-col>
                                </app-row>
                            </div>

                            <div v-else>
                                <app-row justify="center">
                                    <app-sign-col full>
                                        <app-loader vertical>
                                            {{ $t('signIn.loading') }}
                                        </app-loader>
                                    </app-sign-col>
                                </app-row>

                                <app-row justify="center" class="mt-6">
                                    <app-sign-col full>
                                        <p class="text-center">
                                            {{ $t('signIn.processing') }}<br />
                                            {{ $t('signIn.pleaseWait') }}
                                        </p>
                                    </app-sign-col>
                                </app-row>
                            </div>
                        </div>
                    </app-sign-col>
                </app-row>
            </app-sign-col>
        </app-row>
    </app-container>
</template>

<script>
    import AppSloganIntegrationsView from '@/components/ui/AppSloganIntegrationsView';
    import AppSignCol from '@/components/ui/layout/AppSignCol';

    import { CURRENT_APP, CURRENT_APP_VERSION } from '@/constants';

    import { getAppUrlFormat } from '@/utils';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    export default {
        name: 'SignIn',

        components: {
            AppSloganIntegrationsView,
            AppSignCol
        },

        data: () => ({
            appVersion: CURRENT_APP_VERSION,
            appLogo: CURRENT_APP
        }),

        computed: {
            userFetched() {
                return this.$store.getters['auth/token'];
            },
            userRegistered() {
                return this.$store.getters['auth/token:role'](UserRoles.REGISTERED);
            }
        },

        methods: {
            redirectToRegistration() {
                window.location = getAppUrlFormat('world');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .app-version {
        position: absolute;
        top: 12px;
        left: 16px;

        .app-version__current {
            font-weight: bold;
            color: colors.$color-primary;
        }
    }

    .apps-view-col {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;

        padding: spacing.$spacing-9;

        .apps-view-col__wrapper {
            margin-top: spacing.$spacing-9;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .form-col {
        position: relative;

        min-height: 475px;

        padding: spacing.$spacing-9;

        @include media.resolve('xs-only') {
            box-shadow: -1px -5px 15px -17px rgba(0, 0, 0, 0.75);
        }

        @include media.resolve('sm-and-up') {
            box-shadow: -1px 0 20px -17px rgba(0, 0, 0, 0.75);
        }

        .form-col__top-background {
            position: absolute;
            height: 75px;
            top: 0;
            left: spacing.$spacing-12;
            right: spacing.$spacing-12;
            background-color: colors.$color-primary-3;

            @include media.resolve('sm-and-down') {
                display: none;
            }
        }

        .form-col__inner {
            height: calc(100% - 70px);
            display: flex;
            flex-direction: column;

            @include media.resolve('sm-and-up') {
                justify-content: center;
            }

            @include media.resolve('xs-only') {
                margin-top: spacing.$spacing-9;
            }
        }

        .form-col__logo-wrapper {
            display: flex;
            justify-content: center;
        }
    }

    .v-text-field.v-text-field--enclosed .v-text-field__details {
        display: none;
    }
</style>
