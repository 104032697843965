<template>
    <div>
        <v-container class="ma-0 pa-4 pt-0 full-width" v-if="!onlyTable">
            <v-row>
                <v-col
                    cols="12"
                    lg="3"
                    class="d-flex flex-row flex-wrap pl-0 pr-0"
                    :class="{ 'place-center': $vuetify.breakpoint.xs, 'place-start': $vuetify.breakpoint.smAndUp }"
                >
                    <span v-if="false" class="d-flex flex-row">
                        <v-text-field v-model="searchText" outlined class="v-text-field-style" />
                        <v-btn
                            class="search-btn-style"
                            :class="$vuetify.breakpoint.smAndUp ? 'button-text-search' : 'button-icon-search'"
                            dark
                            icon
                            @click="$emit('searchText:change', $event)"
                        >
                            <div v-if="$vuetify.breakpoint.smAndUp" class="table-search-font">
                                {{ $t('table.search') }}
                            </div>
                            <font-awesome-icon
                                v-else
                                size="lg"
                                class="search-icon-color"
                                :icon="['far', 'search']"
                            ></font-awesome-icon>
                        </v-btn>
                    </span>
                    <div>
                        <vt-transaction-filter
                            @filter:reset="$emit('filter:reset', $event)"
                            @filter="$emit('filter:update', filters)"
                            :wallets="wallets"
                            :filters="filters"
                        />
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    lg="9"
                    :class="{ 'place-center': $vuetify.breakpoint.xs, 'place-end': $vuetify.breakpoint.smAndUp }"
                    class="d-flex flex-wrap"
                >
                    <div class="d-flex single-line white-space-nowrap">
                        <vt-order-select v-model="orderBy" @input="$emit('orderBy:change', $event)" />
                    </div>
                    <div class="d-flex single-line mr-0 ml-4 white-space-nowrap place-items-center">
                        {{ pages.totalCount }} {{ $t('table.totalCount') }}
                    </div>
                    <div class="d-flex mr-n2 ml-4 place-content-flex-end">
                        <vt-table-pagination v-if="!onlyTable" v-on="$listeners" />
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <hr class="mt-6" v-if="!onlyTable" />

        <v-data-table
            class="app-table"
            :loading="loading"
            :items="items"
            :headers="headers"
            hide-default-footer
            disable-sort
            :page.sync="pages.current"
        >
            <template v-slot:item.amount="{ item }">
                <span class="float-left width-min-8rem" :class="isOutlay(item) ? 'text-red' : 'text-green'">
                    {{ formatAmount(item.amount, item.currency) }}

                    <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-4 svg-style"
                    >
                        <template v-if="isOutlay(item)">
                            <rect
                                x="12"
                                y="12.9951"
                                width="12"
                                height="12"
                                rx="3.71429"
                                transform="rotate(-180 12 12.9951)"
                                fill="#FEEEEC"
                            />
                            <path
                                d="M9 5.79613C9 5.92748 8.94812 6.05953 8.84436 6.15994L6.37833 8.55803C6.27812 8.655 6.14238 8.70933 6.00025 8.70933C5.85882 8.70933 5.72309 8.655 5.62288 8.55803L3.15546 6.15993C2.94794 5.95843 2.94794 5.63245 3.15688 5.43094C3.36582 5.23013 3.70339 5.23082 3.91091 5.43232L6.00025 7.46317L8.08963 5.43232C8.29715 5.23082 8.634 5.23013 8.84294 5.43095C8.94812 5.53135 9 5.66409 9 5.79613Z"
                                fill="#F9461C"
                            />
                        </template>
                        <template v-else>
                            <rect y="0.995117" width="12" height="12" rx="3.71429" fill="#C6FFEC" />
                            <path
                                d="M3 8.19411C3 8.06275 3.05188 7.93071 3.15564 7.8303L5.62167 5.4322C5.72188 5.33523 5.85762 5.2809 5.99975 5.2809C6.14118 5.2809 6.27691 5.33523 6.37712 5.4322L8.84454 7.8303C9.05206 8.0318 9.05206 8.35779 8.84312 8.55929C8.63418 8.76011 8.29661 8.75942 8.08909 8.55791L5.99975 6.52706L3.91037 8.55791C3.70285 8.75942 3.366 8.7601 3.15706 8.55929C3.05188 8.45888 3 8.32615 3 8.19411Z"
                                fill="#309A5D"
                            />
                        </template>
                    </svg>

                    <img
                        height="14px"
                        :alt="item.currency"
                        class="mr-1 float-right"
                        :src="require('@/assets/icons/crypto/' + item.currency + '.svg')"
                    />
                </span>
            </template>

            <template v-slot:item.createdAt="{ item }">
                <span class="created-at-font">
                    {{ formatTimestamp(item.createdAt) }}
                </span>
            </template>

            <template v-slot:item.type="{ item }">
                <span class="d-flex flex-row">
                    <img v-if="isOutlay(item)" class="mb-1" src="@/assets/icons/transaction-table-red-dot.svg" />
                    <img v-else class="mb-1" src="@/assets/icons/transaction-table-green-dot.svg" />
                    <span :class="isOutlay(item) ? 'text-red' : 'text-green'" class="is-outlay-font">
                        {{ $t(isOutlay(item) ? 'table.typeCategories.sell' : 'table.typeCategories.buy') }}
                    </span>
                </span>
            </template>

            <template v-slot:item.description="{ item }">
                {{ $t('transactionTypes.' + item.type) }}
            </template>

            <template v-slot:item.id="{ item }">
                <span class="item-id-font">
                    {{ item.id }}
                </span>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-menu v-if="item.transferInfo" offset-y :nudge-width="200" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <button-secondary v-on="on" inline class="select-box-activator" icon="question-circle">
                            <span v-if="$vuetify.breakpoint.smAndUp">
                                {{ $t('bo.transactions.transferInfo') }}
                            </span>
                        </button-secondary>
                    </template>

                    <app-card left class="pa-4">
                        <app-text-stack v-if="item.transferInfo.recipient" smaller-value>
                            <template #key>
                                {{ $t('bo.transactions.recipient') }}
                            </template>

                            {{ item.transferInfo.recipient }}
                        </app-text-stack>

                        <hr class="mt-3" v-if="item.transferInfo.recipient && item.transferInfo.sender" />

                        <app-text-stack v-if="item.transferInfo.sender" smaller-value>
                            <template #key>
                                {{ $t('bo.transactions.sender') }}
                            </template>

                            {{ item.transferInfo.sender }}
                        </app-text-stack>

                        <hr class="mt-3" v-if="item.transferInfo.message" />

                        <app-text-stack v-if="item.transferInfo.message" class="mt-3" smaller-value>
                            <template #key>
                                {{ $t('bo.transactions.message') }}
                            </template>

                            {{ item.transferInfo.message }}
                        </app-text-stack>
                    </app-card>
                </v-menu>

                <span v-else v-color:black.translucent>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { core, filtering, pagination } from '@lb-world/core/public/components/tables/mixins';

    import VtOrderSelect from '@/components/ui/form/VtOrderSelect';
    import AppCard from '@/components/ui/AppCard';
    import VtTablePagination from '@/components/ui/tables/VtTablePagination';
    import VtTransactionFilter from '@/components/ui/VtTransactionFilter';

    export default {
        name: 'TransactionsHistoryTable',

        components: { AppCard, VtTablePagination, VtOrderSelect, VtTransactionFilter },

        mixins: [core, pagination, filtering],

        props: {
            onlyTable: {
                type: Boolean,
                default: false
            },
            wallets: {
                type: Object
            },
            orderBy: {
                default: 'createdAt=desc',
                type: String
            },
            searchText: {
                default: '',
                type: String
            },
            walletsLoading: {
                type: Boolean
            }
        },

        methods: {
            isOutlay(item) {
                if (
                    item.type === 'outgoing_transaction' ||
                    item.type === 'outgoing_transfer' ||
                    item.type === 'product_purchase'
                )
                    return true;
                else return false;
            }
        },

        computed: {
            headers() {
                const headersTmp = [
                    {
                        text: this.$t('table.columns.date'),
                        value: 'createdAt'
                    },
                    {
                        text: this.$t('table.columns.id'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.type'),
                        value: 'type'
                    },
                    {
                        text: this.$t('table.columns.description'),
                        value: 'description'
                    }
                ];
                if (!this.onlyTable) {
                    headersTmp.push({
                        text: this.$t('table.columns.comment'),
                        value: 'note',
                        align: 'center'
                    });
                }
                headersTmp.push({
                    text: this.$t('table.columns.amount'),
                    value: 'amount',
                    align: 'start'
                });
                if (!this.onlyTable) {
                    headersTmp.push({
                        text: this.$t('table.columns.actions'),
                        value: 'actions',
                        align: 'center'
                    });
                }
                return headersTmp;
            }
        }
    };
</script>

<style scoped lang="scss"></style>
