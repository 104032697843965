<template>
    <v-container class="ma-0 pa-0 full-width">
        <app-row v-if="walletError">
            <app-registration-col whole-screen>
                <app-card>
                    <app-error no-padding horizontal fetching />
                </app-card>
            </app-registration-col>
        </app-row>
        <div v-else>
            <div v-for="index in walletsCount" :key="index">
                <v-container
                    v-if="
                        dashboard
                            ? walletsData[index - 1].currency === 'EFK'
                            : walletsData[index - 1].currency !== 'USDT'
                    "
                    class="container-card"
                >
                    <app-card left v-if="!walletsFetched">
                        <v-skeleton-loader type="heading, sentences, sentences" width="50%" />
                    </app-card>

                    <v-row v-else-if="walletsData[index - 1].currency !== 'USDT'">
                        <v-col cols="12" sm="6" md="12" lg="6" class="align-self-center">
                            <v-container>
                                <v-row v-if="dashboard">
                                    <div class="d-flex">
                                        <div
                                            class="d-flex flex-auto flex-column graph-title mb-4"
                                            style="font-weight: bold; font-size: 32px;"
                                        >
                                            {{ $t('bo.dashboard.widgets.efkWidget.titleMain') }}
                                        </div>
                                    </div>
                                </v-row>
                                <v-row v-if="!dashboard">
                                    <div class="d-flex">
                                        <img
                                            width="44px"
                                            height="45px"
                                            class="wallet-title__icon mr-2 d-flex align-self-center"
                                            :src="require('@/assets/icons/crypto/' + getWalletCurrency(index) + '.svg')"
                                        />
                                        <div class="d-flex align-self-center px-8 font-title">
                                            {{ walletsData[index - 1].currency }}
                                        </div>
                                    </div>
                                </v-row>
                                <v-row class="mt-8">
                                    <v-col cols="12" md="5" class="pl-0">
                                        <div class="d-flex">
                                            <div class="mr-2 align-self-center main-value-font min-width-max-content">
                                                {{ formatWalletAmount(index) }}
                                            </div>
                                            <img
                                                class="mr-2"
                                                width="40"
                                                :src="
                                                    require('@/assets/icons/crypto/' +
                                                        getWalletCurrency(index) +
                                                        '.svg')
                                                "
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-8 ">
                                    <div class="d-flex flex-wrap text-center full-width justify-space-between">
                                        <!--                                        <div class="pa-1">
                                            <button-primary
                                                v-if="isCurrencyPurchase(walletsData[index - 1])"
                                                small
                                                width="120"
                                                class="mx-auto"
                                                @click="openCurrencyPurchaseDialog(index)"
                                            >
                                                {{ $t('bo.wallets.buy') }}
                                            </button-primary>
                                        </div>-->
                                        <div class="pa-1">
                                            <vt-button
                                                v-if="isCurrencyWithdrawal(walletsData[index - 1])"
                                                @click="openCurrencyWithdrawalDialog(index)"
                                                small
                                                width="120"
                                                bg="rgba(225, 235, 255, 1)"
                                                color="rgba(42, 101, 164, 1)"
                                                >{{ $t('bo.wallets.withdrawal') }}</vt-button
                                            >
                                        </div>
                                        <div class="pa-1">
                                            <vt-button
                                                v-if="isCurrencyTransfer(walletsData[index - 1])"
                                                @click="openCurrencyTransferDialog(index)"
                                                small
                                                width="120"
                                                bg="rgba(225, 235, 255, 1)"
                                                color="rgba(42, 101, 164, 1)"
                                                >{{ $t('bo.wallets.transfer') }}</vt-button
                                            >
                                        </div>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col
                            v-if="walletsData[index - 1].currency !== 'EFK'"
                            cols="12"
                            sm="6"
                            md="12"
                            lg="6"
                            class="align-self-center"
                        >
                            <v-container>
                                <v-row>
                                    <div class="d-flex">
                                        <img src="@/assets/icons/rewatch.svg" />
                                        <div class="d-flex flex-auto flex-column ml-4 graph-title">
                                            {{ $t('bo.dashboard.widgets.efkWidget.title') }}
                                        </div>
                                    </div>
                                </v-row>
                                <v-row>
                                    <app-loader v-if="!walletChartFetched(getWalletChartCurrencies(index))" />

                                    <div class="full-width my-4" v-else>
                                        <line-chart
                                            v-if="walletChartData(getWalletChartCurrencies(index))"
                                            style="height: 200px"
                                            :chart-data="walletChartData(getWalletChartCurrencies(index))"
                                        ></line-chart>

                                        <p v-else class="popover-category text-start">
                                            {{ $t('bo.wallets.widget.noGraphCurrency') }}
                                        </p>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </v-container>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { BASE_CRYPTO_CURRENCY, BASE_CURRENCY } from '@/constants';

    import Currencies from '@lb-world/core/public/utils/currencies';

    import LineChart from '@/components/ui/charts/LineChart';

    import { mapGetters } from 'vuex';

    export default {
        name: 'WalletsContainer',

        props: {
            dashboard: Boolean
        },

        components: {
            LineChart
        },

        computed: {
            ...mapGetters('widgets', {
                walletsCount: 'data:balances:count'
            }),

            ...mapGetters('exchangeRates', ['rates', 'ratesFetched', 'ratesError']),

            ratesFetched() {
                return this.$store.getters['exchangeRates/ratesFetched'];
            },
            rates() {
                return this.$store.getters['exchangeRates/rates'];
            },
            userId() {
                return this.$store.getters['user/user:id'];
            },
            walletsFetched() {
                return this.$store.getters['wallets/wallets:fetched'];
            },
            walletsData() {
                return this.$store.getters['wallets/wallets:data'];
            },
            walletChartFetched() {
                return this.$store.getters['wallets/wallet:chartFetched'];
            },
            walletChartData() {
                return this.$store.getters['wallets/wallet:chartData'];
            },
            walletError() {
                return this.$store.getters['wallets/wallets:error'];
            }
        },

        methods: {
            isCurrencyPurchase(item) {
                return item?.currency === 'EFK' ? true : false;
            },
            isCurrencyWithdrawal(item) {
                return Currencies.isWithdrawal(item?.currency);
            },
            isCurrencyTransfer(item) {
                return Currencies.isTransfer(item?.currency);
            },
            openCurrencyWithdrawalDialog(index) {
                const wallet = this.getWalletCurrency(index);

                this.$emit(
                    'open:withdrawal',
                    {
                        currencyBalance: this.getWalletBalance(index),
                        currency: this.getWalletCurrency(index)
                    },
                    wallet === 'EFK'
                );
            },
            openCurrencyPurchaseDialog(index) {
                const wallet = this.getWalletCurrency(index);

                this.$emit(
                    'open:purchase',
                    {
                        currencyBalance: this.getWalletBalance(index),
                        currency: this.getWalletCurrency(index)
                    },
                    wallet === 'EFK'
                );
            },
            openCurrencyTransferDialog(index) {
                this.$emit('open:transfer', {
                    currencyBalance: this.getWalletBalance(index),
                    currency: this.getWalletCurrency(index),
                    senderId: this.userId
                });
            },
            getWalletBalance(index) {
                return this.walletsData[index - 1]?.balance;
            },
            getWalletCurrency(index) {
                return this.walletsData[index - 1]?.currency;
            },
            getWalletChartCurrencies(index) {
                const walletCurrency = this.walletsData[index - 1].currency;

                if (walletCurrency === 'CZK') {
                    return {
                        walletCurrency,
                        baseCurrency: BASE_CURRENCY,
                        quoteCurrency: walletCurrency
                    };
                } else if (walletCurrency === 'EFK') {
                    return {
                        walletCurrency,
                        baseCurrency: walletCurrency,
                        quoteCurrency: BASE_CRYPTO_CURRENCY
                    };
                } else {
                    return {
                        walletCurrency
                    };
                }
            },
            formatWalletAmount(index) {
                const balance = this.getWalletBalance(index);
                const currency = this.getWalletCurrency(index);

                return this.formatAmount(balance, currency);
            },
            vtGetCurrencyExchangeRate(currency, type) {
                if (!this.ratesError && this.rates) {
                    const base = this.rates.find(c => c.baseCurrency === currency);
                    const quote = this.rates.find(c => c.quoteCurrency === currency);

                    if (type === 'base') {
                        if (base) {
                            return 1;
                        } else if (quote) {
                            return this.formatAmount(quote.rate, 'rate');
                        }
                    } else if (type === 'quote') {
                        if (base) {
                            return this.formatAmount(base.rate, 'rate') + ' ' + BASE_CURRENCY;
                        } else if (quote) {
                            return 1 + ' ' + BASE_CURRENCY;
                        }
                    } else if (type === 'time') {
                        if (base) {
                            return this.formatTimestamp(base.timestamp);
                        } else if (quote) {
                            return this.formatTimestamp(quote.timestamp);
                        }
                    }
                }
            },
            getCurrencyExchangeRate(currency) {
                if (!this.ratesError && this.rates) {
                    const base = this.rates.find(c => c.baseCurrency === currency);
                    const quote = this.rates.find(c => c.quoteCurrency === currency);

                    if (base) {
                        return this.formatAmount(base.rate, 'rate') + ' ' + BASE_CURRENCY;
                    } else if (currency === BASE_CRYPTO_CURRENCY) {
                        return 1 + ' ' + BASE_CURRENCY;
                    } else if (quote) {
                        return (
                            this.formatAmount(quote.rate, 'rate') + ' ' + quote.quoteCurrency + '/' + quote.baseCurrency
                        );
                    }
                }
            },
            getWalletGraphRates() {
                for (const wallet of this.walletsData) {
                    if (wallet.currency === 'CZK') {
                        this.$store
                            .dispatch('exchangeRates/history:fetch', {
                                quoteCurrency: BASE_CURRENCY,
                                baseCurrency: wallet.currency
                            })
                            .then(() => {
                                this.$store.dispatch('exchangeRates/fetch');
                            })
                            .catch(error => {
                                EventBus.$emit('snackbar:show:danger', error);
                            });
                    } else if (wallet.currency === 'EFK') {
                        this.$store
                            .dispatch('exchangeRates/history:fetch', {
                                quoteCurrency: wallet.currency,
                                baseCurrency: BASE_CRYPTO_CURRENCY
                            })
                            .catch(error => {
                                EventBus.$emit('snackbar:show:danger', error);
                            });
                    }
                }
            }
        }
    };
</script>

<style scoped lang="scss"></style>
