<template>
    <v-expansion-panels v-model="panelsOpened">
        <app-expansion-panel v-if="product" :title="$t('bo.products.productSummaryDialog.purchaseDetailsPanel.title')">
            <app-flex class="tree-items">
                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.efkTokens') }}
                        </template>

                        {{ purchaseAmount }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.productId') }}
                        </template>

                        {{ productId }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.openedAt') }}
                        </template>

                        {{ openedAt }}
                    </app-text-stack>
                </app-flex-item>
            </app-flex>

            <app-flex class="tree-items mt-6">
                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.saleRate') }}
                        </template>

                        {{ saleRate }}
                    </app-text-stack>
                </app-flex-item>
            </app-flex>
        </app-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'SummaryEFKLiquidity',

        components: { AppExpansionPanel, AppFlex, AppFlexItem },

        props: ['product', 'panels'],

        data: () => ({
            _panels: []
        }),

        computed: {
            panelsOpened: {
                get() {
                    return this.panels;
                },
                set() {
                    this.$emit('update:panels', this.$data._panels);
                }
            },

            productId() {
                if (this.isEmpty(this.product?.productId)) {
                    return '-';
                }

                return this.product?.productId;
            },
            openedAt() {
                if (this.isEmpty(this.product?.openedAt)) {
                    return '-';
                }

                return this.formatTimestamp(this.product?.openedAt);
            },
            purchaseAmount() {
                if (this.isEmpty(this.product?.purchaseAmount) || this.isEmpty(this.product?.purchaseCurrency)) {
                    return '-';
                }

                const purchaseAmount = this.product?.purchaseAmount;
                const purchaseCurrency = this.product?.purchaseCurrency;

                return this.formatAmount(purchaseAmount, purchaseCurrency) + ' ' + purchaseCurrency;
            },
            saleRate() {
                if (this.isEmpty(this.product?.saleRate)) {
                    return '-';
                }

                return this.formatAmount(this.product?.saleRate, 'rate') + ' USD/EFK';
            }
        }
    };
</script>

<style scoped></style>
