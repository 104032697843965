<template>
    <v-container fill-height fluid class="d-flex flex-row pa-0 account-layout">
        <vt-app-background />
        <vt-app-drawer :drawer="drawer" :fullname="fullname" :email="username" :sections="tabs"></vt-app-drawer>
        <vt-app-toolbar
            v-if="$vuetify.breakpoint.smAndDown"
            @drawer:open="drawer = {}"
            :username="username"
            :user-id="userId"
            :hideToolBar="$vuetify.breakpoint.mdAndUp"
            :hideAppPanel="true"
        ></vt-app-toolbar>
        <router-view v-if="$store.getters['user/user:id'] && $store.getters['widgets/dataFetched']" />
        <app-loader v-else justify="center" class="full-width">
            {{ $t('common.loadingUser') }}
        </app-loader>
    </v-container>
</template>

<script>
    import VtAppBackground from '../../components/ui/layout/VtAppBackground';
    import VtAppToolbar from '../../components/ui/VtAppToolbar';
    import VtAppDrawer from '../../components/ui/drawers/VtAppDrawer';
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'AccountLayout',

        components: {
            VtAppDrawer,
            VtAppBackground,
            VtAppToolbar
        },

        data: () => ({
            drawer: null
        }),

        computed: {
            tabs() {
                return this.$store.getters['tabs/account'];
            },
            username() {
                return this.$store.getters['user/user:email'];
            },
            fullname() {
                return this.$store.getters['user/user:fullName'];
            },
            userId() {
                return this.$store.getters['user/user:id'];
            }
        },

        methods: {
            fetchWidgetData() {
                this.$store.dispatch('widgets/fetch').catch(error => {
                    EventBus.$emit('snackbar:danger:show', error);
                });
            }
        },

        mounted() {
            this.fetchWidgetData();
        }
    };
</script>

<style scoped lang="scss"></style>
