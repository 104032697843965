import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import tabs from './tabs';
import auth from './auth';
import wallets from './wallets';
import transactions from './transactions';
import exchangeRates from './exchangeRates';
import widgets from './widgets';
import products from '@/store/products';
import documents from '@/store/documents';
import aml from '@/store/aml';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        tabs,
        auth,
        wallets,
        transactions,
        exchangeRates,
        widgets,
        products,
        documents,
        aml
    }
});
