<template>
    <app-card class="create-transaction-card create-transaction-style">
        <app-loader v-if="!productFetched" />

        <validation-observer ref="formEFKStake" v-slot="{ invalid }" v-else>
            <app-row class="max-width-600">
                <app-col full-space>
                    <app-text-field
                        v-model="parameters.purchaseAmount"
                        :rules="{
                            required: true,
                            regex: numberRegex,
                            max_value: [walletBalance, currency],
                            decimals: 4
                        }"
                        :label="$t('bo.products.efkStake.create.bondAmount')"
                    >
                        <template #append>
                            {{ currency }}
                        </template>
                    </app-text-field>
                </app-col>
            </app-row>

            <app-text v-if="!maxStake" note justify="start">
                <span
                    v-if="product.minimalPurchaseAmount"
                    v-html="
                        $t('bo.products.efkLiquidity.create.minimalPurchaseAmount', {
                            amount: formatAmount(product.minimalPurchaseAmount)
                        })
                    "
                />

                <span
                    v-if="product.maximalPurchaseAmount"
                    v-html="
                        $t('bo.products.efkLiquidity.create.maximalPurchaseAmount', {
                            amount: formatAmount(product.maximalPurchaseAmount)
                        })
                    "
                />
            </app-text>

            <app-divider v-if="!maxStake" />

            <app-text v-if="!maxStake" highlight>
                {{ $t('bo.products.common.selectOption') }}
            </app-text>

            <app-row v-if="!maxStake">
                <app-col full-space class="pa-0">
                    <app-flex>
                        <app-flex-item
                            class="three-cards-selection"
                            v-for="option in product.interestCalculationData.rates"
                            :key="option.duration"
                        >
                            <button-secondary card-select class="pa-0" @click="parameters.duration = option.duration">
                                <app-card class="w-100" full-width :selected="parameters.duration === option.duration">
                                    <app-text-stack smaller-key>
                                        <template #key>
                                            {{ $t('bo.products.common.duration') }}
                                        </template>

                                        {{ option.duration }}
                                        {{ $tc('common.durations.' + product.durationUnit, option.duration) }}
                                    </app-text-stack>

                                    <app-text-stack smaller-key class="mt-3">
                                        <template #key>
                                            {{ $t('bo.products.efkStake.create.interest') }}
                                        </template>

                                        {{ formatAmount(option.interestRate + volumeBonus) }}
                                        {{ product.interestCalculationData.metadata.interestRateSymbol }}
                                    </app-text-stack>
                                </app-card>
                            </button-secondary>
                        </app-flex-item>
                    </app-flex>
                </app-col>
            </app-row>

            <app-text v-if="!maxStake && !disableVolume" note justify="start">
                <span
                    v-html="
                        $t('bo.products.efkStake.create.volumeBonusLabel', {
                            percentage: formatAmount(volumePercentage),
                            amount: formatAmount(volumeMinimalPurchaseAmount)
                        })
                    "
                />
            </app-text>

            <app-divider v-if="!maxStake && !disableVolume" />

            <app-row>
                <app-col full-space>
                    <app-switch-field
                        :centered="$vuetify.breakpoint.xsOnly"
                        button-events
                        v-model="acceptTerms"
                        @button:click="openProductDocument"
                        :button-loading="productDocumentButton.loading"
                    >
                        {{ $t('bo.products.efkStake.create.agree') }}

                        <template v-slot:button>
                            {{ $t('bo.products.download.efkStakeProductConditions') }}
                        </template>
                    </app-switch-field>
                </app-col>
            </app-row>

            <app-row>
                <app-col full-space>
                    <button-wrapper :justify="$vuetify.breakpoint.smAndUp ? 'start' : 'center'">
                        <vt-button-primary
                            full-width
                            :disabled="invalid || !acceptTerms || !parameters.duration || !userVerified"
                            @click="submit"
                            :loading="submitButtonLoading"
                        >
                            {{ $t('bo.products.efkStake.create.bondButton') }}
                        </vt-button-primary>
                    </button-wrapper>
                </app-col>
            </app-row>
        </validation-observer>
    </app-card>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';
    import { roundAmount } from '@lb-world/core/public/utils/formatting';

    import { ValidationObserver } from 'vee-validate';

    import { DOCUMENT_PRODUCT_SPECS_EFK_STAKE_3 } from '@/constants';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    import AppCard from '../../../ui/AppCard';
    import AppTextField from '../../../ui/form/AppTextField';
    import AppCol from '../../../ui/layout/AppCol';
    import AppSwitchField from '../../../ui/form/AppSwitchField';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';

    export default {
        name: 'CreateTransaction',

        components: {
            AppFlex,
            AppFlexItem,
            AppSwitchField,
            AppCol,
            AppTextField,
            AppCard,
            ValidationObserver
        },

        props: {
            productId: String,
            product: Object,
            productFetched: Boolean,
            maxStake: Boolean,
            disableVolume: Boolean
        },

        data: () => ({
            productDocumentButton: {
                loading: false
            },

            submitButtonLoading: false,

            parameters: {
                purchaseAmount: '',
                duration: ''
            },

            currency: 'EFK',
            acceptTerms: false
        }),

        computed: {
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },

            volumePercentage() {
                return this.product.interestCalculationData.volumeBonus.interestRateBonus;
            },

            volumeMinimalPurchaseAmount() {
                return this.product.interestCalculationData.volumeBonus.minimalPurchaseAmount;
            },

            volumeBonus() {
                return parseInt(this.parameters.purchaseAmount) >= this.volumeMinimalPurchaseAmount
                    ? this.volumePercentage
                    : 0;
            },
            userVerified() {
                return this.$store.getters['auth/token:role'](UserRoles.VERIFIED);
            },
            walletBalance() {
                return this.$store.getters['widgets/data:balances:wallet'](this.currency);
            }
        },

        methods: {
            hasLimitAvailable(remaining, amount) {
                return remaining - amount > 0;
            },
            openProductDocument() {
                this.productDocumentButton.loading = true;

                const windowRef = window.open();

                this.$store
                    .dispatch('documents/document:request', DOCUMENT_PRODUCT_SPECS_EFK_STAKE_3 + '--' + this.$i18n.locale.substring(0, 2))
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.productDocumentButton.loading = false;
                    });
            },
            processPurchase() {
                const productId = this.productId;
                const parameters = this.deepCopy(this.parameters);

                parameters.purchaseAmount = this.convertLocaleToNumber(parameters.purchaseAmount);

                this.$store
                    .dispatch('products/PA:open', {
                        parameters,
                        productId
                    })
                    .then(product => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                        this.$store.dispatch('widgets/fetch');

                        this.$emit('transaction:successful', {
                            product: {
                                productName: 'efkStake',
                                productId: product.id,
                                openedAt: product.openedAt,
                                purchaseAmount: product.purchaseAmount,
                                purchaseCurrency: product.purchaseCurrency,
                                interestRate: product.interestRate,
                                duration: product.duration,
                                durationUnit: product.durationUnit
                            }
                        });

                        this.$refs.formEFKStake.reset();

                        this.parameters.purchaseAmount = '';
                        this.parameters.duration = '';

                        this.acceptTerms = false;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formEFKStake
                    .validate()
                    .then(result => {
                        if (result) {
                            this.processPurchase();
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.submitButtonLoading = false;
                    });
            }
        },

        mounted() {
            if (this.maxStake) {
                const balances = this.$store.getters['widgets/data:balances'];
                const efkBalance = balances['EFK'];

                if (efkBalance) {
                    const purchaseAmount = roundAmount(efkBalance, 'efk').replace('.', ',');
                    this.parameters.purchaseAmount = purchaseAmount;
                    this.parameters.duration = 12;
                }
            }
        }
    };
</script>

<style scoped lang="scss"></style>
