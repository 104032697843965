<template>
    <v-app-bar>
        <v-tabs v-model="tabIndex" :class="['app-tabs', 'v-tabs__' + tabs.type]" centered show-arrows>
            <template v-if="tabs.type === 'steps'">
                <v-tab v-for="(tab, index) in tabs.data" :key="tab.id" disabled>
                    <span v-if="$vuetify.breakpoint.mdAndUp">
                        {{ index + 1 }}. {{ $t(i18nPrefix + '.' + tab.name) }}
                    </span>

                    <span v-else>{{ index + 1 }}.</span>
                </v-tab>
            </template>

            <template v-else-if="tabs.type === 'selection'">
                <v-tab v-for="tab in tabs.data" :key="tab.name">
                    <font-awesome-icon v-if="tab.icon" class="mr-3" :icon="['fad', tab.icon]"></font-awesome-icon>
                    {{ $t(i18nPrefix + '.' + tab.name) }}
                </v-tab>
            </template>
        </v-tabs>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'AppDialogTabs',

        props: {
            tabs: {
                required: true
            },
            currentTabIndex: {
                type: Number,
                default: 0
            },
            i18nPrefix: {
                required: false,
                default: ''
            }
        },

        data: () => ({
            innerTabIndex: 0
        }),

        computed: {
            tabIndex: {
                get() {
                    return this.tabs.type === 'steps' ? this.currentTabIndex : this.innerTabIndex;
                },
                set(newIndex) {
                    this.innerTabIndex = newIndex;

                    this.$emit('tab:change', this.innerTabIndex);
                }
            }
        },

        watch: {
            currentTabIndex(newVal) {
                this.innerTabIndex = newVal;
            }
        }
    };
</script>

<style lang="scss"></style>
