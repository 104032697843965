<template>
    <v-expansion-panels v-model="panelsOpened">
        <app-expansion-panel v-if="product" :title="$t('bo.products.productSummaryDialog.purchaseDetailsPanel.title')">
            <app-flex class="tree-items">
                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.participationAmount') }}
                        </template>

                        {{ purchaseAmount }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.productId') }}
                        </template>

                        {{ productId }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.openedAt') }}
                        </template>

                        {{ openedAt }}
                    </app-text-stack>
                </app-flex-item>
            </app-flex>

            <app-flex class="tree-items mt-6">
                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.totalAppreciation') }}
                        </template>

                        {{ totalAppreciation }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.quarterlyInterestPayment') }}
                        </template>

                        {{ quarterlyInterestPayment }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.interestRate') }}
                        </template>

                        {{ interestRate }}
                    </app-text-stack>
                </app-flex-item>
            </app-flex>
        </app-expansion-panel>

        <app-expansion-panel v-if="payment" :title="$t('bo.products.productSummaryDialog.purchasePaymentPanel.title')">
            <app-title justify="center">
                {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.title') }}
            </app-title>

            <app-text justify="center">
                {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.description4trans1') }}
            </app-text>

            <app-row justify="center">
                <app-registration-col>
                    <app-card text-white increased-shadow narrow v-color:broker>
                        <app-title text-white justify="center">{{ bankAccount.accountNumber }}</app-title>
                    </app-card>
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col whole-screen>
                    <app-flex>
                        <app-flex-item class="two-items">
                            <app-text-stack justify="center">
                                <template #key>
                                    {{
                                        $t(
                                            'bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.amount'
                                        )
                                    }}
                                </template>

                                {{ purchaseAmount }}
                            </app-text-stack>
                        </app-flex-item>

                        <app-flex-item class="two-items">
                            <app-text-stack justify="center">
                                <template #key>
                                    {{
                                        $t(
                                            'bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.paymentIdentifier'
                                        )
                                    }}
                                </template>

                                {{ paymentIdentifier }}
                            </app-text-stack>
                        </app-flex-item>
                    </app-flex>
                </app-registration-col>
            </app-row>

            <app-text justify="center">
                {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.description4trans2') }}
            </app-text>

            <app-divider />

            <app-row>
                <app-registration-col whole-screen>
                    <p>
                        {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.copyInEmail') }}
                    </p>
                </app-registration-col>
            </app-row>
        </app-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'Summary4Trans',

        components: { AppExpansionPanel, AppFlex, AppFlexItem },

        props: ['product', 'payment', 'panels'],

        data: () => ({
            _panels: [],
            bankAccount: {
                accountNumber: '2801936975/2010'
            }
        }),

        computed: {
            panelsOpened: {
                get() {
                    return this.panels;
                },
                set() {
                    this.$emit('update:panels', this.$data._panels);
                }
            },

            productId() {
                if (this.isEmpty(this.product?.productId)) {
                    return '-';
                }

                return this.product?.productId;
            },
            openedAt() {
                if (this.isEmpty(this.product?.openedAt)) {
                    return '-';
                }

                return this.formatTimestamp(this.product?.openedAt);
            },
            purchaseAmount() {
                if (this.isEmpty(this.product?.purchaseAmount) || this.isEmpty(this.product?.purchaseCurrency)) {
                    return '-';
                }

                const purchaseAmount = parseFloat(this.product?.purchaseAmount)?.toFixed();
                const purchaseCurrency = this.product?.purchaseCurrency;

                return this.formatAmount(purchaseAmount, purchaseCurrency) + ' ' + purchaseCurrency;
            },
            totalAppreciation() {
                if (this.isEmpty(this.product?.totalAppreciation)) {
                    return '-';
                }

                return this.formatAmount(this.product?.totalAppreciation, 'czk') + ' CZK';
            },
            interestRate() {
                if (this.isEmpty(this.product?.interestRate)) {
                    return '-';
                }

                return this.product?.interestRate + ' % p.a.';
            },
            paymentIdentifier() {
                if (this.isEmpty(this.payment?.paymentIdentifier)) {
                    return '-';
                }

                return this.payment?.paymentIdentifier;
            },
            quarterlyInterestPayment() {
                if (this.isEmpty(this.product?.quarterlyInterestPayment)) {
                    return '-';
                }

                return this.formatAmount(this.product?.quarterlyInterestPayment, 'czk') + ' CZK';
            }
        }
    };
</script>

<style scoped></style>
