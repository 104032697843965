import EventBus from '@lb-world/core/public/plugins/eventBus';

import Vue from 'vue';

import moment from 'moment-timezone';

import { CURRENT_APP_DEV_URL, CURRENT_APP_URL } from '@/constants';

export const API_INTERNAL_ERROR = 'server_error';
export const API_SERVER_ERROR = 'server_error';

export const FALLBACK_LANGUAGE = 'en-US';
export const LANGUAGES = [
    {
        name: 'English',
        iso: 'en-US',
        id: 'en',
        aliases: ['en', 'en-us']
    },
    {
        name: 'Čeština',
        iso: 'cs-CZ',
        id: 'cs',
        aliases: ['cs', 'cs-cz']
    }
];

export const createCustomErrorKey = (errorKey, attribute) => {
    return 'errors.attributes.' + errorKey + '.' + attribute;
};

export const createWalletsFilterOutput = function(wallets) {
    if (wallets.length <= 0) {
        return '';
    }

    let filterWallets = '';

    for (let w in wallets) {
        if (wallets[w]) {
            filterWallets += w += ',';
        }
    }

    if (filterWallets.length > 0) {
        filterWallets = filterWallets.slice(0, -1);
    }

    return filterWallets;
};

export const parseErrorMessageFromAPI = function(errorObject) {
    if (errorObject.data && errorObject.data.data) {
        const errorKey = errorObject.data.data.errorKey;
        const attributes = errorObject.data.data.attributes;

        if (errorKey && attributes) {
            return { errorKey, attributes };
        } else if (errorKey) {
            return { errorKey };
        } else {
            return API_SERVER_ERROR;
        }
    }

    return API_SERVER_ERROR;
};

export const JWTDecode = function(token) {
    const decode = require('jwt-decode');

    return decode(token).user;
};

export const JWTRefreshOnExpiration = function(expiration) {
    const renewIn = (expiration - moment().unix()) * 1000;
    const renewReserve = 60 * 1000;

    setTimeout(function() {
        Vue.$log.debug('[JWT] Running refresh event in JWTRefreshOnExpiration', renewIn - renewReserve);

        EventBus.$emit('jwt:refresh');
    }, renewIn - renewReserve);
};

export const isProduction = () => {
    return process.env.VUE_APP_MODE === 'production';
};

export const getAppUrlFormat = function(app, section) {
    console.log('GETAPPURLFORMAAAAAAAAAAAAAAT');
    console.log(app, section);
    let prefix = '';

    if (!isProduction()) {
        prefix = 'dev.';
    } else if (app === 'world') {
        prefix = 'account.';
    }

    return 'https://' + prefix + 'lb' + app + '.eu' + (section ?? '');
};

export const getWorldSignInUrlFormat = function(redirectParam = true) {
    const url = new URL(getWorldAppUrl());

    if (redirectParam) {
        url.searchParams.append('continue', isProduction() ? CURRENT_APP_URL : CURRENT_APP_DEV_URL);
    }

    return url;
};

export function getApiUrl() {
    return isProduction() ? 'https://api.lbworld.eu/v1/' : 'https://api.dev.lbworld.eu/v1/';
}

export function getAuthApiUrl() {
    return isProduction() ? 'https://api.lbbroker.eu/v1/' : 'https://api.dev.lbbroker.eu/v1/';
}

export function getWorldAppUrl() {
    return isProduction() ? 'https://account.lbworld.eu' : 'https://dev.lbworld.eu';
}
