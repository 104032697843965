<template>
    <app-section-navigation-col bigger-col class="pl-0">
        <app-card class="py-9 box-shadow-none create-transaction-style">
            <app-loader v-if="!productFetched" />

            <validation-observer ref="formEFKLiquidityValue" v-slot="{ invalid }" v-else>
                <p
                    v-if="!isBankAccountFilled"
                    style="background-color: rgba(248,172,157,0.8); color: #de5346; padding: 12px 8px; margin-left: 12px; margin-right: 12px; margin-bottom: 24px; border-radius: 4px"
                >
                    Před provedením transakce je nutné vyplnit svůj bankovní účet.
                </p>

                <app-row class="max-width-600">
                    <app-col class="mb-0" full-space>
                        <app-text-field
                            v-model="parameters.purchaseAmount"
                            type="number"
                            :step="product.purchaseAmountStep"
                            :rules="{
                                required: true,
                                regex: numberRegex,
                                min_value: [product.minimalPurchaseAmount, currency],
                                decimals: 2
                            }"
                            :label="$t('bo.products.efkLiquidity.create.sellAmount')"
                        >
                            <template #append>
                                {{ currency }}
                            </template>
                        </app-text-field>

                        <div class="mt-4">
                            <app-text-field
                                type="number"
                                :step="product.saleRateStep"
                                v-model="parameters.saleRate"
                                :rules="{ required: true, regex: rateRegexp, decimals: 4, min_value: 0.0001 }"
                                :label="$t('bo.products.common.saleRate')"
                            >
                                <template #append>
                                    USD/EFK
                                </template>
                            </app-text-field>
                        </div>

                        <p
                            v-if="product.minimalPurchaseAmount"
                            class="text-note text-start"
                            v-html="
                                $t('bo.products.efkLiquidity.create.minimalPurchaseAmount', {
                                    amount: formatAmount(product.minimalPurchaseAmount)
                                })
                            "
                        />

                        <p
                            v-if="product.maximalPurchaseAmount"
                            class="text-note text-start"
                            v-html="
                                $t('bo.products.efkLiquidity.create.maximalPurchaseAmount', {
                                    amount: formatAmount(product.maximalPurchaseAmount)
                                })
                            "
                        />
                    </app-col>
                </app-row>

                <app-divider />

                <app-row>
                    <app-col full-space>
                        <app-switch-field
                            button-events
                            :centered="$vuetify.breakpoint.xsOnly"
                            v-model="acceptTerms"
                            @button:click="openProductDocument"
                            :button-loading="productDocumentButtonLoading"
                        >
                            {{ $t('bo.products.efkLiquidity.create.agree') }}

                            <template v-slot:button>
                                {{ $t('bo.products.download.efkControlledLiquidityProductConditions') }}
                            </template>
                        </app-switch-field>
                    </app-col>
                </app-row>
                <app-row>
                    <app-col full-space>
                        <button-wrapper :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'start'">
                            <vt-button-primary
                                full-width
                                @click="submit"
                                :disabled="invalid || !acceptTerms || !userVerified || !isBankAccountFilled"
                                :loading="submitButtonLoading"
                            >
                                {{ $t('bo.products.efkLiquidity.create.submit') }}
                            </vt-button-primary>
                        </button-wrapper>
                    </app-col>
                </app-row>
            </validation-observer>
        </app-card>
    </app-section-navigation-col>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import { ValidationObserver } from 'vee-validate';

    import { DOCUMENT_PRODUCT_SPECS_EFK_CONTROLLED_LIQUIDITY } from '@/constants';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    import AppCard from '../../../ui/AppCard';
    import AppTextField from '../../../ui/form/AppTextField';
    import AppCol from '../../../ui/layout/AppCol';
    import AppSwitchField from '../../../ui/form/AppSwitchField';
    import AppSectionNavigationCol from '@/components/ui/layout/AppSectionNavigationCol';

    export default {
        name: 'CreateTransaction',

        components: {
            AppSectionNavigationCol,
            AppSwitchField,
            AppCol,
            AppTextField,
            AppCard,
            ValidationObserver
        },

        props: ['productId', 'product', 'productFetched'],

        data: () => ({
            parameters: {
                purchaseAmount: 0,
                saleRate: ''
            },

            currency: 'EFK',

            productDocumentButtonLoading: false,
            submitButtonLoading: false,
            acceptTerms: false,

            rateRegexp: getDecimalNumberRegex()
        }),

        computed: {
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },
            userVerified() {
                return this.$store.getters['auth/token:role'](UserRoles.VERIFIED);
            },
            walletBalances() {
                return this.$store.getters['widgets/data:balances'];
            },
            isBankAccountFilled() {
                return this.$store.getters['auth/token:role'](UserRoles.HAVING_BANK_ACCOUNT);
            }
        },

        methods: {
            hasLimitAvailable(remaining, amount) {
                return remaining === null || remaining - amount > 0;
            },
            openProductDocument() {
                this.productDocumentButtonLoading = true;

                const windowRef = window.open();

                this.$store
                    .dispatch(
                        'documents/document:request',
                        DOCUMENT_PRODUCT_SPECS_EFK_CONTROLLED_LIQUIDITY + '--' + this.$i18n.locale.substring(0, 2)
                    )
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.productDocumentButtonLoading = false;
                    });
            },
            processPurchase() {
                const productId = this.productId;
                const parameters = this.deepCopy(this.parameters);

                parameters.purchaseAmount = this.convertLocaleToNumber(parameters.purchaseAmount);

                this.$store
                    .dispatch('products/PA:open', {
                        parameters,
                        productId
                    })
                    .then(product => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                        this.$store.dispatch('widgets/fetch');

                        this.$emit('transaction:successful', {
                            product: {
                                productName: 'efkControlledLiquidity',
                                productId: product.id,
                                openedAt: product.openedAt,
                                purchaseAmount: product.purchaseAmount,
                                purchaseCurrency: product.purchaseCurrency,
                                saleRate: product.saleRate
                            }
                        });

                        this.$refs.formEFKLiquidityValue.reset();

                        this.parameters.purchaseAmount = '';
                        this.parameters.saleRate = '';

                        this.acceptTerms = false;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formEFKLiquidityValue
                    .validate()
                    .then(result => {
                        if (result) {
                            const walletAmount = this.walletBalances['EFK'];

                            if (walletAmount !== undefined && this.parameters.purchaseAmount > walletAmount) {
                                EventBus.$emit('snackbar:show:danger', {
                                    errorKey: 'invalid_state',
                                    attributes: ['purchaseAmount']
                                });

                                this.submitButtonLoading = false;

                                return;
                            }

                            this.processPurchase();
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.submitButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
