<template>
    <app-card dialog>
        <component
            ref="withdrawalComponent"
            :is="currency"
            :withdrawal="withdrawal"
            :walletBalance="balance"
            :minimalAmountWithdraw="minimalAmountWithdraw"
            :currency="currency"
            @change="$emit('update:data', $event)"
        />

        <app-divider />

        <template #button-primary>
            <button-primary @click="withdrawCurrency" :loading="withdrawButtonLoading">
                {{ $t('bo.wallets.currencyWithdrawalDialog.confirm') }}
            </button-primary>
        </template>
    </app-card>
</template>

<script>
    import WithdrawalEFK from '@/components/module-user/wallets/withdrawalDialog/WithdrawalEFK';
    import WithdrawalCZK from '@/components/module-user/wallets/withdrawalDialog/WithdrawalCZK';

    export default {
        name: 'WithdrawalDataStep',

        components: { EFK: WithdrawalEFK, CZK: WithdrawalCZK },

        props: ['withdrawal', 'currency', 'balance'],

        data: () => ({
            minimalAmountWithdraw: 1000,

            withdrawButtonLoading: false,
            walletBalance: 0
        }),

        methods: {
            withdrawCurrency() {
                this.withdrawButtonLoading = true;

                this.$refs['withdrawalComponent'].$refs['formCurrencyWithdrawalDialog' + this.currency]
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$emit('process:next', this.withdrawal);
                        } else {
                            this.withdrawButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.withdrawButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
