<template>
    <app-row justify="center">
        <app-col auto class="apps-view-col__inner">
            <app-logo class="app-logo" :size="$vuetify.breakpoint.mdAndUp ? 'xl' : 'md'" />

            <div class="app-integrations">
                <div v-if="$vuetify.breakpoint.mdAndUp">
                    <div class="app-overview" v-for="app in apps" :key="app.name">
                        <app-logo size="sm" :logo-id="app.id" />
                    </div>
                </div>

                <div v-else class="app-integrations-mobile">
                    <app-logo size="xs" :logo-id="app.id" logo-only v-for="app in apps" :key="app.name" />
                </div>
            </div>
        </app-col>
    </app-row>
</template>

<script>
    import { LBServicesFactory } from '@lb-world/core/public/static/apps';

    import AppCol from './layout/AppCol';

    export default {
        name: 'AppSloganIntegrationsView',

        components: { AppCol },

        computed: {
            apps() {
                return LBServicesFactory.services();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .apps-view-col__inner {
        @include media.resolve('md-and-up') {
            position: relative;
            left: -10%;
        }

        @include media.resolve('lg-and-up') {
            position: relative;
            left: -25%;
        }

        @include media.resolve('md-and-down') {
        }
    }

    .app-logo__slogan {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media.resolve('md-and-down') {
            margin-top: spacing.$spacing-1;
            margin-bottom: 0 !important;
        }

        @include media.resolve('lg-and-up') {
            position: relative;
        }

        span.app-slogan--smaller {
            margin-bottom: 0;

            @include text.TextMedium-16-100;
        }
    }

    .app-integrations {
        margin-top: spacing.$spacing-9;

        @include media.resolve('sm-and-down') {
            margin-top: spacing.$spacing-3;
            margin-left: 0;
        }
    }

    .app-integrations-mobile {
        display: flex;

        .app-logo {
            margin-top: spacing.$spacing-2;
            margin-right: spacing.$spacing-2;
        }
    }

    .app-logo {
        display: flex;
    }

    .app-overview {
        display: flex;
        align-items: center;

        margin-top: spacing.$spacing-3;

        .app-overview__color {
            height: 20px;
            width: 15px;
        }
    }
</style>
