import store from '../store/index';

import Vue from 'vue';

import { setLanguage } from '@lb-world/core/public/locales/engine';

import { checkForMaintenance, checkForServerError, MAINTENANCE_ROUTE, SERVER_ERROR_ROUTE } from '@/overlays';

import { UserRoles } from '@lb-world/core/public/static/roles';

export function includesRoute(to, route) {
    return to.matched.find(r => r.path === route);
}

export function checkAccessToken() {
    Vue.$log.debug('[SYSTEM] checkAccessToken: Init');

    return new Promise((resolve, reject) => {
        const token = store.getters['auth/token'];

        if (!token) {
            Vue.$log.debug('[SYSTEM] checkAccessToken: No token');

            store
                .dispatch('auth/token:store')
                .then(({ decodedToken, expiration }) => {
                    store.dispatch('auth/token:refresh', expiration);

                    Vue.$log.debug('[SYSTEM] checkAccessToken: Token fetched', decodedToken);

                    resolve();
                })
                .catch(({ statusCode }) => {
                    Vue.$log.debug('[SYSTEM] checkAccessToken: Token not fetched, reject()');

                    if (checkForMaintenance(statusCode)) {
                        reject(MAINTENANCE_ROUTE);
                    } else if (checkForServerError(statusCode)) {
                        reject(SERVER_ERROR_ROUTE);
                    }

                    reject();
                });
        } else {
            Vue.$log.debug('[SYSTEM] checkAccessToken: Token found, resolve()');

            resolve();
        }
    });
}

export function userFetch() {
    return new Promise((resolve, reject) => {
        const token = store.getters['auth/token'];

        if (token) {
            store
                .dispatch('auth/user:fetch', token)
                .then(account => {
                    Vue.$log.debug('[SYSTEM] user:fetch successful');

                    if (account.language) {
                        setLanguage(account.language).then(() => {
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                })
                .catch(() => {
                    reject();
                });
        } else {
            reject();
        }
    });
}

export function userRouting(to, from, next) {
    Vue.$log.debug('[SYSTEM] Routing to', to.fullPath);

    // User token is not fetched yet, returning control to UserSignIn
    // to fetch token & user
    if (!store.getters['auth/token:fetched']) {
        Vue.$log.debug('[SYSTEM] Token not fetched, returning control to SignIn');

        const path = to.query.stake ? to.path + ('?stake=' + to.query.stake) : to.path;
        const queries = { ...to.query, redirect: path };

        next({
            path: '/',
            query: queries
        });

        return;
    }

    const userToken = store.getters['auth/token'];
    const registered = store.getters['auth/token:role'](UserRoles.REGISTERED);
    const account = store.getters['user/user'];

    Vue.$log.debug('[AUTH] Logging into account', userToken);

    if (userToken && account && registered) {
        if (includesRoute(to, '/account')) {
            Vue.$log.debug('[ROUTING] Allowing access route to "/account/*"');

            next();
        } else {
            Vue.$log.debug('[ROUTING] Redirecting to route "account:services"');

            next({ name: 'account:dashboard' });
        }
    } else {
        Vue.$log.debug('[ROUTING] Rerouting user to "/"');

        next('/');
    }
}
