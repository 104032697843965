<script>
    import { Line, mixins } from 'vue-chartjs';
    import myIcon from '@/assets/icons/circle.svg';

    export default {
        name: 'LineChart',

        extends: Line,

        props: ['color', 'gradient'],

        mixins: [mixins.reactiveProp],

        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                }
            }
        }),

        mounted() {
            this.chartData.datasets[0].fill = true;

            let ctx = document.getElementById('line-chart').getContext('2d');
            let ggg = ctx.createLinearGradient(0, 140, 0, 0);
            ggg.addColorStop(0, '#EE714D00');
            ggg.addColorStop(1, '#EE714D');

            const chartPoint = new Image();
            chartPoint.src = myIcon;

            console.log(this.chartData.datasets[0], this.$props);
            this.chartData.datasets[0].backgroundColor = ggg;
            this.chartData.datasets[0].borderColor = 'rgba(255, 74, 22, 1)';
            this.chartData.datasets[0].pointStyle = chartPoint;

            this.renderChart(this.chartData, this.options);
        }
    };
</script>

<style scoped lang="scss"></style>
