import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Plugins import
import vuetify from '@lb-world/core/public/plugins/vuetify';
import i18n from '@lb-world/core/public/plugins/i18n';
import projectInit from '@lb-world/core/public/plugins/project';

import '@lb-world/core/public/plugins/validation';
import '@lb-world/core/public/plugins/eventBus';
import '@lb-world/core/public/plugins/logger';

import './plugins/icons';
import './plugins/axios';

Vue.use(projectInit);

// Directives import

// Components import
import AppCard from '@/components/ui/AppCard';

import ButtonPrimary from '@/components/ui/buttons/ButtonPrimary';
import ButtonSecondary from '@/components/ui/buttons/ButtonSecondary';
import ButtonWrapper from '@/components/ui/buttons/ButtonWrapper';
import VtButtonPrimary from '@/components/ui/buttons/VtButtonPrimary';
import VtButtonSecondary from '@/components/ui/buttons/VtButtonSecondary';
import VtButtonCustom from '@/components/ui/buttons/VtButtonCustom';
import LettersIcon from '@/components/ui/icons/LettersIcon';

import AppRow from '@/components/ui/layout/AppRow';
import AppRegistrationCol from '@/components/ui/layout/AppRegistrationCol';

Vue.component('app-card', AppCard);
Vue.component('button-primary', ButtonPrimary);
Vue.component('button-secondary', ButtonSecondary);
Vue.component('button-wrapper', ButtonWrapper);
Vue.component('app-row', AppRow);
Vue.component('app-registration-col', AppRegistrationCol);
Vue.component('vt-button-primary', VtButtonPrimary);
Vue.component('vt-button-secondary', VtButtonSecondary);
Vue.component('vt-button', VtButtonCustom);
Vue.component('l-icon', LettersIcon);

// Configurations
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
