<template>
    <v-col cols="12" sm="6" md="12" class="mb-2 prod-mini-card">
        <v-container class="ma-0 pa-0">
            <div class="ma-0 pa-0 d-flex flex-wrap justify-space-between">
                <div class="d-flex flex-row col-sm-6 col-md-12 col-lg-6 place-items-center">
                    <l-icon class="mr-4 icon-style" background="#f0dbff" color="#be30e0">{{
                        productItem.shortcut
                    }}</l-icon>
                    <div class="d-flex flex-column">
                        <div>
                            {{ $t('productTypes.EFK_CONTROLLED_LIQUIDITY') }}
                        </div>
                        <div class="mt-1 d-block about-font">
                            {{ $t('bo.dashboard.widgets.buyProductsWidget.miniCards.EfkLiquidityMiniCard.about') }}
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap flex justify-space-around align-content-center">
                    <create-transaction-dialog iconText="ECL" heading="EFK Řízená Likvidita" small>
                        <app-card full-width v-if="!productFetched" class="py-9">
                            <app-loader>{{ $t('common.loadingProduct') }}</app-loader>
                        </app-card>
                        <create-transaction
                            class="box-shadow-none"
                            v-else
                            :product-id="productId"
                            :product="product"
                            :product-fetched="productFetched"
                            show-transaction-subtype
                            @transaction:successful="$emit('transaction:successful', $event)"
                            @limitDialog:open="$emit('limitDialog:open', $event)"
                            @editProductDialog:open="$emit('editProductDialog:open', $event)"
                        />
                    </create-transaction-dialog>
                    <vt-button-secondary small :to="{ name: productItem.path }" class="ma-2">{{
                        $t('bo.dashboard.widgets.buyProductsWidget.miniCards.moreBtn')
                    }}</vt-button-secondary>
                </div>
            </div>
        </v-container>
    </v-col>
</template>

<script>
    import { PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY } from '@/constants';
    import { mapGetters } from 'vuex';

    import CreateTransactionDialog from '../VtCreateTransactionDialog';
    import CreateTransaction from './VtCreateTransaction';

    export default {
        name: 'DigiRentMiniCard',

        props: {
            productItem: Object
        },

        components: {
            CreateTransactionDialog,
            CreateTransaction
        },

        data: () => ({
            productId: PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY,

            benefits: [
                'Snadná směna EFK tokenu bez nutnosti registrace na jednotlivé burzy',
                'Využití robotického obchodování pro maximalizaci hodnoty směny'
            ],

            pdfTeaserButton: {
                loading: false
            }
        }),

        computed: {
            ...mapGetters('products', {
                productFilter: 'products:filter',
                productFetched: 'products:fetched',
                productError: 'products:error'
            }),
            product() {
                return this.$store.getters['products/products:filter'](PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY);
            },
            productFetched() {
                return this.$store.getters['products/products:fetched'];
            }
        }
    };
</script>

<style scoped lang="scss"></style>
