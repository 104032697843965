var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-section-navigation-col',{staticClass:"pl-0",attrs:{"bigger-col":""}},[_c('app-card',{staticClass:"py-9 app-card-style create-transaction-style"},[(!_vm.productFetched)?_c('app-loader'):_c('validation-observer',{ref:"formEFKPurchase",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('app-row',[_c('app-col',{staticClass:"mb-0",attrs:{"full-space":""}},[_c('app-text-field',{attrs:{"type":"number","step":_vm.product.purchaseAmountStep,"rules":{
                            required: true,
                            max_value: [_vm.product.maximalPurchaseAmount, _vm.currency],
                            min_value: [_vm.product.minimalPurchaseAmount, _vm.currency],
                            multiple: _vm.product.purchaseAmountStep
                        },"label":_vm.$t('bo.products.efkPurchase.create.purchaseAmount')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.currency)+" ")]},proxy:true}],null,true),model:{value:(_vm.parameters.purchaseAmount),callback:function ($$v) {_vm.$set(_vm.parameters, "purchaseAmount", $$v)},expression:"parameters.purchaseAmount"}},[_vm._v("v ")]),_c('div',{staticClass:"mt-4"},[_c('app-text-field',{attrs:{"type":"number","step":_vm.product.purchaseRateStep,"rules":{ required: true, decimals: 4, regex: _vm.rateRegexp, min_value: 0.0001 },"label":_vm.$t('bo.products.common.maxPurchaseRate')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" USD/EFK ")]},proxy:true}],null,true),model:{value:(_vm.parameters.maxPurchaseRate),callback:function ($$v) {_vm.$set(_vm.parameters, "maxPurchaseRate", $$v)},expression:"parameters.maxPurchaseRate"}})],1)],1)],1),_c('app-text',{attrs:{"note":"","justify":"start"}},[(_vm.product.minimalPurchaseAmount)?_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('bo.products.efkPurchase.create.minimalPurchaseAmount', {
                            amount: _vm.formatAmount(_vm.product.minimalPurchaseAmount)
                        })
                    )}}):_vm._e(),(_vm.product.maximalPurchaseAmount)?_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('bo.products.efkPurchase.create.maximalPurchaseAmount', {
                            amount: _vm.formatAmount(_vm.product.maximalPurchaseAmount)
                        })
                    )}}):_vm._e()]),_c('app-text',{attrs:{"note":"","justify":"start"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('bo.products.efkPurchase.create.feePurchaseLabel', {
                            fee: 6
                        })
                    )}})]),_c('app-divider'),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('app-switch-field',{attrs:{"centered":_vm.$vuetify.breakpoint.xsOnly,"button-events":"","button-loading":_vm.productDocumentButtonLoading},on:{"button:click":_vm.openProductDocument},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.products.download.efkPurchaseProductConditions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkPurchase.create.agree'))+" ")])],1)],1),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('button-wrapper',{attrs:{"justify":_vm.$vuetify.breakpoint.xsOnly ? 'center' : 'start'}},[_c('vt-button-primary',{attrs:{"full-width":"","disabled":invalid || !_vm.acceptTerms || !_vm.userVerified,"loading":_vm.submitButtonLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkPurchase.create.submit'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }