<template>
    <v-container class="ma-0 ma-md-5 pa-0">
        <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
                <v-container class="ma-0 pa-0 full-width">
                    <v-container class="container-card">
                        <div class="d-flex ma-4">
                            <img src="@/assets/icons/rewatch.svg" />
                            <div class="ml-3 align-self-center transaction-history-font">
                                {{ $t('bo.transactions.transactionsHistory') }}
                            </div>
                        </div>

                        <v-row>
                            <v-col></v-col>
                            <v-col
                                cols="12"
                                lg="9"
                                :class="{
                                    'place-center': $vuetify.breakpoint.xs,
                                    'place-end': $vuetify.breakpoint.smAndUp
                                }"
                                class="d-flex flex-wrap"
                            >
                                <div class="d-flex single-line mr-0 ml-4 white-space-nowrap place-items-center">
                                    {{ pages.totalCount }} {{ $t('table.totalCount') }}
                                </div>
                                <div class="d-flex mr-n2 ml-4 place-content-flex-end">
                                    <vt-table-pagination v-on="$listeners" />
                                </div>
                            </v-col>
                        </v-row>

                        <hr class="mt-6" />

                        <v-data-table
                            class="app-table"
                            :loading="loading"
                            :items="items"
                            :headers="headers"
                            hide-default-footer
                            disable-sort
                            :page.sync="pages.current"
                        >
                            <template v-slot:item.amount="{ item }">
                                <div v-if="item.amount !== null">
                                    <span>{{ formatAmount(item.amount, item.currency) }}</span>
                                    <span class="ml-1">{{ item.currency }}</span>
                                </div>

                                <span v-else v-color:black.translucent>{{ $t('common.none') }}</span>
                            </template>

                            <template v-slot:item.createdAt="{ item }">
                                {{ formatTimestamp(item.createdAt) }}
                            </template>

                            <template v-slot:item.type="{ item }">
                                {{ $t('transactionTypes.' + item.type) }}
                            </template>

                            <template v-slot:item.subType="{ item }">
                                <span v-if="item.subType">
                                    {{ $t('transactionSubtypes.' + item.subType) }}
                                </span>

                                <span v-else v-color:black.translucent>
                                    {{ $t('common.none') }}
                                </span>
                            </template>

                            <template v-slot:item.state="{ item }">
                                <span v-color:[getStateTextColor(item)]>
                                    {{ $t('bo.products.common.accountState.' + item.state) }}
                                </span>
                            </template>

                            <template v-slot:loading>
                                <p class="v-data-table--info">
                                    {{ $t('table.loadingData') }}
                                </p>
                            </template>

                            <template v-slot:no-data>
                                <p v-if="!fetchError" class="v-data-table--info">
                                    {{ $t('common.noData') }}
                                </p>
                                <p v-else class="v-data-table--info">
                                    {{ $t('table.fetchError') }}
                                </p>
                            </template>
                        </v-data-table>

                        <table-pagination v-on="$listeners" />
                    </v-container>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { core, pagination } from '@lb-world/core/public/components/tables/mixins';
    import VtTablePagination from '@/components/ui/tables/VtTablePagination';

    export default {
        name: 'ProductTransactionsHistoryTable',
        // eslint-disable-next-line vue/no-unused-components
        components: { VtTablePagination },
        mixins: [core, pagination],

        props: {
            showTransactionSubtype: {
                type: Boolean
            }
        },

        computed: {
            headers() {
                let headersColumns = [
                    {
                        text: this.$t('table.columns.transactionId'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.date'),
                        value: 'createdAt'
                    },
                    {
                        text: this.$t('table.columns.amount'),
                        value: 'amount'
                    },
                    {
                        text: this.$t('table.columns.type'),
                        value: 'type'
                    }
                ];

                if (this.showTransactionSubtype) {
                    headersColumns = [
                        ...headersColumns,
                        {
                            text: this.$t('table.columns.subtype'),
                            value: 'subType'
                        }
                    ];
                }

                return headersColumns;
            }
        },

        methods: {
            getStateTextColor(item) {
                switch (item.state) {
                    case 'open':
                        return 'success';

                    case 'closed':
                        return 'danger';

                    default:
                        return null;
                }
            }
        }
    };
</script>

<style scoped></style>
