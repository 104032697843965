var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-section-navigation-col',{staticClass:"pl-0",attrs:{"bigger-col":""}},[_c('app-card',{staticClass:"py-9 box-shadow-none create-transaction-style"},[(!_vm.productFetched)?_c('app-loader'):_c('validation-observer',{ref:"form4Trans",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('app-row',{staticClass:"max-width-600"},[_c('app-col',{staticClass:"mb-0",attrs:{"full-space":""}},[_c('app-text-field',{attrs:{"rules":{
                            min_value: [10000, 'CZK'],
                            required: true,
                            regex: _vm.numberRegex
                        },"label":_vm.$t('bo.products.4trans.create.participationAmount')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" CZK ")]},proxy:true}],null,true),model:{value:(_vm.parameters.purchaseAmount),callback:function ($$v) {_vm.$set(_vm.parameters, "purchaseAmount", $$v)},expression:"parameters.purchaseAmount"}})],1)],1),_c('app-text',{attrs:{"note":"","justify":"start"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('bo.products.4trans.create.totalAppreciation', {
                            amount: _vm.formatAmount(_vm.totalAppreciation, 'czk'),
                            currency: 'CZK'
                        })
                    )}}),_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('bo.products.4trans.create.quarterlyInterestPayment', {
                            amount: _vm.formatAmount(_vm.quarterlyInterestPayment, 'czk'),
                            currency: 'CZK'
                        })
                    )}})]),_c('app-text',{attrs:{"note":"","justify":"start"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('bo.products.4trans.create.minimalParticipationAmount', {
                            amount: _vm.formatAmount(10000),
                            currency: 'CZK'
                        })
                    )}})]),_c('app-divider'),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('app-switch-field',{attrs:{"centered":_vm.$vuetify.breakpoint.xsOnly,"button-events":"","button-loading":_vm.productDocumentButtonLoading},on:{"button:click":_vm.openProductDocument},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.products.download.trans4ProductConditions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.4trans.create.agree'))+" ")])],1)],1),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('button-wrapper',{attrs:{"justify":_vm.$vuetify.breakpoint.xsOnly ? 'center' : 'start'}},[_c('vt-button-primary',{attrs:{"full-width":"","disabled":invalid || !_vm.acceptTerms || !_vm.userVerified,"loading":_vm.submitButtonLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.4trans.create.submit'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }