<template>
    <div class="full-width">
        <activate-account-banner class="full-width" />
        <v-container class="ma-0 ma-md-5 pa-0">
            <edit-product-dialog :dialog="editProductDialog" />

            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                    <v-container class="ma-0 pa-0 full-width">
                        <v-container
                            v-for="widget in productsWidgets.data"
                            :key="widget"
                            class="container-card pa-0 mb-10"
                        >
                            <component
                                :is="widget.name"
                                @transaction:successful="$emit('transaction:successful', $event)"
                                @editProductDialog:open="openProductEditDialog($event)"
                            ></component>
                        </v-container>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import ActivateAccountBanner from '@/components/ui/ActivateAccountBanner';
    import EventBus from '@lb-world/core/public/plugins/eventBus';
    import EfkPurchase from './products/VtEFKPurchase';
    import EfkPurchaseStake from './products/VtEFKPurchaseStake';
    import EfkStake from './products/VtEFKStake';
    import EfkStakeRfi from './products/VtEfkStakeRfi';
    import EfkLiquidity from './products/VtEFKLiquidity';
    import EfkControlledLiquidity from './products/VtEFKControlledLiquidity.vue';
    import Trans4 from './products/VtProduct4Trans';
    import DigiRent from './products/VtDigiRent';

    import EditProductDialog from '@/components/module-user/products/EditProductDialog';

    export default {
        name: 'Portfolio',
        components: {
            ActivateAccountBanner,
            EditProductDialog,
            EfkPurchase,
            EfkPurchaseStake,
            EfkStake,
            EfkLiquidity,
            Trans4,
            DigiRent,
            EfkStakeRfi,
            EfkControlledLiquidity
        },
        data: () => ({
            editProductDialog: {
                open: false
            }
        }),

        computed: {
            productsFetched() {
                return this.$store.getters['products/products:fetched'];
            },
            productsWidgets() {
                return this.$store.getters['widgets/account:portfolio'];
            }
        },

        mounted() {
            if (!this.productsFetched) {
                this.fetchData();
            }
        },

        methods: {
            openProductEditDialog({ product, productRateType }) {
                this.$set(this.editProductDialog, 'product', product);
                this.$set(this.editProductDialog, 'productRateType', productRateType);
                this.editProductDialog.open = true;
            },
            fetchData() {
                this.$store.dispatch('products/products:clear').then(() => {
                    this.$store.dispatch('products/products:fetch').catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });

                this.$store.dispatch('transactions/transactions:clear');
            }
        }
    };
</script>

<style scoped></style>
