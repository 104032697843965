w<template>
    <validation-observer ref="formCurrencyTransferDialog">
        <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
            <app-card dialog>
                <app-dialog-title :title="$t('bo.wallets.currencyTransferDialog.title')" :dialog="dialog" />

                <app-card dialog>
                    <app-row justify="center">
                        <app-col>
                            <p>
                                {{ $t('bo.wallets.currencyTransferDialog.walletBalance') }}
                            </p>

                            <p>
                                <mark>
                                    {{ formatAmount(walletBalance, transfer.currency) }}
                                    {{ transfer.currency }} </mark
                                >.
                            </p>
                        </app-col>
                    </app-row>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('bo.wallets.currencyTransferDialog.recipient')"
                                v-model="transfer.recipient"
                                rules="required"
                            ></app-text-field>
                        </app-col>
                    </app-row>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('bo.wallets.currencyTransferDialog.amount')"
                                v-model="transfer.amount"
                                :rules="{
                                    required: true,
                                    regex: numberRegex,
                                    max_value: [walletBalance, transfer.currency],
                                    min_value: [minTransferAmount, transfer.currency],
                                    decimals: currencyDecimals[transfer.currency]
                                }"
                            >
                                <template #append>
                                    {{ transfer.currency }}
                                </template>
                            </app-text-field>
                        </app-col>
                    </app-row>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('bo.wallets.currencyTransferDialog.note')"
                                v-model="transfer.message"
                                optional
                                rules="max:80"
                            ></app-text-field>
                        </app-col>
                    </app-row>
                </app-card>

                <template #button-primary>
                    <button-primary @click="transferCurrency" :loading="transferButtonLoading">
                        {{ $t('bo.wallets.currencyTransferDialog.confirm') }}
                    </button-primary>
                </template>
            </app-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppCard from '../../ui/AppCard';
    import AppCol from '../../ui/layout/AppCol';
    import AppTextField from '../../ui/form/AppTextField';

    export default {
        name: 'CurrencyTransferDialog',

        props: ['dialog'],

        components: {
            AppTextField,
            AppCol,
            AppCard,
            ValidationObserver
        },

        data: () => ({
            transfer: {
                sender: '',
                recipient: '',
                currency: '',
                amount: '',
                message: ''
            },

            currencyDecimals: {
                USDT: 2,
                EFK: 4
            },

            minTransferAmount: 0.0001,
            walletBalance: 0,

            transferButtonLoading: false
        }),

        computed: {
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            }
        },

        methods: {
            controlWalletAmount(withdrawal) {
                return withdrawal.amount <= this.dialog.params.currencyBalance;
            },

            transferCurrency() {
                this.transferButtonLoading = true;

                const transfer = this.deepCopy(this.transfer);

                transfer.amount = this.convertLocaleToNumber(transfer.amount);

                if (this.controlWalletAmount(transfer)) {
                    this.$refs.formCurrencyTransferDialog
                        .validate()
                        .then(result => {
                            if (result) {
                                this.$store
                                    .dispatch('wallets/transfer', transfer)
                                    .then(() => {
                                        EventBus.$emit('snackbar:show:success', this.$t('success.currencyTransfer'));

                                        this.$store.dispatch('widgets/clear').then(() => {
                                            this.$store.dispatch('widgets/fetch');
                                        });

                                        this.$emit('transfer:successful');

                                        this.dialog.open = false;
                                    })
                                    .catch(error => {
                                        EventBus.$emit('snackbar:show:danger', error);
                                    })
                                    .finally(() => {
                                        this.transferButtonLoading = false;
                                    });
                            } else {
                                this.transferButtonLoading = false;
                            }
                        })
                        .catch(() => {
                            this.transferButtonLoading = false;
                        });
                }
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.transfer.sender = this.dialog.params?.senderId;
                        this.transfer.currency = this.dialog.params?.currency;
                        this.transfer.amount = '';
                        this.transfer.recipient = '';

                        this.walletBalance = this.dialog.params?.currencyBalance;

                        if (this.$refs.formCurrencyTransferDialog) {
                            this.$refs.formCurrencyTransferDialog.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
