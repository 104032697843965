const state = {
    accountTabs: {
        type: 'selection',
        i18nPrefix: 'bo.menu',
        data: [
            {
                name: 'dashboard',
                path: 'account:dashboard',
                icon: 'th'
            },
            {
                name: 'wallets',
                path: 'account:wallets',
                icon: 'wallet'
            },
            {
                name: 'transactions',
                path: 'account:transactions',
                icon: 'exchange'
            },
            {
                name: 'products',
                path: 'account:products',
                icon: 'box-alt',
                subgroup: {
                    i18nPrefix: 'bo.products.menu',
                    data: [
                        {
                            name: 'products',
                            path: 'account:products',
                            icon: 'box-alt'
                        },
                        {
                            name: 'portfolio',
                            path: 'account:portfolio',
                            icon: 'box-alt'
                        }
                    ]
                },
                o_subgroup: {
                    i18nPrefix: 'bo.products.menu',
                    data: [
                        {
                            name: 'efkStakeRfi',
                            path: 'account:products:efkStakeRfi',
                            icon: 'box-alt',
                            subgroup: {
                                i18nPrefix: 'bo.products.digiRent.menu',
                                data: [
                                    {
                                        name: 'active',
                                        path: 'account:products:digiRent:active'
                                    },
                                    {
                                        name: 'history',
                                        path: 'account:products:digiRent:history'
                                    }
                                ]
                            }
                        },
                        {
                            name: 'digiRent',
                            path: 'account:products:digiRent',
                            icon: 'box-alt',
                            subgroup: {
                                i18nPrefix: 'bo.products.digiRent.menu',
                                data: [
                                    {
                                        name: 'active',
                                        path: 'account:products:digiRent:active'
                                    },
                                    {
                                        name: 'history',
                                        path: 'account:products:digiRent:history'
                                    }
                                ]
                            }
                        },
                        {
                            name: 'efkStake',
                            path: 'account:products:efkStake',
                            icon: 'box-alt',
                            subgroup: {
                                i18nPrefix: 'bo.products.efkStake.menu',
                                data: [
                                    {
                                        name: 'create',
                                        path: 'account:products:efkStake:create'
                                    },
                                    {
                                        name: 'active',
                                        path: 'account:products:efkStake:active'
                                    },
                                    {
                                        name: 'history',
                                        path: 'account:products:efkStake:history'
                                    }
                                ]
                            }
                        },
                        {
                            name: 'efkLiquidity',
                            path: 'account:products:efkLiquidity',
                            icon: 'box-alt',
                            subgroup: {
                                i18nPrefix: 'bo.products.efkLiquidity.menu',
                                data: [
                                    {
                                        name: 'create',
                                        path: 'account:products:efkLiquidity:create'
                                    },
                                    {
                                        name: 'active',
                                        path: 'account:products:efkLiquidity:active'
                                    },
                                    {
                                        name: 'history',
                                        path: 'account:products:efkLiquidity:history'
                                    }
                                ]
                            }
                        },
                        {
                            name: 'efkPurchase',
                            path: 'account:products:efkPurchase',
                            icon: 'box-alt',
                            subgroup: {
                                i18nPrefix: 'bo.products.efkPurchase.menu',
                                data: [
                                    {
                                        name: 'create',
                                        path: 'account:products:efkPurchase:create'
                                    },
                                    {
                                        name: 'active',
                                        path: 'account:products:efkPurchase:active'
                                    },
                                    {
                                        name: 'history',
                                        path: 'account:products:efkPurchase:history'
                                    }
                                ]
                            }
                        },
                        {
                            name: '4trans',
                            path: 'account:products:4trans',
                            icon: 'box-alt',
                            subgroup: {
                                i18nPrefix: 'bo.products.4trans.menu',
                                data: [
                                    {
                                        name: 'create',
                                        path: 'account:products:4trans:create'
                                    },
                                    {
                                        name: 'active',
                                        path: 'account:products:4trans:active'
                                    },
                                    {
                                        name: 'history',
                                        path: 'account:products:4trans:history'
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
            // {
            //     name: 'detail',
            //     path: 'account:detail',
            //     icon: 'box-alt'
            // },
        ]
    },
    productsTabs: {
        type: 'selection',
        i18nPrefix: 'bo.products.menu',
        data: [
            {
                name: 'efkPurchaseStake',
                title: 'EFK Nákup a Stake',
                path: 'account:products:efkPurchaseStake',
                icon: 'box-alt',
                shortcut: 'eps'
            },
            {
                name: 'efkLiquidity',
                title: 'EFK Likvidita',
                path: 'account:products:efkLiquidity',
                icon: 'box-alt',
                shortcut: 'el'
            },
            {
                name: 'efkStake3',
                title: 'EFK Stake III',
                path: 'account:products:efkStake',
                icon: 'box-alt',
                shortcut: 'es'
            },
            {
                name: 'efkStakeRfi',
                title: 'EFK Stake RFI',
                path: 'account:products:efkStakeRfi',
                icon: 'box-alt',
                shortcut: 'rfi'
            },
            {
                name: 'efkControlledLiquidity',
                title: 'EFK Řízená Likvidita',
                path: 'account:products:efkControlledLiquidity',
                icon: 'box-alt',
                shortcut: 'ecl'
            }
        ]
    },
    digiRentTabs: {
        type: 'selection',
        i18nPrefix: 'bo.products.tabs',
        data: [
            {
                name: 'active',
                path: 'account:products:digiRent:active'
            },
            {
                name: 'history',
                path: 'account:products:digiRent:history'
            }
        ]
    },
    efkStakeTabs: {
        type: 'selection',
        i18nPrefix: 'bo.products.tabs',
        data: [
            {
                name: 'create',
                path: 'account:products:efkStake:create'
            },
            {
                name: 'active',
                path: 'account:products:efkStake:active'
            },
            {
                name: 'history',
                path: 'account:products:efkStake:history'
            }
        ]
    },
    efkLiquidityTabs: {
        type: 'selection',
        i18nPrefix: 'bo.products.tabs',
        data: [
            {
                name: 'create',
                path: 'account:products:efkLiquidity:create'
            },
            {
                name: 'active',
                path: 'account:products:efkLiquidity:active'
            },
            {
                name: 'history',
                path: 'account:products:efkLiquidity:history'
            }
        ]
    },
    efkPurchaseTabs: {
        type: 'selection',
        i18nPrefix: 'bo.products.tabs',
        data: [
            {
                name: 'create',
                path: 'account:products:efkPurchase:create'
            },
            {
                name: 'active',
                path: 'account:products:efkPurchase:active'
            },
            {
                name: 'history',
                path: 'account:products:efkPurchase:history'
            }
        ]
    },
    product4trans: {
        type: 'selection',
        i18nPrefix: 'bo.products.tabs',
        data: [
            {
                name: 'create',
                path: 'account:products:4trans:create'
            },
            {
                name: 'active',
                path: 'account:products:4trans:active'
            },
            {
                name: 'history',
                path: 'account:products:4trans:history'
            }
        ]
    }
};

const getters = {
    account: state => state.accountTabs,
    'account:portfolio': state => state.productsTabs,
    'account:products': state => state.productsTabs,
    'account:products:digiRent': state => state.digiRentTabs,
    'account:products:efkStakeRfi': state => state.digiRentTabs,
    'account:products:efkStake': state => state.efkStakeTabs,
    'account:products:efkLiquidity': state => state.efkLiquidityTabs,
    'account:products:efkPurchase': state => state.efkPurchaseTabs,
    'account:products:4trans': state => state.product4trans
};

const actions = {};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
