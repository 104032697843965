<template>
    <app-section-navigation-col bigger-col class="pl-0">
        <app-card class="py-9 box-shadow-none create-transaction-style">
            <app-loader v-if="!productFetched" />

            <validation-observer ref="form4Trans" v-slot="{ invalid }" v-else>
                <app-row class="max-width-600">
                    <app-col class="mb-0" full-space>
                        <app-text-field
                            v-model="parameters.purchaseAmount"
                            :rules="{
                                min_value: [10000, 'CZK'],
                                required: true,
                                regex: numberRegex
                            }"
                            :label="$t('bo.products.4trans.create.participationAmount')"
                        >
                            <template #append>
                                CZK
                            </template>
                        </app-text-field>
                    </app-col>
                </app-row>

                <app-text note justify="start">
                    <span
                        v-html="
                            $t('bo.products.4trans.create.totalAppreciation', {
                                amount: formatAmount(totalAppreciation, 'czk'),
                                currency: 'CZK'
                            })
                        "
                    />

                    <span
                        v-html="
                            $t('bo.products.4trans.create.quarterlyInterestPayment', {
                                amount: formatAmount(quarterlyInterestPayment, 'czk'),
                                currency: 'CZK'
                            })
                        "
                    />
                </app-text>

                <app-text note justify="start">
                    <span
                        v-html="
                            $t('bo.products.4trans.create.minimalParticipationAmount', {
                                amount: formatAmount(10000),
                                currency: 'CZK'
                            })
                        "
                    />
                </app-text>

                <app-divider />

                <app-row>
                    <app-col full-space>
                        <app-switch-field
                            :centered="$vuetify.breakpoint.xsOnly"
                            button-events
                            v-model="acceptTerms"
                            @button:click="openProductDocument"
                            :button-loading="productDocumentButtonLoading"
                        >
                            {{ $t('bo.products.4trans.create.agree') }}

                            <template v-slot:button>
                                {{ $t('bo.products.download.trans4ProductConditions') }}
                            </template>
                        </app-switch-field>
                    </app-col>
                </app-row>

                <app-row>
                    <app-col full-space>
                        <button-wrapper :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'start'">
                            <vt-button-primary
                                full-width
                                @click="submit"
                                :disabled="invalid || !acceptTerms || !userVerified"
                                :loading="submitButtonLoading"
                            >
                                {{ $t('bo.products.4trans.create.submit') }}
                            </vt-button-primary>
                        </button-wrapper>
                    </app-col>
                </app-row>
            </validation-observer>
        </app-card>
    </app-section-navigation-col>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { getIntegerNumberRegex } from '@lb-world/core/public/utils/regexp';

    import { ValidationObserver } from 'vee-validate';

    import { DOCUMENT_PRODUCT_SPECS_4TRANS } from '@/constants';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    import AppCard from '../../../ui/AppCard';
    import AppTextField from '../../../ui/form/AppTextField';
    import AppCol from '../../../ui/layout/AppCol';
    import AppSwitchField from '../../../ui/form/AppSwitchField';
    import AppSectionNavigationCol from '@/components/ui/layout/AppSectionNavigationCol';

    export default {
        name: 'CreateTransaction',

        components: {
            AppSectionNavigationCol,
            AppSwitchField,
            AppCol,
            AppTextField,
            AppCard,
            ValidationObserver
        },

        props: ['productId', 'product', 'productFetched'],

        data: () => ({
            parameters: {
                purchaseAmount: ''
            },
            productDocumentButtonLoading: false,
            submitButtonLoading: false,
            acceptTerms: false
        }),

        computed: {
            numberRegex() {
                return getIntegerNumberRegex();
            },

            purchaseAmount() {
                return this.convertLocaleToNumber(this.parameters?.purchaseAmount);
            },

            userVerified() {
                return this.$store.getters['auth/token:role'](UserRoles.VERIFIED);
            },

            totalAppreciation() {
                if (this.purchaseAmount === '' || this.purchaseAmount === 0) {
                    return 0;
                }

                return (this.purchaseAmount / 100) * 15.3;
            },

            quarterlyInterestPayment() {
                if (this.purchaseAmount === '' || this.purchaseAmount === 0) {
                    return 0;
                }

                return this.totalAppreciation / 12;
            }
        },

        methods: {
            hasLimitAvailable(remaining) {
                return remaining === null || remaining - this.purchaseAmount > 0;
            },
            openProductDocument() {
                this.productDocumentButtonLoading = true;

                const windowRef = window.open();

                this.$store
                    .dispatch('documents/document:request', DOCUMENT_PRODUCT_SPECS_4TRANS)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.productDocumentButtonLoading = false;
                    });
            },
            processPurchase() {
                const productId = this.productId;
                const parameters = this.deepCopy(this.parameters);

                parameters.purchaseAmount = this.convertLocaleToNumber(parameters.purchaseAmount);

                this.$store
                    .dispatch('products/PA:open', {
                        parameters,
                        productId
                    })
                    .then(product => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                        this.$store.dispatch('widgets/fetch');

                        this.$emit('transaction:successful', {
                            product: {
                                productName: '4trans',
                                productId: product.id,
                                openedAt: product.openedAt,
                                purchaseAmount: product.purchaseAmount,
                                purchaseCurrency: 'CZK',
                                totalAppreciation: product.totalAppreciation,
                                interestRate: product.interestRate,
                                quarterlyInterestPayment: product.interestPaymentAmount
                            },
                            payment: {
                                paymentIdentifier: product.paymentIdentifier
                            }
                        });

                        this.$refs.form4Trans.reset();

                        this.parameters.purchaseAmount = '';

                        this.acceptTerms = false;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.form4Trans
                    .validate()
                    .then(result => {
                        if (result) {
                            if (this.product?.checkAMLLimit) {
                                this.$log.debug('[AML] This product requires AML check');

                                this.$store
                                    .dispatch('aml/userLimit:fetch')
                                    .then(userLimit => {
                                        const remainingLimit = userLimit?.remainingLimit;

                                        if (this.hasLimitAvailable(remainingLimit)) {
                                            this.processPurchase();
                                        } else if (!this.hasLimitAvailable(remainingLimit)) {
                                            this.$emit('limitDialog:open', {
                                                purchaseAmount: this.purchaseAmount,
                                                remainingLimit
                                            });

                                            this.submitButtonLoading = false;
                                        } else {
                                            EventBus.$emit('snackbar:show:danger', this.$t('general_error'));

                                            this.submitButtonLoading = false;
                                        }
                                    })
                                    .catch(error => {
                                        EventBus.$emit('snackbar:show:danger', error);

                                        this.submitButtonLoading = false;
                                    });
                            } else {
                                this.processPurchase();
                            }
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.submitButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
