<template>
    <validation-observer ref="formCurrencyWithdrawalDialogEFK">
        <app-row justify="center">
            <app-col>
                <p>
                    {{ $t('bo.wallets.currencyWithdrawalDialog.minimumAmount') }}
                    <mark>{{ formatAmount(calculatedMinimumWithdrawAmount, currency) }} {{ currency }} </mark>.
                </p>
                <p>
                    {{ $t('bo.wallets.currencyWithdrawalDialog.walletBalance') }}
                    <mark>
                        {{ formatAmount(walletBalance, currency) }}
                        {{ currency }} </mark
                    >.
                </p>
            </app-col>
        </app-row>

        <app-divider />

        <app-row justify="center">
            <app-col>
                <app-text-field
                    :label="$t('bo.wallets.currencyWithdrawalDialog.receiverAddressETH')"
                    v-model="withdrawal.address"
                    @input="$emit('change', withdrawal)"
                    :rules="{
                        required: true,
                        regex: /^[1-9A-HJ-NP-Za-km-z]{32,44}/
                    }"
                />
            </app-col>
        </app-row>

        <app-row justify="center">
            <app-col>
                <app-text-field
                    :label="$t('bo.wallets.currencyWithdrawalDialog.amount')"
                    v-model="withdrawal.amount"
                    @input="$emit('change', withdrawal)"
                    :rules="{
                        required: true,
                        regex: numberRegex,
                        min_value: [minimalAmountWithdraw, currency],
                        max_value: [walletBalance, currency],
                        decimals: 4
                    }"
                >
                    <template #append>
                        {{ currency }}
                    </template>
                </app-text-field>
            </app-col>
        </app-row>

        <app-text justify="center">
            <div class="d-flex align-center justify-center">
                <p v-html="$t('bo.wallets.currencyWithdrawalDialog.totalFee')" class="mb-0 mr-1" />

                <mark class="mb-0">0 EFK.</mark>
            </div>

            <p v-html="$t('bo.wallets.currencyWithdrawalDialog.totalFeeInformation')" />
        </app-text>

        <app-row justify="center">
            <app-col>
                <p
                    v-html="
                        $t('bo.wallets.currencyWithdrawalDialog.totalSent', {
                            amount: formatAmount(calculateTotalSentAmount, currency),
                            currency
                        })
                    "
                />
            </app-col>
        </app-row>

        <app-row justify="center">
            <app-col>
                <p>
                    Ujistěte se, že jste zadali SOL adresu, na kterou Vám EFK tokeny bezpečně přijdou.
                </p>
                <p>
                    Pokud vybíráte EFK tokeny na tokenovou burzu, musí být token na této burze zalistován, aby Vám
                    tokeny dorazily. EFK je v současné době zalistován na burzách Lbank, BitForex a BitMart. V případě,
                    že si nejste jisti, zda jste zadali správnou adresu, kontaktujte nás neprodleně na
                    podpora@lbworld.eu.
                </p>
            </app-col>
        </app-row>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { mapGetters } from 'vuex';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import AppCol from '@/components/ui/layout/AppCol';
    import AppTextField from '@/components/ui/form/AppTextField';

    export default {
        name: 'WithdrawalEFK',

        components: { AppTextField, AppCol, ValidationObserver },

        props: ['minimalAmountWithdraw', 'walletBalance', 'currency', 'withdrawal'],

        computed: {
            ...mapGetters('wallets', ['fees', 'feesFetched', 'feesError']),

            feeEFK() {
                return this.fees?.find(f => f.currency === 'EFK')?.fee;
            },
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },
            calculatedMinimumWithdrawAmount() {
                return this.feeEFK
                    ? this.feeEFK > this.minimalAmountWithdraw
                        ? this.feeEFK
                        : this.minimalAmountWithdraw
                    : this.minimalAmountWithdraw;
            },
            withdrawalAmount() {
                return this.convertLocaleToNumber(this.withdrawal.amount);
            },
            calculateTotalSentAmount() {
                const sentAmount = (this.withdrawalAmount ?? 0) - this.feeEFK;

                return sentAmount && sentAmount > 0 ? sentAmount : 0;
            }
        }
    };
</script>

<style scoped></style>
