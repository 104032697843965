<template>
    <div
        class="letters-icon"
        :class="{ 'let-primary': primary, 'let-secondary': secondary }"
        :style="{ color: color, background: background }"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'LettersIcon',
        props: {
            color: String,
            background: String,
            primary: Boolean,
            secondary: Boolean
        }
    };
</script>

<style scoped lang="scss">
    .letters-icon {
        font-family: Grayfel;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 20px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.16px;
        background: #000;
        border-radius: 8px;
        color: #fff;
    }
    .let-primary {
        color: colors.$color-primary;
        background-color: colors.$color-primary-btn;
    }
    .let-secondary {
        color: colors.$color-secondary;
        background-color: colors.$color-secondary-btn;
    }
</style>
