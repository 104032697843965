<template>
    <v-list>
        <v-list-item-group v-model="model">
            <template v-for="section in sections.data">
                <v-list-item
                    active-class="vt-active-list-item"
                    class="vt-list-item"
                    v-if="!section.subgroup"
                    :key="section.id"
                    link
                    :to="{ name: section.path }"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-icon>
                            <img height="20" :src="getImgUrl(section.name + '-' + active)" alt="" />
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $t(sections.i18nPrefix + '.' + section.name) }}
                        </v-list-item-title>
                    </template>
                </v-list-item>

                <v-list-group v-else sub-group :key="section.id" class="vt-sub-group pl-0" id="product-item">
                    <template v-slot:prependIcon>
                        <img height="20" :src="getImgUrl(section.name + '-true')" class="margin-left-16" />
                    </template>
                    <template v-slot:appendIcon>
                        <font-awesome-icon color="#de5246" :icon="['fas', 'chevron-down']"></font-awesome-icon>
                    </template>
                    <template v-slot:activator>
                        <v-list-item-title>
                            {{ $t(sections.i18nPrefix + '.' + section.name) }}
                        </v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="subgroup in section.subgroup.data"
                        :key="subgroup.id"
                        link
                        :to="{ name: subgroup.path }"
                    >
                        <v-list-item-title class="margin-left-28">
                            {{ $t(section.subgroup.i18nPrefix + '.' + subgroup.name) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </template>
        </v-list-item-group>
    </v-list>
</template>

<script>
    export default {
        name: 'VtSidebarMenu',

        props: ['sections'],

        data() {
            return {
                model: null
            };
        },

        methods: {
            getImgUrl(name) {
                const images = require.context('../../../assets/icons/', false, /\.svg$/);
                return images('./' + name + '.svg');
            }
        }
    };
</script>

<style scoped lang="scss">
    .margin-left-16 {
        margin-left: -16px !important;
    }

    .vt-list-item,
    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        border-radius: 8px;
        overflow: hidden;
        color: rgba(33, 30, 82, 1) !important;
    }
    #product-item .v-list-item {
        margin: 6px;
        border-radius: 8px;
        font-family: Grayfel;
        color: #e4562e !important;
    }
    #product-item .v-list-item__title {
        letter-spacing: 0.1599999964237213px;
        text-align: left;
        color: #e4562e !important;
    }
    .vt-active-list-item {
        border-radius: 8px;
        overflow: hidden;
        background-color: colors.$color-primary-btn !important;
        color: colors.$color-primary !important;
    }
    .vt-sub-group:not(.v-list-group--active) {
        padding: 0;
        background-color: transparent !important;
    }
    .vt-sub-group {
        padding: 0;
        background-color: colors.$color-primary-btn;
        border-radius: 8px;
        overflow: hidden;
    }

    #product-item > div {
        color: #de5246;
        padding-left: 16px !important;
    }
</style>
