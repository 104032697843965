<template>
    <app-card dialog button-switch>
        <app-text justify="center">
            <p class="popover-category">
                {{ $t('bo.wallets.withdrawalConfirm.beSure') }}
            </p>
        </app-text>

        <app-text justify="center">
            {{ $t('bo.wallets.withdrawalConfirm.ifWithdrawal') }}
        </app-text>

        <app-text justify="center">
            {{ $t('bo.wallets.withdrawalConfirm.actualInfo') }}
        </app-text>

        <app-text justify="center">
            {{ $t('bo.wallets.withdrawalConfirm.contactInfo') }}
        </app-text>

        <app-divider class="mt-6" />

        <template #button-secondary>
            <vt-button-secondary @click="$emit('process:prev')">
                {{ $t('bo.wallets.withdrawalConfirm.buttonBack') }}
            </vt-button-secondary>
        </template>

        <template #button-primary>
            <vt-button-primary @click="processFinished" :loading="submitButtonLoading">
                {{ $t('bo.wallets.withdrawalConfirm.buttonWithdrawal') }}
            </vt-button-primary>
        </template>
    </app-card>
</template>

<script>
    export default {
        name: 'WithdrawalConfirmationStep',

        data: () => ({
            submitButtonLoading: false
        }),

        methods: {
            processFinished() {
                this.submitButtonLoading = true;

                this.$emit('process:finished');
            }
        }
    };
</script>

<style scoped></style>
