var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"app-table",attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"hide-default-footer":"","disable-sort":"","page":_vm.pages.current},on:{"update:page":function($event){return _vm.$set(_vm.pages, "current", $event)},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatAmount(item.balance, item.currency)))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.currency))])]}},{key:"item.openedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.openedAt))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration + ' ' + _vm.$tc('common.durations.' + item.durationUnit, item.duration))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"color",rawName:"v-color:[getStateTextColor(item)]",arg:_vm.getStateTextColor(item)}]},[_vm._v(" "+_vm._s(_vm.$t('bo.products.common.accountState.' + item.state))+" ")])]}},{key:"item.interestRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.interestRate)+_vm._s(_vm.interestRateSymbol)+" ")]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])}),_c('vt-table-pagination',_vm._g({staticClass:"mt-5"},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }