<template>
    <v-select
        class="vt-order-select"
        v-model="orderBy"
        dense
        solo
        hide-details
        :items="orderByOptions"
        item-text="label"
        item-value="value"
        @change="$emit('input', $event)"
        background-color="transparent"
    />
</template>

<script>
    export default {
        name: 'VtOrderSelect',
        props: ['value'],
        data() {
            return {
                orderByOptions: [
                    { label: this.$t('table.order.dateDesc'), value: 'createdAt=desc' },
                    { label: this.$t('table.order.dateAsc'), value: 'createdAt=asc' }
                ],
                orderBy: this.value || 'createdAt=desc'
            };
        }
    };
</script>

<style scoped lang="scss"></style>
