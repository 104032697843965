<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('bo.products.editProductDialog.title')" :dialog="dialog" />

            <app-card v-if="dialog.product" dialog>
                <app-text justify="center">
                    <span
                        v-html="
                            $t('bo.products.editProductDialog.productAccountSettings', {
                                productAccount: dialog.product.id
                            })
                        "
                    />
                </app-text>

                <app-divider />

                <app-title justify="center">{{ $t('bo.products.common.' + dialog.productRateType) }}</app-title>

                <app-row>
                    <app-registration-col whole-screen>
                        <p>{{ $t('bo.products.editProductDialog.rateChangeTimeout') }}</p>
                        <p v-if="!dialog.product.rateChangeAllowed">
                            {{ $t('bo.products.editProductDialog.rateChangeNotAvailable') }}
                        </p>
                    </app-registration-col>
                </app-row>

                <app-row v-if="dialog.product.rateChangeAllowed" justify="center" class="mt-3">
                    <app-registration-col field>
                        <validation-observer ref="formEditProductDialogRate">
                            <app-text-field
                                v-model="productRate"
                                :label="$t('bo.products.editProductDialog.rateChangeNewValue')"
                                :rules="rateChangeNewValueRules"
                            >
                                <template #append>
                                    USD/EFK
                                </template>
                            </app-text-field>
                        </validation-observer>

                        <button-primary
                            :loading="productRateButtonLoading"
                            @click="processSettingChangeRate"
                            class="mt-3"
                        >
                            {{ $t('bo.products.editProductDialog.rateChangeSubmit') }}
                        </button-primary>
                    </app-registration-col>
                </app-row>

                <app-divider />

                <app-title justify="center">{{ $t('bo.products.editProductDialog.prolongationTitle') }}</app-title>

                <div v-if="dialog.product.prolongationAllowed">
                    <app-row>
                        <app-registration-col whole-screen>
                            <p>{{ $t('bo.products.editProductDialog.prolongationProductExpires') }}</p>
                            <p>{{ $t('bo.products.editProductDialog.prolongationPossible') }}</p>
                        </app-registration-col>
                    </app-row>

                    <app-row justify="center">
                        <app-registration-col>
                            <button-primary
                                @click="processSettingProlongProduct"
                                :loading="prolongProductButtonLoading"
                            >
                                {{ $t('bo.products.editProductDialog.prolongationSubmit') }}
                            </button-primary>
                        </app-registration-col>
                    </app-row>
                </div>

                <div v-else>
                    <app-row>
                        <app-registration-col whole-screen>
                            <p
                                v-if="isProductExpired(dialog.product)"
                                v-html="
                                    $t('bo.products.editProductDialog.prolongationExpired', {
                                        date: productExpire(dialog.product)
                                    })
                                "
                            />

                            <div v-else>
                                <p
                                    v-if="productExpire(dialog.product) !== null"
                                    v-html="
                                        $t('bo.products.editProductDialog.prolongationExpireDate', {
                                            date: productExpire(dialog.product)
                                        })
                                    "
                                />
                                <p v-else>{{ $t('bo.products.editProductDialog.prolongationExpirationNotSet') }}</p>

                                <p>{{ $t('bo.products.editProductDialog.prolongationNotReadyYet') }}</p>
                            </div>
                        </app-registration-col>
                    </app-row>
                </div>
            </app-card>
        </app-card>
    </v-dialog>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { ValidationObserver } from 'vee-validate';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import AppCard from '../../ui/AppCard';
    import AppTextField from '@/components/ui/form/AppTextField';
    import { PRODUCT_ID_EFK_PURCHASE_STAKE } from '@/constants';

    export default {
        name: 'EditProductDialog',

        props: ['dialog'],

        data: () => ({
            productRate: '',
            productRateMax: '',
            productRateButtonLoading: false,

            prolongProductButtonLoading: false,

            rateRegexp: getDecimalNumberRegex()
        }),

        components: {
            ValidationObserver,
            AppTextField,
            AppCard
        },

        computed: {
            getMinProductRate() {
                return this.dialog?.product?.productId === PRODUCT_ID_EFK_PURCHASE_STAKE
                    ? this.productEfkPurchaseStake?.minPurchaseRate
                    : 0.0001;
            },

            productEfkPurchaseStake() {
                return this.$store.getters['products/products:filter'](PRODUCT_ID_EFK_PURCHASE_STAKE);
            },

            rateChangeNewValueRules() {
                let rules = {
                    required: true,
                    regex: this.rateRegexp,
                    decimals: 4,
                    min_value: this.getMinProductRate
                };

                if (this.dialog.productRateType === 'minSaleRate' && this.productRateMax) {
                    rules.max_value = this.productRateMax;
                }

                return rules;
            }
        },

        methods: {
            isProductExpired(product) {
                return this.productExpire(product) && product.closed;
            },
            productExpire(product) {
                return this.formatTimestamp(product?.expiresAt) ?? null;
            },
            processSettingProlongProduct() {
                this.prolongProductButtonLoading = true;

                this.changeProductSettings({ prolong: true })
                    .then(productAccount => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.productSettingsApplied'));

                        this.$set(this.dialog, 'product', productAccount);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.prolongProductButtonLoading = false;
                    });
            },
            processSettingChangeRate() {
                this.productRateButtonLoading = true;

                this.$refs.formEditProductDialogRate
                    .validate()
                    .then(result => {
                        if (result) {
                            this.changeProductSettings({ changedRate: this.convertLocaleToNumber(this.productRate) })
                                .then(productAccount => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.productSettingsApplied'));

                                    this.$set(this.dialog, 'product', productAccount);
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.productRateButtonLoading = false;
                                });
                        } else {
                            this.productRateButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.productRateButtonLoading = false;
                    });
            },
            changeProductSettings(options) {
                return new Promise((resolve, reject) => {
                    this.$store
                        .dispatch('products/PA:change', {
                            productAccount: this.dialog.product.id,
                            options
                        })
                        .then(productAccount => {
                            EventBus.$emit('snackbar:show:success', this.$t('success.productSettingsApplied'));

                            this.$set(this.dialog, 'product', productAccount);

                            resolve(productAccount);
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);

                            reject();
                        });
                });
            }
        },

        watch: {
            'dialog.open': {
                handler(opened) {
                    if (opened) {
                        this.productRate = this.dialog.product[this.dialog.productRateType];

                        if (this.dialog.productRateType === 'minSaleRate') {
                            this.productRateMax = this.dialog.product[this.dialog.productRateType];
                        } else {
                            this.productRateMax = null;
                        }

                        if (this.$refs.formWithdrawalConfirmationDialog) {
                            this.$refs.formWithdrawalConfirmationDialog.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
