<template>
    <base-overlay
        icon="map-marked-alt"
        :title="$t('overlays.notFound.title')"
        :description="$t('overlays.notFound.description')"
        :additional-description="$t('overlays.notFound.additionalDescription')"
        :button-title="$t('overlays.notFound.button')"
        @button:click="redirectToHomepage"
    />
</template>

<script>
    import BaseOverlay from '@/views/error/BaseOverlay';

    export default {
        name: 'NotFound',

        components: { BaseOverlay },

        methods: {
            redirectToHomepage() {
                this.$router.replace('/');
            }
        }
    };
</script>

<style scoped lang="scss"></style>
