<template>
    <v-progress-linear :value="value" class="app-progress-linear" />
</template>

<script>
    export default {
        name: 'AppProgress',

        props: ['value']
    };
</script>

<style scoped lang="scss">
    .app-progress-linear {
        width: spacing.$spacing-12;
        height: spacing.$spacing-1;

        border-radius: spacing.$spacing-2;
        margin-right: spacing.$spacing-2;

        @include media.resolve('xs-only') {
            margin-bottom: 4px;
        }
    }
</style>
