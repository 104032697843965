import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);

/* Account tab icons */
import {
    faHistory,
    faTh,
    faUserCircle,
    faFileAlt,
    faMoneyCheck,
    faCoin,
    faLeaf,
    faBoxAlt,
    faSignOut,
    faWallet,
    faExchange,
    faCogs,
    faArrowAltUp,
    faArrowAltRight,
    faQuestionCircle,
    faBoxCheck,
    faMapMarkedAlt,
    faWrench,
    faBomb,
    faArrowAltLeft
} from '@fortawesome/pro-duotone-svg-icons';

import { faBars, faExclamationCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';

import {
    faCheckCircle,
    faTimesCircle,
    faChevronDown,
    faTimes,
    faQuestionCircle as fasQuestionCircle
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faBoxCheck,
    faHistory,
    faTh,
    faLeaf,
    faBoxAlt,
    faMoneyCheck,
    faUserCircle,
    faFileAlt,
    faExchange,
    faWallet,
    faCheckCircle,
    faTimesCircle,
    faBars,
    faChevronDown,
    faCoin,
    faSignOut,
    faChevronDown,
    faExclamationCircle,
    faTimes,
    faCogs,
    faArrowAltUp,
    faArrowAltRight,
    faQuestionCircle,
    fasQuestionCircle,
    faMapMarkedAlt,
    faWrench,
    faBomb,
    faArrowAltLeft,
    faSearch
);
