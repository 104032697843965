<template>
    <v-menu v-if="!hide" v-model="menu" offset-y :nudge-width="150" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <button-secondary v-on="on" inline class="select-box-activator user-info-panel" @click="menu = !menu">
                <span class="user-info-panel--email d-flex align-center">
                    <font-awesome-icon
                        size="lg"
                        :icon="['fad', 'user-circle']"
                        class="user-info-panel--user-icon mr-2"
                    />

                    <span v-if="$vuetify.breakpoint.mdAndUp" class="mr-3">
                        {{ username }}
                    </span>

                    <font-awesome-icon size="sm" :icon="['fas', 'chevron-down']" class="user-info-panel--user-icon" />
                </span>
            </button-secondary>
        </template>

        <app-card left class="pa-4">
            <app-text-stack>
                <template #key>
                    {{ $t('topPanel.userId') }}
                </template>

                <span>{{ userId }}</span>
            </app-text-stack>

            <hr class="mt-3" />

            <app-text-stack class="mt-6">
                <template #key>
                    {{ $t('topPanel.username') }}
                </template>

                <div class="menu-user-email">
                    <span class="mr-4">{{ username }}</span>

                    <button-primary small :loading="logoutButton.loading" @click="signOut">
                        {{ $t('topPanel.logOutButton') }}
                    </button-primary>
                </div>
            </app-text-stack>

            <hr class="mt-3" />

            <app-text-stack class="mt-6">
                <template #key>
                    {{ $t('topPanel.version') }}
                </template>

                <span>{{ appVersion }}</span>
            </app-text-stack>
        </app-card>
    </v-menu>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { LOGIN_NAMESPACE_ADMIN } from '@lb-world/core/public/utils/auth';
    import { CURRENT_APP_VERSION } from '@/constants';

    export default {
        name: 'UserInfoPanel',

        data: () => ({
            appVersion: CURRENT_APP_VERSION,

            menu: false,

            logoutButton: {
                loading: false
            }
        }),

        props: {
            hide: {
                type: Boolean
            },
            userId: {
                type: String
            },
            username: {
                type: String
            }
        },

        methods: {
            signOut() {
                this.logoutButton.loading = true;

                const redirectPage =
                    this.$store.getters['auth/token:namespace'] === LOGIN_NAMESPACE_ADMIN ? '/admin' : '/';

                this.$store
                    .dispatch('auth/user:logout')
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.signedOut'));

                        this.$router.push(redirectPage);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.logoutButton.loading = false;
                    });
            }
        }
    };
</script>

<style lang="scss"></style>
