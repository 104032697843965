<template>
    <app-card>
        <product-transactions-digi-rent
            :loading="!productAccountsFetched"
            :items="productAccounts"
            :pages="productAccountsPages"
            :fetch-error="productAccountsError"
            @pagination:change="fetchProductAccounts"
        />
    </app-card>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import ProductTransactionsDigiRent from '@/components/ui/tables/ProductTransactionsDigiRent';

    export default {
        name: 'ActiveTransactions',

        props: ['productFetched', 'product', 'productId'],

        components: { ProductTransactionsDigiRent },

        computed: {
            productAccounts() {
                return this.$store.getters['products/PADr'];
            },
            productAccountsFetched() {
                return this.$store.getters['products/PADr:fetched'];
            },
            productAccountsError() {
                return this.$store.getters['products/PADr:error'];
            },
            productAccountsPages() {
                const result = this.$store.getters['products/PADr:pages'];
                this.$emit('getCount', result?.totalCount);
                return result;
            }
        },

        methods: {
            fetchProductAccounts(page) {
                this.$store.dispatch('products/PADr:clear').then(() => {
                    this.$store
                        .dispatch('products/PADr:fetch', {
                            productId: this.productId,
                            page: page
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            this.$store.dispatch('products/PADr:clear').then(() => {
                this.fetchProductAccounts();
            });
        }
    };
</script>

<style scoped></style>
