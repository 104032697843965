var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formCurrencyWithdrawalDialogEFK"},[_c('app-row',{attrs:{"justify":"center"}},[_c('app-col',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('bo.wallets.currencyWithdrawalDialog.minimumAmount'))+" "),_c('mark',[_vm._v(_vm._s(_vm.formatAmount(_vm.calculatedMinimumWithdrawAmount, _vm.currency))+" "+_vm._s(_vm.currency)+" ")]),_vm._v(". ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('bo.wallets.currencyWithdrawalDialog.walletBalance'))+" "),_c('mark',[_vm._v(" "+_vm._s(_vm.formatAmount(_vm.walletBalance, _vm.currency))+" "+_vm._s(_vm.currency)+" ")]),_vm._v(". ")])])],1),_c('app-divider'),_c('app-row',{attrs:{"justify":"center"}},[_c('app-col',[_c('app-text-field',{attrs:{"label":_vm.$t('bo.wallets.currencyWithdrawalDialog.receiverAddressETH'),"rules":{
                    required: true,
                    regex: /^[1-9A-HJ-NP-Za-km-z]{32,44}/
                }},on:{"input":function($event){return _vm.$emit('change', _vm.withdrawal)}},model:{value:(_vm.withdrawal.address),callback:function ($$v) {_vm.$set(_vm.withdrawal, "address", $$v)},expression:"withdrawal.address"}})],1)],1),_c('app-row',{attrs:{"justify":"center"}},[_c('app-col',[_c('app-text-field',{attrs:{"label":_vm.$t('bo.wallets.currencyWithdrawalDialog.amount'),"rules":{
                    required: true,
                    regex: _vm.numberRegex,
                    min_value: [_vm.minimalAmountWithdraw, _vm.currency],
                    max_value: [_vm.walletBalance, _vm.currency],
                    decimals: 4
                }},on:{"input":function($event){return _vm.$emit('change', _vm.withdrawal)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.currency)+" ")]},proxy:true}]),model:{value:(_vm.withdrawal.amount),callback:function ($$v) {_vm.$set(_vm.withdrawal, "amount", $$v)},expression:"withdrawal.amount"}})],1)],1),_c('app-text',{attrs:{"justify":"center"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('p',{staticClass:"mb-0 mr-1",domProps:{"innerHTML":_vm._s(_vm.$t('bo.wallets.currencyWithdrawalDialog.totalFee'))}}),_c('mark',{staticClass:"mb-0"},[_vm._v("0 EFK.")])]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('bo.wallets.currencyWithdrawalDialog.totalFeeInformation'))}})]),_c('app-row',{attrs:{"justify":"center"}},[_c('app-col',[_c('p',{domProps:{"innerHTML":_vm._s(
                    _vm.$t('bo.wallets.currencyWithdrawalDialog.totalSent', {
                        amount: _vm.formatAmount(_vm.calculateTotalSentAmount, _vm.currency),
                        currency: _vm.currency
                    })
                )}})])],1),_c('app-row',{attrs:{"justify":"center"}},[_c('app-col',[_c('p',[_vm._v(" Ujistěte se, že jste zadali SOL adresu, na kterou Vám EFK tokeny bezpečně přijdou. ")]),_c('p',[_vm._v(" Pokud vybíráte EFK tokeny na tokenovou burzu, musí být token na této burze zalistován, aby Vám tokeny dorazily. EFK je v současné době zalistován na burzách Lbank, BitForex a BitMart. V případě, že si nejste jisti, zda jste zadali správnou adresu, kontaktujte nás neprodleně na podpora@lbworld.eu. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }