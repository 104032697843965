var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"app-table",attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('span',{staticClass:"float-left width-min-8rem",class:_vm.isOutlay(item) ? 'text-red' : 'text-green'},[_vm._v(" "+_vm._s(_vm.formatAmount(item.amount, item.currency))+" "),_c('svg',{staticClass:"mr-4 svg-style",attrs:{"width":"12","height":"13","viewBox":"0 0 12 13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[(_vm.isOutlay(item))?[_c('rect',{attrs:{"x":"12","y":"12.9951","width":"12","height":"12","rx":"3.71429","transform":"rotate(-180 12 12.9951)","fill":"#FEEEEC"}}),_c('path',{attrs:{"d":"M9 5.79613C9 5.92748 8.94812 6.05953 8.84436 6.15994L6.37833 8.55803C6.27812 8.655 6.14238 8.70933 6.00025 8.70933C5.85882 8.70933 5.72309 8.655 5.62288 8.55803L3.15546 6.15993C2.94794 5.95843 2.94794 5.63245 3.15688 5.43094C3.36582 5.23013 3.70339 5.23082 3.91091 5.43232L6.00025 7.46317L8.08963 5.43232C8.29715 5.23082 8.634 5.23013 8.84294 5.43095C8.94812 5.53135 9 5.66409 9 5.79613Z","fill":"#F9461C"}})]:[_c('rect',{attrs:{"y":"0.995117","width":"12","height":"12","rx":"3.71429","fill":"#C6FFEC"}}),_c('path',{attrs:{"d":"M3 8.19411C3 8.06275 3.05188 7.93071 3.15564 7.8303L5.62167 5.4322C5.72188 5.33523 5.85762 5.2809 5.99975 5.2809C6.14118 5.2809 6.27691 5.33523 6.37712 5.4322L8.84454 7.8303C9.05206 8.0318 9.05206 8.35779 8.84312 8.55929C8.63418 8.76011 8.29661 8.75942 8.08909 8.55791L5.99975 6.52706L3.91037 8.55791C3.70285 8.75942 3.366 8.7601 3.15706 8.55929C3.05188 8.45888 3 8.32615 3 8.19411Z","fill":"#309A5D"}})]],2),(item.currency)?_c('img',{staticClass:"mr-1 float-right",attrs:{"height":"14px","alt":item.currency,"src":require('@/assets/icons/crypto/' + item.currency + '.svg')}}):_vm._e()]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"created-at-font"},[_vm._v(" "+_vm._s(_vm.formatTimestamp(item.timestamp))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex flex-row"},[(_vm.isOutlay(item))?_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/transaction-table-red-dot.svg")}}):_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/transaction-table-green-dot.svg")}}),_c('span',{staticClass:"is-outlay-font",class:_vm.isOutlay(item) ? 'text-red' : 'text-green'},[_vm._v(" "+_vm._s(_vm.$t(_vm.isOutlay(item) ? 'table.typeCategories.sell' : 'table.typeCategories.buy'))+" ")])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('bo.transactions.types.' + item.type))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.transferInfo)?_c('v-menu',{attrs:{"offset-y":"","nudge-width":200,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button-secondary',_vm._g({staticClass:"select-box-activator",attrs:{"inline":"","icon":"question-circle"}},on),[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('bo.transactions.transferInfo'))+" ")]):_vm._e()])]}}],null,true)},[_c('app-card',{staticClass:"pa-4",attrs:{"left":""}},[(item.transferInfo.recipient)?_c('app-text-stack',{attrs:{"smaller-value":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.transactions.recipient'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.transferInfo.recipient)+" ")]):_vm._e(),(item.transferInfo.recipient && item.transferInfo.sender)?_c('hr',{staticClass:"mt-3"}):_vm._e(),(item.transferInfo.sender)?_c('app-text-stack',{attrs:{"smaller-value":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.transactions.sender'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.transferInfo.sender)+" ")]):_vm._e(),(item.transferInfo.message)?_c('hr',{staticClass:"mt-3"}):_vm._e(),(item.transferInfo.message)?_c('app-text-stack',{staticClass:"mt-3",attrs:{"smaller-value":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.transactions.message'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.transferInfo.message)+" ")]):_vm._e()],1)],1):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(_vm.fetchError)?_c('app-error',{attrs:{"horizontal":"","fetching":"","no-padding":""}}):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }