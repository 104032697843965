<template>
    <div>
        <div class="align-center d-flex justify-space-between width-250 margin-inline-auto">
            <v-btn
                class="paginator-btn"
                icon
                :disabled="currentPage === 1"
                v-bind="attrs"
                @click="$emit('pagination:change', 1)"
                v-on="on"
            >
                <img src="@/assets/icons/paginator-start.svg" />
            </v-btn>
            <v-btn
                class="paginator-btn"
                icon
                :disabled="currentPage === 1"
                v-bind="attrs"
                @click="$emit('pagination:change', pages.current - 1)"
                v-on="on"
            >
                <img src="@/assets/icons/paginator-left.svg" />
            </v-btn>
            <div class="width-48">
                <input class="paginator-input text-center" v-model="currentPage" type="number" />
            </div>
            <div>{{ $t('table.pagination.from') }} {{ pages.length }}</div>
            <v-btn
                class="paginator-btn"
                icon
                :disabled="currentPage === pages.length"
                v-bind="attrs"
                @click="$emit('pagination:change', pages.current + 1)"
                v-on="on"
            >
                <img src="@/assets/icons/paginator-right.svg" />
            </v-btn>
            <v-btn
                class="paginator-btn"
                icon
                :disabled="currentPage === pages.length"
                v-bind="attrs"
                @click="$emit('pagination:change', pages.length)"
                v-on="on"
            >
                <img src="@/assets/icons/paginator-end.svg" />
            </v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VtTablePagination',

        data: () => ({
            itemsPerPage: 10
        }),

        computed: {
            pages() {
                return this.$parent.pages;
            },
            currentPage: {
                set(nextPage) {
                    this.$emit('pagination:change', nextPage);
                },
                get() {
                    return this.pages.current;
                }
            }
        }
    };
</script>

<style lang="scss"></style>
