<template>
    <div>
        <v-data-table
            class="app-table"
            :loading="loading"
            :items="items"
            :headers="headers"
            hide-default-footer
            disable-sort
            :page.sync="pages.current"
            @click:row="handleClick"
        >
            <template v-slot:item.balance="{ item }">
                <span>{{ formatAmount(item.balance, item.currency) }}</span>
                <span class="ml-1">{{ item.currency }}</span>
            </template>

            <template v-slot:item.openedAt="{ item }">
                {{ formatTimestamp(item.openedAt) }}
            </template>

            <template v-slot:item.state="{ item }">
                <span v-color:[getStateTextColor(item)]>
                    {{ $t('bo.products.common.accountState.' + item.state) }}
                </span>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>
        <vt-table-pagination v-on="$listeners" class="mt-5"></vt-table-pagination>
    </div>
</template>

<script>
    import { core, pagination } from '@lb-world/core/public/components/tables/mixins';
    import VtTablePagination from '@/components/ui/tables/VtTablePagination';

    export default {
        name: 'ProductTransactionsEfkStakeRfi',

        components: { VtTablePagination },

        mixins: [core, pagination],

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.id'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.opened'),
                        value: 'openedAt'
                    },
                    {
                        text: this.$t('table.columns.balance'),
                        value: 'balance'
                    },
                    {
                        text: this.$t('table.columns.state'),
                        value: 'state'
                    }
                ];
            }
        },

        methods: {
            handleClick(item) {
                this.$router.push({
                    path: '/account/detail/dr/' + item.id
                });
            },
            getStateTextColor(item) {
                switch (item.state) {
                    case 'open':
                        return 'success';

                    case 'closed':
                        return 'danger';

                    default:
                        return null;
                }
            }
        }
    };
</script>

<style scoped></style>
