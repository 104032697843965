var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-card',{staticClass:"create-transaction-card create-transaction-style"},[(!_vm.productFetched)?_c('app-loader'):_c('validation-observer',{ref:"formEFKStake",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('app-row',{staticClass:"max-width-600"},[_c('app-col',{attrs:{"full-space":""}},[_c('app-text-field',{attrs:{"rules":{
                        required: true,
                        regex: _vm.numberRegex,
                        max_value: [_vm.walletBalance, _vm.currency],
                        decimals: 4
                    },"label":_vm.$t('bo.products.efkStake.create.bondAmount')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.currency)+" ")]},proxy:true}],null,true),model:{value:(_vm.parameters.purchaseAmount),callback:function ($$v) {_vm.$set(_vm.parameters, "purchaseAmount", $$v)},expression:"parameters.purchaseAmount"}})],1)],1),(!_vm.maxStake)?_c('app-text',{attrs:{"note":"","justify":"start"}},[(_vm.product.minimalPurchaseAmount)?_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.$t('bo.products.efkLiquidity.create.minimalPurchaseAmount', {
                        amount: _vm.formatAmount(_vm.product.minimalPurchaseAmount)
                    })
                )}}):_vm._e(),(_vm.product.maximalPurchaseAmount)?_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.$t('bo.products.efkLiquidity.create.maximalPurchaseAmount', {
                        amount: _vm.formatAmount(_vm.product.maximalPurchaseAmount)
                    })
                )}}):_vm._e()]):_vm._e(),(!_vm.maxStake)?_c('app-divider'):_vm._e(),(!_vm.maxStake)?_c('app-text',{attrs:{"highlight":""}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.common.selectOption'))+" ")]):_vm._e(),(!_vm.maxStake)?_c('app-row',[_c('app-col',{staticClass:"pa-0",attrs:{"full-space":""}},[_c('app-flex',_vm._l((_vm.product.interestCalculationData.rates),function(option){return _c('app-flex-item',{key:option.duration,staticClass:"three-cards-selection"},[_c('button-secondary',{staticClass:"pa-0",attrs:{"card-select":""},on:{"click":function($event){_vm.parameters.duration = option.duration}}},[_c('app-card',{staticClass:"w-100",attrs:{"full-width":"","selected":_vm.parameters.duration === option.duration}},[_c('app-text-stack',{attrs:{"smaller-key":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.products.common.duration'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(option.duration)+" "+_vm._s(_vm.$tc('common.durations.' + _vm.product.durationUnit, option.duration))+" ")]),_c('app-text-stack',{staticClass:"mt-3",attrs:{"smaller-key":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.products.efkStake.create.interest'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.formatAmount(option.interestRate + _vm.volumeBonus))+" "+_vm._s(_vm.product.interestCalculationData.metadata.interestRateSymbol)+" ")])],1)],1)],1)}),1)],1)],1):_vm._e(),(!_vm.maxStake)?_c('app-text',{attrs:{"note":"","justify":"start"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.$t('bo.products.efkStake.create.volumeBonusLabel', {
                        percentage: _vm.formatAmount(_vm.volumePercentage),
                        amount: _vm.formatAmount(_vm.volumeMinimalPurchaseAmount)
                    })
                )}})]):_vm._e(),(!_vm.maxStake)?_c('app-divider'):_vm._e(),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('app-switch-field',{attrs:{"centered":_vm.$vuetify.breakpoint.xsOnly,"button-events":"","button-loading":_vm.productDocumentButton.loading},on:{"button:click":_vm.openProductDocument},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.products.download.efkStakeProductConditions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkStake.create.agree'))+" ")])],1)],1),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('button-wrapper',{attrs:{"justify":_vm.$vuetify.breakpoint.smAndUp ? 'start' : 'center'}},[_c('vt-button-primary',{attrs:{"full-width":"","disabled":invalid || !_vm.acceptTerms || !_vm.parameters.duration || !_vm.userVerified,"loading":_vm.submitButtonLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkStake.create.bondButton'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }