import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import DocumentsRepository from '@lb-world/core/public/api/repositories/DocumentsRepository';

const actions = {
    'document:request': (_, id) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            Vue.$log.debug('[ACTION] UserID is defined', id);

            DocumentsRepository.createDownload(id)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data.url);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

export default {
    namespaced: true,
    state: {},
    actions,
    mutations: {},
    getters: {}
};
