var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-card',{staticClass:"create-transaction-card create-transaction-style"},[(!_vm.productFetched)?_c('app-loader'):_c('validation-observer',{ref:"formEFKStakeRfi",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('app-row',{staticClass:"max-width-600"},[_c('app-col',{attrs:{"full-space":""}},[_c('app-text-field',{attrs:{"rules":{
                        required: true,
                        regex: _vm.numberRegex,
                        max_value: [_vm.maxValue, _vm.currency],
                        min_value: [_vm.minValue, _vm.currency],
                        decimals: 4
                    },"label":_vm.$t('bo.products.efkStake.create.bondAmount')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.currency)+" ")]},proxy:true}],null,true),model:{value:(_vm.parameters.purchaseAmount),callback:function ($$v) {_vm.$set(_vm.parameters, "purchaseAmount", $$v)},expression:"parameters.purchaseAmount"}})],1)],1),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('app-switch-field',{attrs:{"centered":_vm.$vuetify.breakpoint.xsOnly,"button-events":"","button-loading":_vm.productDocumentButton.loading},on:{"button:click":_vm.openProductDocument},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.products.download.efkStakeRfiProductConditions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkStake.create.agree'))+" ")])],1)],1),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('button-wrapper',{attrs:{"justify":_vm.$vuetify.breakpoint.smAndUp ? 'start' : 'center'}},[_c('vt-button-primary',{attrs:{"full-width":"","disabled":invalid || !_vm.acceptTerms || !_vm.userVerified,"loading":_vm.submitButtonLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkStake.create.bondButton'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }