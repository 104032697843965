import Vue from 'vue';

import UserDataset from '@lb-world/core/public/models/store/UserDataset';

import { parseErrorMessageFromAPI } from '@/utils';

import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';

const state = {
    user: new UserDataset()
};

const getters = {
    user: state => state.user.data,
    'user:fetched': state => state.user.fetched,
    'user:id': state => state.user.data.id,
    'user:email': state => state.user.data.email,
    'user:fullName': state => state.user.getFullName(),
    'user:bankAccount': state => state.user.bankAccount,
    'user:digitized': state => state.user.data.allDataDigitized,
    'user:approved': state => state.user.data.identityVerificationState === 'approved',

    'account:activated': (state, getters) => getters['user:digitized'] && getters['user:approved']
};

const actions = {
    update: ({ commit, getters }, { user }) => {
        Vue.$log.debug('[ACTION] Running action with API call', user);

        return new Promise((resolve, reject) => {
            UserRepository.update(getters['user:id'], user)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    commit('update', user);

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    store: (state, data) => {
        state.user.storeData(data);
    },
    update: (state, data) => {
        state.user.updateData(data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
