import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import TableDataset from '@lb-world/core/public/models/store/TableDataset';
import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

import ProductsRepository from '@lb-world/core/public/api/repositories/ProductsRepository';

const state = {
    products: new BaseDataset([]),
    PADetail: new BaseDataset({}),
    PA: new TableDataset(),
    PADr: new TableDataset(),
    PAEs: new TableDataset(),
    PAEsRfi: new TableDataset(),
    PAECL: new TableDataset(),
    PAEp: new TableDataset(),
    PAEps: new TableDataset(),
    PAEl: new TableDataset(),
    PATransactions: new TableDataset()
};

const getters = {
    'products:fetched': state => state.products.fetched,
    'products:error': state => state.products.fetchError,
    'products:filter': state => productId => state.products.data.find(p => p.productId === productId),

    PADetail: state => state.PADetail.data ?? {},
    'PADetail:fetched': state => state.PADetail.fetched,
    'PADetail:error': state => state.PADetail.fetchError,

    PA: state => state.PA.data.items ?? [],
    'PA:fetched': state => state.PA.fetched,
    'PA:error': state => state.PA.fetchError,
    'PA:pages': state => state.PA.getPages(),

    PADr: state => state.PADr.data.items ?? [],
    'PADr:fetched': state => state.PADr.fetched,
    'PADr:error': state => state.PADr.fetchError,
    'PADr:pages': state => state.PADr.getPages(),

    PAEsRfi: state => state.PAEsRfi.data.items ?? [],
    'PAEsRfi:fetched': state => state.PAEsRfi.fetched,
    'PAEsRfi:error': state => state.PAEsRfi.fetchError,
    'PAEsRfi:pages': state => state.PAEsRfi.getPages(),

    PAECL: state => state.PAECL.data.items ?? [],
    'PAECL:fetched': state => state.PAECL.fetched,
    'PAECL:error': state => state.PAECL.fetchError,
    'PAECL:pages': state => state.PAECL.getPages(),

    PAEs: state => state.PAEs.data.items ?? [],
    'PAEs:fetched': state => state.PAEs.fetched,
    'PAEs:error': state => state.PAEs.fetchError,
    'PAEs:pages': state => state.PAEs.getPages(),

    PAEp: state => state.PAEp.data.items ?? [],
    'PAEp:fetched': state => state.PAEp.fetched,
    'PAEp:error': state => state.PAEp.fetchError,
    'PAEp:pages': state => state.PAEp.getPages(),

    PAEps: state => state.PAEps.data.items ?? [],
    'PAEps:fetched': state => state.PAEps.fetched,
    'PAEps:error': state => state.PAEps.fetchError,
    'PAEps:pages': state => state.PAEps.getPages(),

    PAEl: state => state.PAEl.data.items ?? [],
    'PAEl:fetched': state => state.PAEl.fetched,
    'PAEl:error': state => state.PAEl.fetchError,
    'PAEl:pages': state => state.PAEl.getPages(),

    PATransactions: state => state.PATransactions.data.items ?? [],
    'PATransactions:fetched': state => state.PATransactions.fetched,
    'PATransactions:error': state => state.PATransactions.fetchError,
    'PATransactions:pages': state => state.PATransactions.getPages()
};

const actions = {
    'products:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            ProductsRepository.getProducts()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const products = response.data;

                    if (products) {
                        commit('store', { key: 'products', data: products });

                        resolve();
                    } else {
                        commit('error', 'products');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'products');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'products:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'products');

            resolve();
        });
    },
    'PA:open': ({ rootGetters }, { productId, parameters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            ProductsRepository.openPA(rootGetters['user/user:id'], productId, parameters)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PA:change': ({ commit }, { productAccount, options }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productAccount, options);

        return new Promise((resolve, reject) => {
            ProductsRepository.changePA(productAccount, options)
                .then(response => {
                    const productAccount = response.data;

                    Vue.$log.debug('[ACTION] Received response', productAccount);

                    if (productAccount) {
                        commit('replace', { key: 'PA', item: productAccount });

                        resolve(productAccount);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PADetail:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PADetail');

            resolve();
        });
    },
    'PADetail:fetch': ({ commit }, { productId }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId);

        return new Promise((resolve, reject) => {
            ProductsRepository.getPADetail(productId)
                .then(response => {
                    const PADetail = response.data;

                    Vue.$log.debug('[ACTION] Received response', PADetail);

                    if (PADetail) {
                        commit('store', { key: 'PADetail', data: PADetail });

                        resolve();
                    } else {
                        commit('error', 'PADetail');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PADetail');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PA:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PA');

            resolve();
        });
    },
    'PATransactions:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PA:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PA', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PA');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PA');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PADr:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PADr');
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PADr:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PADr', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PADr');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PADr');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PAEs:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PAEs');
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PAEs:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PAEs', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PAEs');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PAEs');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PAEsRfi:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PAEsRfi');
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PAEsRfi:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PAEsRfi', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PAEsRfi');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PAEsRfi');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PAECL:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PAECL');
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PAECL:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PAECL', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PAECL');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PAECL');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PAEp:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PAEp');
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PAEp:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PAEp', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PAEp');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PAEp');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PAEps:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PAEps');
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PAEps:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PAEps', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PAEps');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PAEps');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PAEl:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'PAEl');
            commit('clear', 'PATransactions');

            resolve();
        });
    },
    'PAEl:fetch': ({ commit, rootGetters }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productId, page);

        return new Promise((resolve, reject) => {
            const filters = {
                product: productId
            };

            ProductsRepository.getPA({
                data: { user: rootGetters['user/user:id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PA = response.data;

                    Vue.$log.debug('[ACTION] Received response', PA);

                    if (PA) {
                        commit('store', { key: 'PAEl', data: PA });

                        resolve();
                    } else {
                        commit('error', 'PAEl');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PAEl');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'PATransactions:fetch': ({ commit }, { productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const filters = {
                productAccount: productId
            };

            ProductsRepository.getPATransactions({
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const PATransactions = response.data;

                    Vue.$log.debug('[ACTION] Received response', PATransactions);

                    if (PATransactions) {
                        commit('store', { key: 'PATransactions', data: PATransactions });

                        resolve();
                    } else {
                        commit('error', 'PATransactions');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'PATransactions');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data }) => {
        state[key].storeData(data);
    },
    replace: (state, { key, item }) => {
        state[key].replaceItem(i => i.id === item.id, item);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
