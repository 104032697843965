<template>
    <v-app>
        <app-snackbar />

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppSnackbar from './components/ui/AppSnackbar';

    export default {
        name: 'App',

        components: { AppSnackbar },

        created() {
            this.$log.debug('[SYSTEM] Running in mode: ' + process.env.VUE_APP_MODE);

            EventBus.$on('jwt:refresh', () => {
                this.$log.debug('[JWT] Signal to refresh token received, dispatching action');

                this.$store
                    .dispatch('auth/token:store')
                    .then(({ expiration }) => {
                        this.$store.dispatch('auth/token:refresh', expiration);
                    })
                    .catch(({ error }) => {
                        this.$log.debug('[JWT] Dispatch action to refresh token failed');

                        EventBus.$emit('snackbar:show:danger', error);

                        this.$router.push({ name: 'account:services' });
                    });
            });
        }
    };
</script>

<style lang="scss">
    @import 'scss/main';
</style>
