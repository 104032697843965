<template>
    <v-navigation-drawer
        :value="drawer"
        app
        :touchless="$vuetify.breakpoint.mdAndUp"
        v-click-outside="hideDrawer"
        width="254"
        :permanent="$vuetify.breakpoint.mdAndUp"
        id="v-nav-drawer"
    >
        <v-container class="fill-height d-flex flex-column app-drawer-container">
            <div class="flex-row pa-0 ma-0">
                <v-row align-content-center align-center justify-center>
                    <v-col class="text-center align-self-center">
                        <app-logo logoId="broker" size="md" class="mr-2" />
                    </v-col>
                    <v-col class="text-center align-self-center">
                        <v-btn icon>
                            <img height="24" src="@/assets/logos/lb-world-ring.svg" alt="" />
                        </v-btn>
                    </v-col>
                    <v-col class="text-center align-self-center" v-if="!$store.getters['auth/token:isAdmin']">
                        <vt-app-menu :xs="true" />
                    </v-col>
                </v-row>
            </div>
            <div class="flex-row pa-0 ma-0 full-width">
                <v-row
                    align-content-center
                    align-center
                    justify-center
                    class="full-width d-inline-block ma-0 pa-0 mt-8"
                >
                    <vt-sidebar-menu :sections="sections" />
                </v-row>
            </div>
            <div class="flex-row flex-auto d-flex pa-0 ma-0">
                <div class="full-width d-flex align-self-end ma-0 pa-0">
                    <vt-bottom-sidebar-menu :email="email" :fullname="fullname" />
                </div>
            </div>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
    import { LOGIN_NAMESPACE_ADMIN } from '@lb-world/core/public/utils/auth';
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import VtAppMenu from '../menu/VtAppMenu';
    import VtSidebarMenu from './VtSidebarMenu';
    import VtBottomSidebarMenu from './VtBottomSidebarMenu';

    export default {
        name: 'SideBar',
        components: {
            VtSidebarMenu,
            VtAppMenu,
            VtBottomSidebarMenu
        },
        props: {
            email: String,
            fullname: String,
            sections: Object,
            drawer: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                logoutButton: {
                    loading: false
                },
                group: null
            };
        },
        watch: {
            group() {
                this.$emit('clicked', false);
            }
        },
        methods: {
            signOut() {
                this.logoutButton.loading = true;

                const redirectPage =
                    this.$store.getters['auth/token:namespace'] === LOGIN_NAMESPACE_ADMIN ? '/admin' : '/';

                this.$store
                    .dispatch('auth/user:logout')
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.signedOut'));

                        this.$router.push(redirectPage);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.logoutButton.loading = false;
                    });
            },
            hideDrawer(e) {
                if (e.target.className === 'v-overlay__scrim') {
                    this.$emit('clicked', false);
                }
            },
            activeTab(tab) {
                this.$emit('activeTabFromSideBar', tab);
            }
        }
    };
</script>

<style scoped lang="scss"></style>
