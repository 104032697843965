<template>
    <div>
        <v-data-table
            class="app-table"
            :loading="loading"
            :items="items"
            :headers="headers"
            hide-default-footer
            disable-sort
            :page.sync="pages.current"
        >
            <template v-slot:item.balance="{ item }">
                <div v-if="item.closed" class="d-flex align-center justify-start" @click="handleClick(item)">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <font-awesome-icon class="mr-1" v-color:success :icon="['fad', 'arrow-alt-up']" />

                                <span v-if="!isEmpty(item.totalProceeds)">
                                    {{ formatAmount(item.totalProceeds, item.currency) }}
                                    {{ item.currency }}
                                </span>

                                <span v-else v-color:black.translucent>{{ $t('common.none') }}</span>
                            </span>
                        </template>

                        <span>{{ $t('transactionTypes.product_proceeds') }}</span>
                    </v-tooltip>
                </div>

                <div
                    v-else-if="item.fillProgress !== undefined"
                    class="d-flex align-center justify-start"
                    @click="handleClick(item)"
                >
                    <app-progress :value="item.fillProgress" />

                    <span>{{ item.fillProgress }}%</span>
                </div>

                <span v-else v-color:black.translucent>{{ $t('common.none') }}</span>
            </template>

            <template v-slot:item.saleRate="{ item }">
                <div @click="handleClick(item)">
                    {{ formatAmount(item.saleRate, 'rate') }} <span class="ml-1">USD/EFK</span>
                </div>
            </template>

            <template v-slot:item.expiresAt="{ item }">
                <span v-if="formatTimestamp(item.expiresAt) !== null" @click="handleClick(item)">
                    {{ formatTimestamp(item.expiresAt) }}
                </span>

                <span v-else v-color:black.translucent @click="handleClick(item)">{{ $t('common.notSet') }}</span>
            </template>

            <template v-slot:item.openedAt="{ item }">
                <span @click="handleClick(item)">{{ formatTimestamp(item.openedAt) }}</span>
            </template>

            <template v-slot:item.state="{ item }">
                <span v-color:[getStateTextColor(item)] @click="handleClick(item)">
                    {{ $t('bo.products.common.accountState.' + item.state) }}
                </span>
            </template>

            <template v-slot:item.id="{ item }">
                <span @click="handleClick(item)">{{ item.id }}</span>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>

        <vt-table-pagination v-on="$listeners" class="mt-5" />
    </div>
</template>

<script>
    import { core, pagination } from '@lb-world/core/public/components/tables/mixins';
    import VtTablePagination from '@/components/ui/tables/VtTablePagination';

    import AppProgress from '@/components/ui/AppProgress';

    export default {
        name: 'ProductTransactionsEfkLiquidity',

        components: { AppProgress, VtTablePagination },

        mixins: [core, pagination],

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.id'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.opened'),
                        value: 'openedAt'
                    },
                    {
                        text: this.$t('table.columns.balance'),
                        value: 'balance',
                        align: 'start'
                    },
                    {
                        text: this.$t('table.columns.saleRate'),
                        value: 'saleRate'
                    },
                    {
                        text: this.$t('table.columns.expiresAt'),
                        value: 'expiresAt'
                    },
                    {
                        text: this.$t('table.columns.state'),
                        value: 'state'
                    }
                ];
            }
        },

        methods: {
            handleClick(item) {
                this.$router.push({
                    path: '/account/detail/ep/' + item.id
                });
            },
            openProductEditDialog(item) {
                this.$emit('editProductDialog:open', {
                    product: item,
                    productRateType: 'minSaleRate'
                });
            },
            getStateTextColor(item) {
                switch (item.state) {
                    case 'open':
                        return 'success';

                    case 'closed':
                        return 'danger';

                    default:
                        return null;
                }
            }
        }
    };
</script>

<style scoped></style>
