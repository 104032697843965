<template>
    <v-btn
        depressed
        :small="small"
        :class="{
            'full-width': fullWidth,
            inline,
            'vt-small': small,
            iconButton
        }"
        class="vt-button-primary primary--text"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <font-awesome-icon v-if="icon" :icon="[iconSet, icon]" :class="{ 'mr-2': !iconButton }"></font-awesome-icon>
        <slot> </slot>
    </v-btn>
</template>

<script>
    export default {
        name: 'VtButtonPrimary',

        props: {
            small: {
                type: Boolean
            },
            fullWidth: {
                type: Boolean
            },
            inline: {
                type: Boolean
            },
            iconButton: {
                type: Boolean
            },
            iconSet: {
                type: String,
                default: 'fad'
            },
            icon: {
                type: String
            }
        }
    };
</script>

<style scoped lang="scss">
    .vt-button-primary {
        font-weight: 600 !important;
        font-size: 14px !important;
        border-radius: 8px;
        padding: 22px 0 !important;
        background-color: colors.$color-primary-btn !important;
    }
    .vt-small {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
</style>
