<template>
    <div v-if="!$store.getters['user/account:activated']" class="activate-account-container">
        <div>
            <img height="32" class="mr-4" src="@/assets/icons/activate-account-doc.svg" />
        </div>
        <span class="font-banner-title">{{ $t('ActivateAccountBanner.title') }}</span>
        <div class="divider" />
        <span class="font-banner-text">
            {{ $t('ActivateAccountBanner.info') }}
            <a @click="redirectToLbWorld" class="font-size-700">{{ $t('ActivateAccountBanner.clickHere') }}</a>
        </span>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { getAppUrlFormat } from '@/utils';

    export default {
        name: 'ActivateAccountBanner',

        computed: {
            ...mapGetters('user', {
                user: 'user'
            })
        },

        methods: {
            redirectToLbWorld() {
                window.location = getAppUrlFormat('world', '/account/documents');
            }
        }
    };
</script>

<style scoped lang="scss"></style>
