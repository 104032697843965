var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-section-navigation-col',{staticClass:"pl-0",attrs:{"bigger-col":""}},[_c('app-card',{staticClass:"py-9 box-shadow-none create-transaction-style"},[(!_vm.productFetched)?_c('app-loader'):_c('validation-observer',{ref:"formEFKLiquidityValue",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isBankAccountFilled)?_c('p',{staticStyle:{"background-color":"rgba(248,172,157,0.8)","color":"#de5346","padding":"12px 8px","margin-left":"12px","margin-right":"12px","margin-bottom":"24px","border-radius":"4px"}},[_vm._v(" Před provedením transakce je nutné vyplnit svůj bankovní účet. ")]):_vm._e(),_c('app-row',{staticClass:"max-width-600"},[_c('app-col',{staticClass:"mb-0",attrs:{"full-space":""}},[_c('app-text-field',{attrs:{"type":"number","step":_vm.product.purchaseAmountStep,"rules":{
                            required: true,
                            regex: _vm.numberRegex,
                            min_value: [_vm.product.minimalPurchaseAmount, _vm.currency],
                            decimals: 2
                        },"label":_vm.$t('bo.products.efkLiquidity.create.sellAmount')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.currency)+" ")]},proxy:true}],null,true),model:{value:(_vm.parameters.purchaseAmount),callback:function ($$v) {_vm.$set(_vm.parameters, "purchaseAmount", $$v)},expression:"parameters.purchaseAmount"}}),_c('div',{staticClass:"mt-4"},[_c('app-text-field',{attrs:{"type":"number","step":_vm.product.saleRateStep,"rules":{ required: true, regex: _vm.rateRegexp, decimals: 4, min_value: 0.0001 },"label":_vm.$t('bo.products.common.minSaleRate')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" USD/EFK ")]},proxy:true}],null,true),model:{value:(_vm.parameters.minSaleRate),callback:function ($$v) {_vm.$set(_vm.parameters, "minSaleRate", $$v)},expression:"parameters.minSaleRate"}})],1),(_vm.product.minimalPurchaseAmount)?_c('p',{staticClass:"text-note text-start",domProps:{"innerHTML":_vm._s(
                            _vm.$t('bo.products.efkLiquidity.create.minimalPurchaseAmount', {
                                amount: _vm.formatAmount(_vm.product.minimalPurchaseAmount)
                            })
                        )}}):_vm._e(),(_vm.product.maximalPurchaseAmount)?_c('p',{staticClass:"text-note text-start",domProps:{"innerHTML":_vm._s(
                            _vm.$t('bo.products.efkLiquidity.create.maximalPurchaseAmount', {
                                amount: _vm.formatAmount(_vm.product.maximalPurchaseAmount)
                            })
                        )}}):_vm._e()],1)],1),_c('app-divider'),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('app-switch-field',{attrs:{"button-events":"","centered":_vm.$vuetify.breakpoint.xsOnly,"button-loading":_vm.productDocumentButtonLoading},on:{"button:click":_vm.openProductDocument},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('bo.products.download.efkLiquidityProductConditions'))+" ")]},proxy:true}],null,true),model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkLiquidity.create.agree'))+" ")])],1)],1),_c('app-row',[_c('app-col',{attrs:{"full-space":""}},[_c('button-wrapper',{attrs:{"justify":_vm.$vuetify.breakpoint.xsOnly ? 'center' : 'start'}},[_c('vt-button-primary',{attrs:{"full-width":"","disabled":invalid || !_vm.acceptTerms || !_vm.userVerified || !_vm.isBankAccountFilled,"loading":_vm.submitButtonLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.efkLiquidity.create.submit'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }