<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('bo.wallets.purchase')" :dialog="dialog" />

            <app-card dialog>
                <create-transaction
                    :product-id="productId"
                    :product="product"
                    :product-fetched="productFetched"
                    show-transaction-subtype
                    @transaction:successful="$emit('transaction:successful', $event)"
                    @limitDialog:open="$emit('limitDialog:open', $event)"
                    @editProductDialog:open="$emit('editProductDialog:open', $event)"
                />
            </app-card>

            <template #button-primary>
                <button-primary>
                    {{ $t('bo.wallets.currencyTransferDialog.confirm') }}
                </button-primary>
            </template>
        </app-card>
    </v-dialog>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';
    import { PRODUCT_ID_EFK_PURCHASE } from '@/constants';
    import { mapGetters } from 'vuex';

    import AppCard from '../../ui/AppCard';
    import CreateTransaction from '../../../components/module-user/products/efkPurchase/CreateTransaction';

    export default {
        name: 'CurrencyTransferDialog',

        props: ['dialog'],

        components: {
            CreateTransaction,
            AppCard
        },

        computed: {
            ...mapGetters('products', {
                productFilter: 'products:filter',
                productFetched: 'products:fetched',
                productError: 'products:error'
            }),
            product() {
                return this.productFilter(PRODUCT_ID_EFK_PURCHASE);
            },
            productFetched() {
                return this.$store.getters['products/products:fetched'];
            },
            productError() {
                return this.$store.getters['products/products:error'];
            }
        },

        methods: {
            fetchData() {
                this.$store.dispatch('products/products:clear').then(() => {
                    this.$store.dispatch('products/products:fetch').catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });

                this.$store.dispatch('transactions/transactions:clear');
            }
        },

        data: () => ({
            productId: PRODUCT_ID_EFK_PURCHASE
        }),
        mounted() {
            if (!this.productsFetched) {
                this.fetchData();
            }
        }
    };
</script>

<style scoped></style>
