<template>
    <v-col v-if="auto" class="col-auto">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="buttons" class="col-12 col-md-auto">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="fullSpace && half" class="col-md-6 col-sm-12 col-12">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="fullSpace" class="col-12">
        <slot> </slot>
    </v-col>

    <v-col
        v-else
        :class="[
            'col-12',
            'col-sm-' + this.base * 5,
            'col-md-' + this.base * 4,
            'col-lg-' + this.base * 4,
            'col-xl-' + this.base * 3
        ]"
    >
        <slot> </slot>
    </v-col>
</template>

<script>
    export default {
        name: 'AppCol',

        props: {
            half: {
                type: Boolean
            },
            fullSpace: {
                type: Boolean
            },
            buttons: {
                type: Boolean
            },
            auto: {
                type: Boolean
            }
        },

        computed: {
            base() {
                return this.half ? 1 : 2;
            }
        }
    };
</script>

<style scoped></style>
