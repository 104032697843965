<template>
    <div class="flex-box-container__item">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'AppFlexItem'
    };
</script>

<style scoped></style>
