<template>
    <v-btn
        :small="small"
        text
        v-bind="$attrs"
        v-on="$listeners"
        class="vt-button-custom"
        :class="[
            'justify-' + justify,
            'v-btn--secondary',
            {
                'full-width': fullWidth,
                inline,
                form,
                cardSelect,
                iconButton
            }
        ]"
        tag="button"
        :color="color"
        :style="{ 'background-color': bg }"
    >
        <font-awesome-icon v-if="icon" :icon="[iconSet, icon]" :class="{ 'mr-2': !iconButton }"></font-awesome-icon>
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: 'VtButtonSecondary',

        props: {
            small: {
                type: Boolean
            },
            centered: {
                type: Boolean,
                default: false
            },
            iconSet: {
                type: String,
                default: 'fad'
            },
            bg: {
                type: String,
                default: 'transparent'
            },
            iconButton: {
                type: Boolean
            },
            icon: {
                type: String
            },
            fullWidth: {
                type: Boolean
            },
            inline: {
                type: Boolean
            },
            form: {
                type: Boolean
            },
            justify: {
                type: String,
                default: 'center'
            },
            color: {
                type: String,
                default: '#fffaaa'
            },
            cardSelect: {
                type: Boolean
            }
        }
    };
</script>

<style scoped lang="scss">
    .vt-button-custom {
        font-weight: 600 !important;
        font-size: 14px !important;
        border-radius: 8px;
        padding: 22px 0 !important;
    }
</style>
