import Vue from 'vue';

import { API_INTERNAL_ERROR, parseErrorMessageFromAPI } from '@/utils';

import { parseLabel } from '@/components/ui/charts/utils';
import { BASE_CRYPTO_CURRENCY } from '@/constants';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

import Currencies from '@lb-world/core/public/utils/currencies';

import WalletRepository from '@lb-world/core/public/api/repositories/WalletRepository';

const state = {
    wallets: new BaseDataset(),
    fees: new BaseDataset()
};

const getters = {
    fees: state => state.fees.data,
    feesFetched: state => state.fees.fetched,
    feesError: state => state.fees.fetchError,

    'wallets:count': state => Object.keys(state.widget.data)?.length ?? 2,
    'wallets:fetched': state => state.wallets.fetched,
    'wallets:error': state => state.wallets.fetchError,
    'wallets:data': state => state.wallets.data?.items,

    'wallet:chartFetched': (state, getters, _, rootGetters) => {
        return walletContext => {
            return (
                rootGetters['exchangeRates/rateHistory'](walletContext)?.fetched ??
                walletContext.walletCurrency === BASE_CRYPTO_CURRENCY
            );
        };
    },
    'wallet:chartData': (state, getters, _, rootGetters) => {
        return ({ baseCurrency, quoteCurrency, walletCurrency }) => {
            const rateHistory = rootGetters['exchangeRates/rateHistory']({ baseCurrency, quoteCurrency })?.data?.rates;

            if (!rateHistory) {
                return;
            }

            const labelSet = [];
            const dataSet = [];

            for (const [label, data] of Object.entries(rateHistory)) {
                labelSet.push(parseLabel(label));
                dataSet.push(data);
            }

            return {
                labels: labelSet,
                datasets: [
                    {
                        data: dataSet,
                        fill: false,
                        borderColor: Currencies.getColor(walletCurrency),
                        borderWidth: 3
                    }
                ]
            };
        };
    }
};

const actions = {
    clear: ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', 'wallets');

            resolve();
        });
    },
    fetch: ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            WalletRepository.get(rootGetters['user/user:id'])
                .then(response => {
                    const userWallets = response.data;

                    Vue.$log.debug('[ACTION] Received response', userWallets);

                    if (userWallets) {
                        commit('storeWallets', userWallets);

                        resolve();
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'wallets');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    transfer: ({ rootGetters }, data) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const userId = rootGetters['user/user:id'];

            if (userId) {
                Vue.$log.debug('[ACTION] UserID is defined', userId);

                WalletRepository.transfer(data)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        if (response.data) {
                            resolve();
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] UserID is not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    withdrawal: ({ rootGetters }, data) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            WalletRepository.withdrawal(rootGetters['user/user:id'], data)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve();
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'fee:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            WalletRepository.getFees()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'fees', data: response.data });

                        resolve();
                    } else {
                        commit('error', 'fees');
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'fees');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

export const sortWallets = wallets => {
    return wallets.sort((a, b) => {
        if (a.currency === BASE_CRYPTO_CURRENCY) {
            return 1;
        } else if (b.currency === BASE_CRYPTO_CURRENCY) {
            return -1;
        } else if (a.currency.length < b.currency.length) {
            return -1;
        } else if (a.currency.length > b.currency.length) {
            return 1;
        }

        return 0;
    });
};

const mutations = {
    store: (state, { key, data }) => {
        state[key].storeData(data);
    },
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    storeWallets: (state, data) => {
        data.items = sortWallets(data.items);

        state.wallets.storeData(data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
