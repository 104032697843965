<template>
    <v-col v-if="half" class="col-lg-6 col-md-6 col-sm-12 col-12">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="full" cols="col-lg-10 col-md-12 col-sm-9 col-12">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="button" cols="col-lg-10 col-md-6 col-sm-9 col-12">
        <slot> </slot>
    </v-col>

    <v-col v-else class="pa-0">
        <slot></slot>
    </v-col>
</template>

<script>
    export default {
        name: 'AppSignCol',

        props: {
            half: {
                type: Boolean
            },

            full: {
                type: Boolean
            },

            button: {
                type: Boolean
            }
        }
    };
</script>

<style lang="scss" scoped></style>
