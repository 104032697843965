import Vue from 'vue';

import { parseErrorMessageFromAPI } from '@/utils';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import WidgetsRepository from '@lb-world/core/public/api/repositories/WidgetsRepository';

import { BASE_CRYPTO_CURRENCY } from '@/constants';

const state = {
    widgets: new BaseDataset(),
    productsWidgets: {
        data: [
            {
                name: 'efkPurchaseStake',
                shortcut: 'eps'
            },
            {
                name: 'efkLiquidity',
                shortcut: 'el'
            },
            {
                name: 'efkStake',
                shortcut: 'es'
            },
            {
                name: 'efkStake3',
                shortcut: 'es'
            },
            {
                name: 'efkStakeRfi',
                shortcut: 'rfi'
            },
            {
                name: 'efkControlledLiquidity',
                shortcut: 'ecl'
            },
            {
                name: 'digiRent',
                shortcut: 'dr'
            }
        ]
    },
    portfolioWidgets: {
        data: [
            {
                name: 'efkPurchaseStake',
                shortcut: 'eps'
            },
            {
                name: 'efkPurchase',
                shortcut: 'ep'
            },
            {
                name: 'efkLiquidity',
                shortcut: 'el'
            },
            {
                name: 'efkStake',
                shortcut: 'es'
            },
            {
                name: 'digiRent',
                shortcut: 'dr'
            },
            {
                name: 'efkStakeRfi',
                shortcut: 'rfi'
            },
            {
                name: 'efkControlledLiquidity',
                shortcut: 'ecl'
            }
        ]
    }
};

const getters = {
    'account:products': state => state.productsWidgets,
    'account:portfolio': state => state.portfolioWidgets,
    'data:transactions': state => state.widgets.data?.lastTransactions,
    'data:balances': state => sortWalletBalances(state.widgets.data?.balances),
    'data:balances:count': state => Object.keys(state.widgets.data?.balances)?.length ?? 2,
    'data:balances:wallet': state => wallet => state.widgets.data?.balances[wallet],
    dataFetched: state => state.widgets.fetched,
    dataError: state => state.widgets.fetchError
};

const actions = {
    fetch: ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            WidgetsRepository.getOverview(rootGetters['user/user:id'])
                .then(response => {
                    const widgetData = response.data;

                    Vue.$log.debug('[ACTION] Received response', widgetData);

                    if (widgetData) {
                        commit('store', { key: 'widgets', data: widgetData });
                    }

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'widgets');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    clear: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'widgets');

            resolve();
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data }) => {
        state[key].storeData(data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export const sortWalletBalances = wallets => {
    return Object.fromEntries(
        Object.entries(wallets).sort((a, b) => {
            if (a[0] === BASE_CRYPTO_CURRENCY) {
                return 1;
            } else if (b[0] === BASE_CRYPTO_CURRENCY) {
                return -1;
            } else if (a[0].length < b[0].length) {
                return -1;
            } else if (a[0].length > b[0].length) {
                return 1;
            }

            return 0;
        })
    );
};
