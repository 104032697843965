<template>
    <v-snackbar top v-model="snackbar" :timeout="timeout" :max-width="300" :min-width="300" light>
        <div class="d-flex">
            <span>
                <font-awesome-icon v-color:[iconColor] class="mr-2" size="lg" :icon="['fas', icon]" />
            </span>

            <span>
                {{ text }}
            </span>
        </div>

        <template #action="{ attrs }">
            <button-secondary v-if="action.title" small v-bind="attrs" @click="actionConfirmed(action)">
                {{ $t('snackbar.actions.' + action.title) | textUppercase }}
            </button-secondary>

            <button-secondary v-else small v-bind="attrs" @click="snackbar = false">
                {{ $t('common.close') | textUppercase }}
            </button-secondary>
        </template>
    </v-snackbar>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import Vue from 'vue';

    import i18n from '@lb-world/core/public/plugins/i18n';

    import { createCustomErrorKey } from '@/utils';

    export default {
        name: 'AppSnackbar',

        data: () => ({
            snackbar: false,
            timeout: 4000,
            type: '',
            text: '',
            action: {
                title: '',
                hookId: '',
                params: {}
            }
        }),

        filters: {
            textUppercase(value) {
                return value.toUpperCase();
            }
        },

        computed: {
            icon() {
                switch (this.type) {
                    case 'success':
                        return 'check-circle';

                    case 'confirmation':
                        return 'question-circle';

                    default:
                        return 'times-circle';
                }
            },
            iconColor() {
                return this.type;
            }
        },

        methods: {
            actionConfirmed({ hookId, params }) {
                EventBus.$emit(hookId, params);

                this.snackbar = false;
            },
            appendInternalCode(payload) {
                return payload.internalCode ? ' (' + payload.internalCode + ')' : '';
            },
            showSnackbar(payload) {
                const focusedElement = this.$el.querySelector(':focus');

                if (focusedElement !== null && focusedElement) {
                    focusedElement.blur();
                }

                this.$log.info('[APP] Showing snackbar', payload);

                this.text = payload.text;
                this.type = payload.type;

                if (payload?.action) {
                    this.$set(this, 'action', payload.action);
                } else {
                    this.$set(this, 'action', {});
                }

                if (this.text) {
                    this.snackbar = true;
                }
            }
        },

        mounted() {
            EventBus.$on('snackbar:show', payload => {
                if (payload.type && payload.text) {
                    this.showSnackbar(payload);
                }
            });

            EventBus.$on('snackbar:show:success', payload => {
                if (payload) {
                    this.showSnackbar({
                        text: payload,
                        type: 'success'
                    });
                }
            });

            EventBus.$on('snackbar:show:confirmation', payload => {
                this.$log.info('[APP] Showing confirmation snackbar', payload);

                if (payload && payload.text && payload.hookId) {
                    this.showSnackbar({
                        text: payload.text,
                        type: 'confirmation',
                        action: {
                            title: 'confirm',
                            hookId: payload.hookId,
                            params: payload.params
                        }
                    });
                }
            });

            EventBus.$on('snackbar:show:danger', payload => {
                if (payload) {
                    Vue.$log.debug('[APP] Preparing to show snackbar', payload);

                    if (typeof payload === 'string') {
                        payload = { errorKey: payload };
                    }

                    const { errorKey, attributes } = payload;

                    let translateKey = 'errors.' + errorKey;

                    if (attributes && attributes.length > 0 && i18n.te(createCustomErrorKey(errorKey, attributes[0]))) {
                        translateKey = createCustomErrorKey(errorKey, attributes[0]);
                    }

                    this.showSnackbar({
                        text: i18n.t(translateKey) + this.appendInternalCode(payload),
                        type: 'danger'
                    });
                }
            });
        }
    };
</script>

<style lang="scss">
    .v-snack {
        z-index: 2000 !important;

        .v-snack__wrapper {
            background-color: white !important;

            @include media.resolve('xs-only') {
                max-width: 290px;
                min-width: 290px;
            }

            .v-snack__content {
                @include text.TextRegular-16-100;
            }
        }
    }
</style>
