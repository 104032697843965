<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="800px" @click:outside="dialog = false">
        <template v-if="productId !== 'EFK_PURCHASE'" v-slot:activator="{ on, attrs }">
            <vt-button-primary :small="small" v-bind="attrs" v-on="on" class="mt-2 ml-2 px-4">
                <img height="17" class="mr-9" src="@/assets/icons/products-cart.svg" />
                <span class="mr-11">
                    {{ $t('bo.dashboard.widgets.buyProductsWidget.miniCards.buyBtn') }}
                </span>
            </vt-button-primary>
        </template>
        <v-card class="v-card-style">
            <div class="d-flex flex-column mt-3">
                <div v-if="heading != null" class="d-flex mt-4 ml-4">
                    <l-icon class="product-icon-dimension ml-3" v-if="iconText != null" secondary>{{
                        iconText
                    }}</l-icon>
                    <div class="ml-3 align-self-center heading-font">
                        {{ heading }}
                    </div>
                </div>
                <div class="d-flex">
                    <slot v-bind="{ maxStake, disableVolume }" />
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import { PRODUCT_ID_EFK_STAKE } from '@/constants';

    export default {
        name: 'CreateTransactionDialog',
        props: {
            small: {
                type: Boolean,
                default: false
            },
            iconText: {
                type: String,
                default: null
            },
            heading: {
                type: String,
                default: null
            },
            productId: String,
            disableVolume: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                dialog: false,
                maxStake: false
            };
        },
        mounted() {
            if (this.$route.query.stake && this.productId === PRODUCT_ID_EFK_STAKE) {
                this.maxStake = true;
                this.dialog = true;
            }
        }
    };
</script>

<style scoped></style>
