<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('bo.products.productSummaryDialog.title')" :dialog="dialog" />

            <app-card v-if="dialog.product" dialog>
                <app-row>
                    <app-registration-col whole-screen>
                        <font-awesome-icon class="mr-1" v-color:broker :icon="['fad', 'box-check']" size="2x" />
                    </app-registration-col>
                </app-row>

                <app-title justify="center">{{ $t('bo.products.productSummaryDialog.purchased') }}</app-title>

                <app-text justify="center">
                    <span
                        v-html="
                            $t('bo.products.productSummaryDialog.purchasedDescription', {
                                product: $t('bo.products.' + productName + '.title')
                            })
                        "
                    />
                </app-text>

                <app-row justify="center" v-if="allowedProducts.includes(productName)">
                    <app-registration-col whole-screen>
                        <component
                            :is="'summary_' + productName"
                            :product="dialog.product"
                            :payment="dialog.payment"
                            :panels="panels"
                        />
                    </app-registration-col>
                </app-row>

                <app-divider :bottom-margin="0" />

                <template #button-primary>
                    <button-primary @click="dialog.open = false">
                        {{ $t('bo.products.productSummaryDialog.close') }}
                    </button-primary>
                </template>
            </app-card>
        </app-card>
    </v-dialog>
</template>

<script>
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';

    import SummaryEFKPurchase from '@/components/module-user/products/purchaseOverviewDialog/SummaryEFKPurchase';
    import SummaryEFKStake from '@/components/module-user/products/purchaseOverviewDialog/SummaryEFKStake';
    import SummaryEFKLiquidity from '@/components/module-user/products/purchaseOverviewDialog/SummaryEFKLiquidity';
    import SummaryEFKControlledLiquidity from '@/components/module-user/products/purchaseOverviewDialog/SummaryEFKControlledLiquidity.vue';
    import Summary4Trans from '@/components/module-user/products/purchaseOverviewDialog/Summary4Trans';

    export default {
        name: 'PurchaseSummaryDialog',

        components: {
            AppFlex,
            AppFlexItem,

            summary_efkPurchase: SummaryEFKPurchase,
            summary_efkStake: SummaryEFKStake,
            summary_efkLiquidity: SummaryEFKLiquidity,
            summary_efkControlledLiquidity: SummaryEFKControlledLiquidity,
            summary_4trans: Summary4Trans
        },

        props: ['dialog'],

        data: () => ({
            allowedProducts: ['efkPurchase', 'efkStake', 'efkLiquidity', '4trans'],
            panels: []
        }),

        computed: {
            productName() {
                return this.dialog.product?.productName;
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (!opened) {
                        this.panels = [];
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
