var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"app-table",attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"hide-default-footer":"","disable-sort":"","page":_vm.pages.current},on:{"update:page":function($event){return _vm.$set(_vm.pages, "current", $event)}},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.closed)?_c('div',{staticClass:"d-flex align-center justify-start",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('font-awesome-icon',{directives:[{name:"color",rawName:"v-color:success",arg:"success"}],staticClass:"mr-1",attrs:{"icon":['fad', 'arrow-alt-up']}}),(!_vm.isEmpty(item.totalProceeds))?_c('span',[_vm._v(" "+_vm._s(_vm.formatAmount(item.totalProceeds, item.currency))+" "+_vm._s(item.currency)+" ")]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(_vm._s(_vm.$t('common.none')))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('transactionTypes.product_proceeds')))])])],1):(item.fillProgress !== undefined)?_c('div',{staticClass:"d-flex align-center justify-start",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('app-progress',{attrs:{"value":item.fillProgress}}),_c('span',[_vm._v(_vm._s(item.fillProgress)+"%")])],1):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(_vm._s(_vm.$t('common.none')))])]}},{key:"item.saleRate",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(" "+_vm._s(_vm.formatAmount(item.saleRate, 'rate'))+" "),_c('span',{staticClass:"ml-1"},[_vm._v("USD/EFK")])])]}},{key:"item.expiresAt",fn:function(ref){
var item = ref.item;
return [(_vm.formatTimestamp(item.expiresAt) !== null)?_c('span',{on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(" "+_vm._s(_vm.formatTimestamp(item.expiresAt))+" ")]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}],on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(_vm._s(_vm.$t('common.notSet')))])]}},{key:"item.openedAt",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(_vm._s(_vm.formatTimestamp(item.openedAt)))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"color",rawName:"v-color:[getStateTextColor(item)]",arg:_vm.getStateTextColor(item)}],on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(" "+_vm._s(_vm.$t('bo.products.common.accountState.' + item.state))+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(_vm._s(item.id))])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])}),_c('vt-table-pagination',_vm._g({staticClass:"mt-5"},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }