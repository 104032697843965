<template>
    <app-card class="create-transaction-card create-transaction-style">
        <app-loader v-if="!productFetched" />

        <validation-observer ref="formEFKStakeRfi" v-slot="{ invalid }" v-else>
            <app-row class="max-width-600">
                <app-col full-space>
                    <app-text-field
                        v-model="parameters.purchaseAmount"
                        :rules="{
                            required: true,
                            regex: numberRegex,
                            max_value: [maxValue, currency],
                            min_value: [minValue, currency],
                            decimals: 4
                        }"
                        :label="$t('bo.products.efkStake.create.bondAmount')"
                    >
                        <template #append>
                            {{ currency }}
                        </template>
                    </app-text-field>
                </app-col>
            </app-row>

            <app-row>
                <app-col full-space>
                    <app-switch-field
                        :centered="$vuetify.breakpoint.xsOnly"
                        button-events
                        v-model="acceptTerms"
                        @button:click="openProductDocument"
                        :button-loading="productDocumentButton.loading"
                    >
                        {{ $t('bo.products.efkStake.create.agree') }}

                        <template v-slot:button>
                            {{ $t('bo.products.download.efkStakeRfiProductConditions') }}
                        </template>
                    </app-switch-field>
                </app-col>
            </app-row>

            <app-row>
                <app-col full-space>
                    <button-wrapper :justify="$vuetify.breakpoint.smAndUp ? 'start' : 'center'">
                        <vt-button-primary
                            full-width
                            :disabled="invalid || !acceptTerms || !userVerified"
                            @click="submit"
                            :loading="submitButtonLoading"
                        >
                            {{ $t('bo.products.efkStake.create.bondButton') }}
                        </vt-button-primary>
                    </button-wrapper>
                </app-col>
            </app-row>
        </validation-observer>
    </app-card>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';
    import { roundAmount } from '@lb-world/core/public/utils/formatting';

    import { ValidationObserver } from 'vee-validate';

    import { DOCUMENT_PRODUCT_SPECS_EFK_STAKE_RFI } from '@/constants';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    import AppCard from '../../../ui/AppCard';
    import AppTextField from '../../../ui/form/AppTextField';
    import AppCol from '../../../ui/layout/AppCol';
    import AppSwitchField from '../../../ui/form/AppSwitchField';

    export default {
        name: 'CreateTransaction',

        components: {
            AppSwitchField,
            AppCol,
            AppTextField,
            AppCard,
            ValidationObserver
        },

        props: {
            productId: String,
            product: Object,
            productFetched: Boolean
        },

        data: () => ({
            productDocumentButton: {
                loading: false
            },

            submitButtonLoading: false,

            parameters: {
                purchaseAmount: ''
            },

            currency: 'EFK',
            acceptTerms: false
        }),

        computed: {
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },

            userVerified() {
                return this.$store.getters['auth/token:role'](UserRoles.VERIFIED);
            },
            walletBalance() {
                return this.$store.getters['widgets/data:balances:wallet'](this.currency);
            },
            maxValue() {
                return roundAmount(this.walletBalance, 'efk');
            },
            minValue() {
                return this.walletBalance < this.product.minimalPurchaseAmount
                    ? this.product.minimalPurchaseAmount
                    : roundAmount(this.walletBalance, 'efk');
            }
        },

        methods: {
            hasLimitAvailable(remaining, amount) {
                return remaining - amount > 0;
            },
            openProductDocument() {
                this.productDocumentButton.loading = true;

                const windowRef = window.open();

                this.$store
                    .dispatch('documents/document:request', DOCUMENT_PRODUCT_SPECS_EFK_STAKE_RFI + '--' + this.$i18n.locale.substring(0, 2))
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.productDocumentButton.loading = false;
                    });
            },
            processPurchase() {
                const productId = this.productId;
                const parameters = this.deepCopy(this.parameters);

                parameters.purchaseAmount = this.convertLocaleToNumber(parameters.purchaseAmount);

                this.$store
                    .dispatch('products/PA:open', {
                        parameters,
                        productId
                    })
                    .then(product => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                        this.$store.dispatch('widgets/fetch');

                        this.$emit('transaction:successful', {
                            product: {
                                productName: 'efkStakeRfi',
                                productId: product.id,
                                openedAt: product.openedAt,
                                purchaseAmount: product.purchaseAmount,
                                purchaseCurrency: product.purchaseCurrency
                            }
                        });

                        this.$refs.formEFKStakeRfi.reset();

                        this.parameters.purchaseAmount = '';

                        this.acceptTerms = false;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formEFKStakeRfi
                    .validate()
                    .then(result => {
                        if (result) {
                            this.processPurchase();
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.submitButtonLoading = false;
                    });
            }
        },

        mounted() {
            const balances = this.$store.getters['widgets/data:balances'];
            const efkBalanceRfi = balances['EFK'];

            if (efkBalanceRfi) {
                const purchaseAmount = roundAmount(efkBalanceRfi, 'efk').replace('.', ',');
                this.parameters.purchaseAmount = purchaseAmount;
            }
        }
    };
</script>

<style scoped lang="scss"></style>
