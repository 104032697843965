<template>
    <v-expansion-panels v-model="panelsOpened">
        <app-expansion-panel v-if="product" :title="$t('bo.products.productSummaryDialog.purchaseDetailsPanel.title')">
            <app-flex class="tree-items">
                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.purchaseAmount') }}
                        </template>

                        {{ purchaseAmount }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.productId') }}
                        </template>

                        {{ productId }}
                    </app-text-stack>
                </app-flex-item>

                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.openedAt') }}
                        </template>

                        {{ openedAt }}
                    </app-text-stack>
                </app-flex-item>
            </app-flex>

            <app-flex class="tree-items mt-6">
                <app-flex-item class="tree-items">
                    <app-text-stack justify="center">
                        <template #key>
                            {{ $t('bo.products.productSummaryDialog.purchaseDetailsPanel.maxPurchaseRate') }}
                        </template>

                        {{ wmaxPurchaseRate }}
                    </app-text-stack>
                </app-flex-item>
            </app-flex>
        </app-expansion-panel>

        <app-expansion-panel v-if="payment" :title="$t('bo.products.productSummaryDialog.purchasePaymentPanel.title')">
            <app-title justify="center">
                {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.title') }}
            </app-title>

            <app-text justify="center">
                {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.description') }}
            </app-text>

            <app-row justify="center">
                <app-registration-col>
                    <app-card text-white increased-shadow narrow v-color:broker>
                        <app-title text-white justify="center">{{ bankAccount.accountNumber }}</app-title>

                        <app-row justify="center">
                            <app-registration-col>
                                <p class="bank-account-address-color">{{ bankAccount.address1 }}</p>
                            </app-registration-col>
                        </app-row>

                        <app-row justify="center">
                            <app-registration-col>
                                <p class="bank-account-address-color">{{ bankAccount.address2 }}</p>
                            </app-registration-col>
                        </app-row>

                        <app-row justify="center">
                            <app-registration-col>
                                <p class="bank-account-address-color">{{ bankAccount.address3 }}</p>
                            </app-registration-col>
                        </app-row>
                    </app-card>
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col whole-screen>
                    <app-flex>
                        <app-flex-item class="tree-items">
                            <app-text-stack justify="center">
                                <template #key>
                                    {{
                                        $t(
                                            'bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.amount'
                                        )
                                    }}
                                </template>

                                {{ purchaseAmount }}
                            </app-text-stack>
                        </app-flex-item>

                        <app-flex-item class="tree-items">
                            <app-text-stack justify="center">
                                <template #key>
                                    {{
                                        $t(
                                            'bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.paymentIdentifier'
                                        )
                                    }}
                                </template>

                                {{ paymentIdentifier }}
                            </app-text-stack>
                        </app-flex-item>
                    </app-flex>
                </app-registration-col>
            </app-row>

            <app-row>
                <app-registration-col whole-screen>
                    <app-text-stack justify="center">
                        <template #key>
                            {{
                                $t(
                                    'bo.products.productSummaryDialog.purchasePaymentPanel.paymentInformation.paymentNote'
                                )
                            }}
                        </template>

                        Vytvoření obchodního účtu LBworld, {{ userName }}
                    </app-text-stack>
                </app-registration-col>
            </app-row>

            <app-divider />

            <app-title justify="center">{{
                $t('bo.products.productSummaryDialog.purchasePaymentPanel.attention.title')
            }}</app-title>

            <app-row justify="center">
                <app-registration-col whole-screen>
                    <p>
                        {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.attention.moneyReturn1') }}
                    </p>
                    <p>
                        {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.attention.moneyReturn2') }}
                    </p>
                </app-registration-col>
            </app-row>

            <app-divider />

            <app-row>
                <app-registration-col whole-screen>
                    <p>
                        {{ $t('bo.products.productSummaryDialog.purchasePaymentPanel.copyInEmail') }}
                    </p>
                </app-registration-col>
            </app-row>
        </app-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'SummaryEFKPurchase',

        props: ['product', 'payment', 'panels'],

        data: () => ({
            _panels: [],
            bankAccount: {
                accountNumber: '1682800017/2260',
                address1: 'NEY spořitelní družstvo',
                address2: 'Rohanské nábřeží 671/15',
                address3: '186 00 Praha 8'
            }
        }),

        computed: {
            panelsOpened: {
                get() {
                    return this.panels;
                },
                set() {
                    this.$emit('update:panels', this.$data._panels);
                }
            },

            userName() {
                return this.$store.getters['user/user:fullName'];
            },
            productId() {
                if (this.isEmpty(this.product?.productId)) {
                    return '-';
                }

                return this.product?.productId;
            },
            openedAt() {
                if (this.isEmpty(this.product?.openedAt)) {
                    return '-';
                }

                return this.formatTimestamp(this.product?.openedAt);
            },
            purchaseAmount() {
                if (this.isEmpty(this.product?.purchaseAmount) || this.isEmpty(this.product?.purchaseCurrency)) {
                    return '-';
                }

                const purchaseAmount = this.product?.purchaseAmount;
                const purchaseCurrency = this.product?.purchaseCurrency;

                return this.formatAmount(purchaseAmount, purchaseCurrency) + ' ' + purchaseCurrency;
            },
            paymentIdentifier() {
                if (this.isEmpty(this.payment?.paymentIdentifier)) {
                    return '-';
                }

                return this.payment?.paymentIdentifier;
            },
            maxPurchaseRate() {
                if (this.isEmpty(this.product?.maxPurchaseRate)) {
                    return '-';
                }

                return this.formatAmount(this.product?.maxPurchaseRate, 'rate') + ' USD/EFK';
            }
        },

        components: { AppExpansionPanel, AppFlex, AppFlexItem }
    };
</script>

<style scoped>
    .bank-account-address-color {
        color: #f0f0f0;
    }
</style>
