<template>
    <v-container>
        <v-row class="align-items-center pa-5">
            <v-col cols="12" sm="6">
                <div class="d-inline-flex">
                    <l-icon class="product-icon-dimension" primary background="#f0dbff" color="#be30e0">ECL</l-icon>
                    <div class="pl-5 align-self-center portfolio-products-font26">
                        {{ $t('bo.products.efkControlledLiquidity.title') }}
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6" class="portfolio-products-col-flexstuff">
                <div>
                    <div class="portfolio-products-purchased-title">
                        {{ $t('bo.products.portfolio.purchased') }}
                    </div>
                    <div class="portfolio-products-how-many-text">
                        {{ count }} {{ $t('bo.products.portfolio.products') }}
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="place-content-center">
            <app-error v-if="productError" no-padding horizontal fetching />
        </v-row>
        <v-row class="place-content-center px-5">
            <app-loader v-if="!productFetched">
                {{ $t('common.loadingProduct') }}
            </app-loader>
            <active
                v-else
                class="full-width pa-0 portfolio-products-active"
                :product-id="productId"
                :product="product"
                :product-fetched="productFetched"
                @getCount="count = $event || 0"
                @transaction:successful="$emit('transaction:successful', $event)"
                @editProductDialog:open="$emit('editProductDialog:open', $event)"
            />
        </v-row>
    </v-container>
</template>

<script>
    import ActiveTransactions from '../../../../components/module-user/products/efkControlledLiquidity/VtActiveTransactions';

    import { DOCUMENT_PRODUCT_INTRO_EFK_CONTROLLED_LIQUIDITY, PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY } from '@/constants';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'EFKControlledLiquidity',

        components: {
            active: ActiveTransactions
        },

        data: () => ({
            count: 0,

            tabIndex: 0,

            productId: PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY,

            pdfTeaserButton: {
                loading: false
            }
        }),

        computed: {
            tabs() {
                return this.$store.getters['tabs/account:products:efkLiquidity'];
            },
            currentTab() {
                return this.tabs.data[this.tabIndex].name;
            },
            product() {
                return this.$store.getters['products/products:filter'](PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY);
            },
            productFetched() {
                return this.$store.getters['products/products:fetched'];
            },
            productError() {
                return this.$store.getters['products/products:error'];
            }
        },

        methods: {
            openPDFTeaser() {
                this.pdfTeaserButton.loading = true;

                const windowRef = window.open();

                this.$store
                    .dispatch('documents/document:request', DOCUMENT_PRODUCT_INTRO_EFK_CONTROLLED_LIQUIDITY)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.pdfTeaserButton.loading = false;
                    });
            },
            tabChange(id) {
                this.tabIndex = id;
            }
        }
    };
</script>

<style scoped></style>
