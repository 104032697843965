import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import AMLRepository from '@lb-world/core/public/api/repositories/AMLRepository';

const actions = {
    'userLimit:fetch': ({ rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.getUserLimit(rootGetters['user/user:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

export default {
    namespaced: true,
    state: {},
    actions,
    mutations: {},
    getters: {}
};
