<template>
    <v-col v-if="wholeScreen && field" class="col-12 col-lg-10 col-md-12 col-sm-11">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="wholeScreen" class="col-12">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="specialWholeScreen" class="col-12 col-lg-12">
        <slot> </slot>
    </v-col>

    <v-col v-else-if="field" class="col-12 col-lg-5 col-md-6 col-sm-11">
        <slot> </slot>
    </v-col>

    <v-col v-else class="col-xl-8 col-lg-9 col-md-10 col-sm-11">
        <slot> </slot>
    </v-col>
</template>

<script>
    export default {
        name: 'AppRegistrationCol',

        props: {
            field: {
                type: Boolean
            },
            specialWholeScreen: {
                type: Boolean
            },
            wholeScreen: {
                type: Boolean
            }
        }
    };
</script>

<style lang="scss"></style>
