<template>
    <div>
        <v-data-table
            class="app-table"
            :loading="loading"
            :items="items"
            :headers="headers"
            hide-default-footer
            disable-sort
        >
            <template v-slot:item.amount="{ item }">
                <span
                    v-if="item.amount"
                    class="float-left width-min-8rem"
                    :class="isOutlay(item) ? 'text-red' : 'text-green'"
                >
                    {{ formatAmount(item.amount, item.currency) }}

                    <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-4 svg-style"
                    >
                        <template v-if="isOutlay(item)">
                            <rect
                                x="12"
                                y="12.9951"
                                width="12"
                                height="12"
                                rx="3.71429"
                                transform="rotate(-180 12 12.9951)"
                                fill="#FEEEEC"
                            />
                            <path
                                d="M9 5.79613C9 5.92748 8.94812 6.05953 8.84436 6.15994L6.37833 8.55803C6.27812 8.655 6.14238 8.70933 6.00025 8.70933C5.85882 8.70933 5.72309 8.655 5.62288 8.55803L3.15546 6.15993C2.94794 5.95843 2.94794 5.63245 3.15688 5.43094C3.36582 5.23013 3.70339 5.23082 3.91091 5.43232L6.00025 7.46317L8.08963 5.43232C8.29715 5.23082 8.634 5.23013 8.84294 5.43095C8.94812 5.53135 9 5.66409 9 5.79613Z"
                                fill="#F9461C"
                            />
                        </template>
                        <template v-else>
                            <rect y="0.995117" width="12" height="12" rx="3.71429" fill="#C6FFEC" />
                            <path
                                d="M3 8.19411C3 8.06275 3.05188 7.93071 3.15564 7.8303L5.62167 5.4322C5.72188 5.33523 5.85762 5.2809 5.99975 5.2809C6.14118 5.2809 6.27691 5.33523 6.37712 5.4322L8.84454 7.8303C9.05206 8.0318 9.05206 8.35779 8.84312 8.55929C8.63418 8.76011 8.29661 8.75942 8.08909 8.55791L5.99975 6.52706L3.91037 8.55791C3.70285 8.75942 3.366 8.7601 3.15706 8.55929C3.05188 8.45888 3 8.32615 3 8.19411Z"
                                fill="#309A5D"
                            />
                        </template>
                    </svg>

                    <img
                        v-if="item.currency"
                        height="14px"
                        :alt="item.currency"
                        class="mr-1 float-right"
                        :src="require('@/assets/icons/crypto/' + item.currency + '.svg')"
                    />
                </span>

                <span v-color:black.translucent v-else>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template v-slot:item.timestamp="{ item }">
                <span class="created-at-font">
                    {{ formatTimestamp(item.timestamp) }}
                </span>
            </template>

            <template v-slot:item.type="{ item }">
                <span class="d-flex flex-row">
                    <img v-if="isOutlay(item)" class="mb-1" src="@/assets/icons/transaction-table-red-dot.svg" />
                    <img v-else class="mb-1" src="@/assets/icons/transaction-table-green-dot.svg" />
                    <span :class="isOutlay(item) ? 'text-red' : 'text-green'" class="is-outlay-font">
                        {{ $t(isOutlay(item) ? 'table.typeCategories.sell' : 'table.typeCategories.buy') }}
                    </span>
                </span>
            </template>

            <template v-slot:item.description="{ item }">
                {{ $t('bo.transactions.types.' + item.type) }}
            </template>

            <template v-slot:item.actions="{ item }">
                <v-menu v-if="item.transferInfo" offset-y :nudge-width="200" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <button-secondary v-on="on" inline class="select-box-activator" icon="question-circle">
                            <span v-if="$vuetify.breakpoint.smAndUp">
                                {{ $t('bo.transactions.transferInfo') }}
                            </span>
                        </button-secondary>
                    </template>

                    <app-card left class="pa-4">
                        <app-text-stack v-if="item.transferInfo.recipient" smaller-value>
                            <template #key>
                                {{ $t('bo.transactions.recipient') }}
                            </template>

                            {{ item.transferInfo.recipient }}
                        </app-text-stack>

                        <hr class="mt-3" v-if="item.transferInfo.recipient && item.transferInfo.sender" />

                        <app-text-stack v-if="item.transferInfo.sender" smaller-value>
                            <template #key>
                                {{ $t('bo.transactions.sender') }}
                            </template>

                            {{ item.transferInfo.sender }}
                        </app-text-stack>

                        <hr class="mt-3" v-if="item.transferInfo.message" />

                        <app-text-stack v-if="item.transferInfo.message" class="mt-3" smaller-value>
                            <template #key>
                                {{ $t('bo.transactions.message') }}
                            </template>

                            {{ item.transferInfo.message }}
                        </app-text-stack>
                    </app-card>
                </v-menu>

                <span v-else v-color:black.translucent>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <app-error v-if="fetchError" horizontal fetching no-padding />
                <p v-else class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { core } from '@lb-world/core/public/components/tables/mixins';

    import AppCard from '@/components/ui/AppCard';

    export default {
        name: 'LastTransactionsHistoryTable',

        components: { AppCard },

        mixins: [core],

        props: {
            onlyTable: {
                type: Boolean,
                default: false
            },
            wallets: {
                type: Object
            },
            orderBy: {
                default: 'createdAt=desc',
                type: String
            },
            searchText: {
                default: '',
                type: String
            },
            walletsLoading: {
                type: Boolean
            }
        },

        methods: {
            isOutlay(item) {
                if (
                    item.type === 'outgoing_transaction' ||
                    item.type === 'outgoing_transfer' ||
                    item.type === 'product_purchase'
                )
                    return true;
                else return false;
            }
        },

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.date'),
                        value: 'timestamp'
                    },
                    {
                        text: this.$t('table.columns.type'),
                        value: 'type'
                    },
                    {
                        text: this.$t('table.columns.description'),
                        value: 'description'
                    },
                    {
                        text: this.$t('table.columns.amount'),
                        value: 'amount',
                        align: 'start'
                    }
                ];
            }
        }
    };
</script>

<style scoped lang="scss"></style>
