<template>
    <v-row :justify="justify">
        <slot></slot>
    </v-row>
</template>

<script>
    export default {
        name: 'AppRow',

        props: {
            justify: {
                type: String,
                default: 'start'
            }
        }
    };
</script>

<style scoped>
    .row {
        margin: 0;
    }
</style>
