<template>
    <v-container fluid class="pa-0">
        <v-container class="app-toolbar-container" flat clipped-left app v-if="!hideToolBar">
            <v-row justify="center" align="center" class="text-center">
                <v-col cols="3" class="pa-0 text-left">
                    <v-btn
                        @click="$emit('drawer:open')"
                        v-if="!hideDrawerPanel"
                        :class="{ 'ml-8': $vuetify.breakpoint.smAndUp, 'ml-4': $vuetify.breakpoint.xs }"
                        icon
                    >
                        <img height="24" src="@/assets/logos/menu.svg" alt="" />
                    </v-btn>
                    <div
                        class="d-inline-flex"
                        :class="{
                            'ml-6': $vuetify.breakpoint.smAndUp && hideDrawerPanel,
                            'ml-2': $vuetify.breakpoint.xs && hideDrawerPanel
                        }"
                        v-if="!hideAppPanel"
                    >
                        <vt-app-menu />
                    </div>
                    <button-secondary
                        class="return-button"
                        v-if="returnButton"
                        icon="arrow-alt-circle-left"
                        inline
                        @click="$router.push('/')"
                    >
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ $t('common.return') }}
                        </span>
                    </button-secondary>
                </v-col>
                <v-col cols="6" class="pa-0">
                    <img class="vertical-align-middle" src="@/assets/logos/lb-broker-black.svg" alt="" />
                </v-col>
                <v-col cols="3" class="pa-0">
                    <vt-lang-menu v-if="$vuetify.breakpoint.sm && hideUserPanel" />
                    <user-info-panel
                        class="vertical-align-middle position-relative"
                        :hide="$vuetify.breakpoint.sm && hideUserPanel"
                        :username="username"
                        :user-id="userId"
                    ></user-info-panel>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import UserInfoPanel from './VtUserInfoPanel';
    import VtLangMenu from '../../components/ui/menu/VtLangMenu';
    import VtAppMenu from '../../components/ui/menu/VtAppMenu';

    export default {
        name: 'VtAppToolbar',

        components: {
            UserInfoPanel,
            VtAppMenu,
            VtLangMenu
        },

        props: {
            hideToolBar: {
                type: Boolean
            },
            returnButton: {
                type: Boolean
            },
            hideUserPanel: {
                type: Boolean
            },
            hideAppPanel: {
                type: Boolean
            },
            hideDrawerPanel: {
                type: Boolean
            },
            userId: {
                type: String
            },
            username: {
                type: String
            }
        },

        data: () => ({
            toolbarHeight: 84,
            drawer: false
        })
    };
</script>

<style lang="scss" scoped></style>
