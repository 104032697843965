import Vue from 'vue';
import VueRouter from 'vue-router';

import AccountLayout from '../views/account/VtAccountLayout';
import Dashboard from '../views/account/sections/VtDashboard';
import Wallets from '../views/account/sections/VtWallets';
import Transactions from '../views/account/sections/Transactions';
import Portfolio from '../views/account/sections/Portfolio';
import Detail from '../views/account/sections/Detail';

import Products from '../views/account/sections/Products';
import ProductsDigiRent from '@/components/module-user/products/digiRent/DigiRent';
import ProductsEFKStake from '@/components/module-user/products/efkStake/EfkStake';
import ProductsEFKLiquidity from '@/components/module-user/products/efkLiquidity/EFKLiquidity';
import ProductsEFKPurchase from '@/components/module-user/products/efkPurchaseStake/EFKPurchaseStake';
import ProductsEFKPurchaseStake from '@/components/module-user/products/efkPurchaseStake/EFKPurchaseStake';
import Products4Trans from '@/components/module-user/products/4trans/4trans';
import ProductsEFKStakeRfi from '@/components/module-user/products/efkStakeRfi/EfkStakeRfi';

import NotFound from '../views/error/VtNotFound';
import Maintenance from '@/views/error/Maintenance';
import ServerError from '@/views/error/ServerError';

import Public from '@/views/Public';

import { userRouting } from '@/middleware/auth';

import { getUserDefaultLanguage, setLanguage } from '@lb-world/core/public/locales/engine';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Public,
        children: [
            {
                path: 'account',
                beforeEnter: userRouting,
                component: AccountLayout,
                children: [
                    {
                        path: 'dashboard',
                        name: 'account:dashboard',
                        component: Dashboard
                    },
                    {
                        path: 'wallets',
                        name: 'account:wallets',
                        component: Wallets
                    },
                    {
                        path: 'transactions',
                        name: 'account:transactions',
                        component: Transactions
                    },
                    {
                        path: 'portfolio',
                        name: 'account:portfolio',
                        component: Portfolio
                    },
                    {
                        path: 'detail/:productId/:id',
                        name: 'account:detail',
                        component: Detail,
                        props: true
                    },
                    {
                        path: 'products',
                        name: 'account:products',
                        component: Products,
                        children: [
                            {
                                path: 'digiRent',
                                name: 'account:products:digiRent',
                                component: ProductsDigiRent
                            },
                            {
                                path: 'efkStake',
                                name: 'account:products:efkStake',
                                component: ProductsEFKStake
                            },
                            {
                                path: 'efkStakeRfi',
                                name: 'account:products:efkStakeRfi',
                                component: ProductsEFKStakeRfi
                            },
                            {
                                path: 'efkLiquidity',
                                name: 'account:products:efkLiquidity',
                                component: ProductsEFKLiquidity
                            },
                            {
                                path: 'efkPurchase',
                                name: 'account:products:efkPurchase',
                                component: ProductsEFKPurchase
                            },
                            {
                                path: 'efkPurchaseStake',
                                name: 'account:products:efkPurchaseStake',
                                component: ProductsEFKPurchaseStake
                            },
                            {
                                path: '4trans',
                                name: 'account:products:4trans',
                                component: Products4Trans
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
    },
    {
        path: '/server-error',
        name: 'serverError',
        component: ServerError
    },
    {
        path: '*',
        name: 'notFound',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    Vue.$log.debug('[LANG] Router: Checking for language present');

    const lang = getUserDefaultLanguage();

    if (lang) {
        Vue.$log.debug('[LANG] Router: Language to be present', lang);

        setLanguage(lang).then(() => next());
    } else {
        Vue.$log.debug('[LANG] Router: No language preference found');

        next();
    }
});

export default router;
