var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 ma-md-5 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"ma-0 pa-0 full-width"},[_c('v-container',{staticClass:"container-card"},[_c('div',{staticClass:"d-flex ma-4"},[_c('img',{attrs:{"src":require("@/assets/icons/rewatch.svg")}}),_c('div',{staticClass:"ml-3 align-self-center transaction-history-font"},[_vm._v(" "+_vm._s(_vm.$t('bo.transactions.transactionsHistory'))+" ")])]),_c('v-row',[_c('v-col'),_c('v-col',{staticClass:"d-flex flex-wrap",class:{
                                'place-center': _vm.$vuetify.breakpoint.xs,
                                'place-end': _vm.$vuetify.breakpoint.smAndUp
                            },attrs:{"cols":"12","lg":"9"}},[_c('div',{staticClass:"d-flex single-line mr-0 ml-4 white-space-nowrap place-items-center"},[_vm._v(" "+_vm._s(_vm.pages.totalCount)+" "+_vm._s(_vm.$t('table.totalCount'))+" ")]),_c('div',{staticClass:"d-flex mr-n2 ml-4 place-content-flex-end"},[_c('vt-table-pagination',_vm._g({},_vm.$listeners))],1)])],1),_c('hr',{staticClass:"mt-6"}),_c('v-data-table',{staticClass:"app-table",attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"hide-default-footer":"","disable-sort":"","page":_vm.pages.current},on:{"update:page":function($event){return _vm.$set(_vm.pages, "current", $event)}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
                            var item = ref.item;
return [(item.amount !== null)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatAmount(item.amount, item.currency)))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.currency))])]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(_vm._s(_vm.$t('common.none')))])]}},{key:"item.createdAt",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.createdAt))+" ")]}},{key:"item.type",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('transactionTypes.' + item.type))+" ")]}},{key:"item.subType",fn:function(ref){
                            var item = ref.item;
return [(item.subType)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('transactionSubtypes.' + item.subType))+" ")]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"item.state",fn:function(ref){
                            var item = ref.item;
return [_c('span',{directives:[{name:"color",rawName:"v-color:[getStateTextColor(item)]",arg:_vm.getStateTextColor(item)}]},[_vm._v(" "+_vm._s(_vm.$t('bo.products.common.accountState.' + item.state))+" ")])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])}),_c('table-pagination',_vm._g({},_vm.$listeners))],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }