<template>
    <v-col cols="12" sm="6" md="12" class="mb-2 prod-mini-card">
        <v-container class="ma-0 pa-0">
            <div class="ma-0 pa-0 d-flex flex-wrap align-content-center justify-space-between">
                <div class="d-flex flex-row col-sm-6 col-md-12 col-lg-6 place-items-center">
                    <l-icon class="mr-4 icon-style" secondary>{{ productItem.shortcut }}</l-icon>
                    <div class="d-flex flex-column">
                        <div>
                            {{ $t('productTypes.DIGI_RENT') }}
                        </div>
                        <div class="mt-1 d-block about-font">
                            {{ $t('bo.dashboard.widgets.buyProductsWidget.miniCards.about') }}
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap flex justify-space-around">
                    <button-primary small class="ma-1 visibility-hidden">
                        {{ $t('bo.dashboard.widgets.buyProductsWidget.miniCards.DigiRentMiniCard.buyEfkBtn') }}
                    </button-primary>
                    <vt-button-secondary small :to="{ name: productItem.path }" class="ma-1">{{
                        $t('bo.dashboard.widgets.buyProductsWidget.miniCards.moreBtn')
                    }}</vt-button-secondary>
                </div>
            </div>
        </v-container>
    </v-col>
</template>

<script>
    export default {
        name: 'DigiRentMiniCard',
        props: {
            productItem: Object
        }
    };
</script>

<style scoped lang="scss"></style>
