<template>
    <div class="vt-bg-container">
        <img src="@/assets/lb-broker-background.svg" />
    </div>
</template>

<script>
    export default {
        name: 'VtAppBackground'
    };
</script>

<style scoped lang="scss"></style>
