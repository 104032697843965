<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="800px">
        <template v-slot:activator="{ on, attrs }">
            <vt-button-secondary v-bind="attrs" v-on="on">{{ $t('table.filter') }}</vt-button-secondary>
        </template>
        <v-card class="transaction-filter-card">
            <div class="d-flex flex-column ma-2">
                <div class="d-flex ma-4">
                    <img src="@/assets/icons/rewatch.svg" />
                    <div class="ml-3 transaction-filter-font20 align-self-center">
                        {{ $t('bo.transactions.filter.filter') }}
                    </div>
                    <div class="flex-auto"></div>
                    <div>
                        <button-secondary @click="resetFilter()" small>{{
                            $t('bo.transactions.filter.reset')
                        }}</button-secondary>
                    </div>
                </div>
                <div class="d-flex ma-4">
                    <span class="transaction-filter-font24">
                        {{ $t('bo.transactions.filter.byCurrency') }}
                    </span>
                </div>
                <div class="d-flex ma-4 flex-wrap">
                    <v-switch
                        v-for="wallet in Object.keys(filters.currency)"
                        :key="wallet"
                        v-model="filters.currency[wallet]"
                        inset
                        :label="wallet"
                        class="mr-6"
                    />
                </div>
                <div v-if="false" class="d-flex ma-4">
                    <span class="transaction-filter-font18">
                        {{ $t('bo.transactions.filter.byProduct') }}
                    </span>
                </div>
                <div v-if="false" class="d-flex ma-4 flex-wrap">
                    <v-switch class="mr-6" v-model="filterValue.products.digi" inset label="Digi Renta" />
                    <v-switch class="mr-6" v-model="filterValue.products.stake" inset label="Efk stake" />
                    <v-switch class="mr-6" v-model="filterValue.products.likv" inset label="Efk likvidita" />
                </div>
                <div class="d-flex ma-4">
                    <span class="transaction-filter-font18">
                        {{ $t('bo.transactions.filter.date') }}
                    </span>
                </div>
                <div class="d-flex ma-4">
                    <app-date-picker
                        class="mt-n4"
                        hide-hint
                        :label="$t('bo.transactions.filter.dateFrom')"
                        placeholder="Vyberte datum"
                        v-model="dateFrom"
                        @keyup.enter="
                            dialog = false;
                            $emit('filter', filters);
                        "
                    ></app-date-picker>
                    <div class="ml-6" />
                    <app-date-picker
                        class="mt-n4"
                        hide-hint
                        :label="$t('bo.transactions.filter.dateTo')"
                        placeholder="Vyberte datum"
                        v-model="dateTo"
                        @keyup.enter="
                            dialog = false;
                            $emit('filter', filters);
                        "
                    ></app-date-picker>
                </div>
                <div class="d-flex ma-4">
                    <span class="transaction-filter-font18">
                        {{ $t('bo.transactions.filter.note') }}
                    </span>
                </div>
                <div class="d-flex ma-4">
                    <app-text-field
                        class="mt-n4 full-width max-width-650"
                        hide-hint
                        :label="$t('bo.transactions.filter.note')"
                        :placeholder="$t('bo.transactions.filter.writeNone')"
                        v-model="filters.message"
                        @keyup.enter="
                            dialog = false;
                            $emit('filter', filters);
                        "
                    >
                    </app-text-field>
                </div>
                <div
                    class="d-flex ma-4 flex-wrap"
                    :style="{ 'place-content': $vuetify.breakpoint.xs ? 'space-evenly' : 'flex-end' }"
                >
                    <vt-button-secondary
                        class="mt-4 mt-sm-0"
                        @click="
                            dialog = false;
                            $emit('cancel');
                        "
                    >
                        {{ $t('bo.transactions.filter.cancel') }}
                    </vt-button-secondary>
                    <vt-button-primary
                        class="ml-sm-6 mt-4 mt-sm-0"
                        @click="
                            dialog = false;
                            $emit('filter', filters);
                        "
                    >
                        {{ $t('bo.transactions.filter.apply') }}
                    </vt-button-primary>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';
    import AppDatePicker from '@/components/ui/form/AppDatePicker';

    export default {
        name: 'VtTransactionsFilter',
        components: {
            AppDatePicker,
            AppTextField
        },
        props: {
            wallets: Object,
            filters: Object
        },
        methods: {
            resetFilter: function() {
                this.filters = {
                    currency: {
                        EFK: true,
                        USDT: true,
                        CZK: true
                    }
                };
                this.dateFrom = null;
                this.dateTo = null;
                this.$emit('filter:reset', this.filters);
                this.dialog = false;
            }
        },
        watch: {
            dateFrom: function(val) {
                this.filters.dateFrom = val ? new Date(val).getTime() / 1000 : null;
            },
            dateTo: function(val) {
                this.filters.dateTo = val ? new Date(val).getTime() / 1000 : null;
            }
        },
        data() {
            return {
                dateTo: null,
                dateFrom: null,
                filterValue: {
                    wallets: {},
                    products: {}
                },
                dialog: false
            };
        }
    };
</script>

<style scoped></style>
