<template>
    <div class="full-width">
        <activate-account-banner />
        <v-container class="ma-0 ma-md-5 pa-0">
            <limit-not-available-dialog :dialog="limitDialog" />
            <edit-product-dialog :dialog="editProductDialog" />
            <purchase-summary-dialog :dialog="purchaseSummaryDialog" />
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                    <v-container v-if="isProductOpened" class="ma-0 pa-0 full-width">
                        <v-container class="container-card pa-0">
                            <router-view />
                        </v-container>
                    </v-container>
                    <v-container v-else class="ma-0 pa-0 full-width">
                        <template>
                            <v-container
                                v-for="widget in productsWidgets.data"
                                :key="widget.name"
                                class="container-card pa-4 mb-10"
                            >
                                <component @limitDialog:open="openLimitDialog" :is="widget.name"></component>
                            </v-container>
                        </template>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import LimitNotAvailableDialog from '@/components/module-user/purchase-limits/LimitNotAvailableDialog';
    import EditProductDialog from '@/components/module-user/products/EditProductDialog';
    import PurchaseSummaryDialog from '@/components/module-user/products/PurchaseSummaryDialog';

    import DigiRent from '@/components/module-user/products/digiRent/DigiRent';
    import EfkStakeRfi from '@/components/module-user/products/efkStakeRfi/EfkStakeRfi';
    import EfkStake from '@/components/module-user/products/efkStake/EfkStake';
    import EfkStake3 from '@/components/module-user/products/efkStake3/EfkStake';
    import EfkPurchase from '@/components/module-user/products/efkPurchase/EfkPurchase';
    import EfkPurchaseStake from '@/components/module-user/products/efkPurchaseStake/EFKPurchaseStake';
    import EfkControlledLiquidity from '@/components/module-user/products/efkControlledLiquidity/EFKControlledLiquidity.vue';
    import EfkLiquidity from '@/components/module-user/products/efkLiquidity/EFKLiquidity';
    import Trans4 from '@/components/module-user/products/4trans/4trans';
    import ActivateAccountBanner from '@/components/ui/ActivateAccountBanner';

    export default {
        name: 'Products',

        components: {
            PurchaseSummaryDialog,
            EditProductDialog,
            LimitNotAvailableDialog,
            DigiRent,
            EfkStakeRfi,
            EfkStake,
            EfkStake3,
            EfkPurchase,
            EfkLiquidity,
            EfkPurchaseStake,
            EfkControlledLiquidity,
            Trans4,
            ActivateAccountBanner
        },

        data: () => ({
            limitDialog: {
                open: false
            },
            editProductDialog: {
                open: false
            },
            purchaseSummaryDialog: {
                open: false
            },
            isProductOpened: false
        }),

        computed: {
            productsWidgets() {
                return this.$store.getters['widgets/account:products'];
            },
            productsTabs() {
                return this.$store.getters['tabs/account:products'];
            },
            productsFetched() {
                return this.$store.getters['products/products:fetched'];
            }
        },

        methods: {
            getProductOpened() {
                if (this.productsFetched) {
                    let result = false;
                    (this.productsTabs.data || []).map(product => {
                        if (product.path === this.$router.currentRoute.name) {
                            result = true;
                        }
                    });
                    this.isProductOpened = result;
                } else {
                    this.isProductOpened = false;
                }
            },
            openProductSummeryDialog({ product, payment }) {
                this.$set(this.purchaseSummaryDialog, 'product', product);
                this.$set(this.purchaseSummaryDialog, 'payment', payment);
                this.purchaseSummaryDialog.open = true;

                this.fetchData();
            },
            openLimitDialog(purchase) {
                this.limitDialog.purchase = purchase;
                this.limitDialog.open = true;
            },
            openProductEditDialog({ product, productRateType }) {
                this.editProductDialog.product = product;
                this.editProductDialog.productRateType = productRateType;
                this.editProductDialog.open = true;
            },
            fetchData() {
                this.$store.dispatch('products/products:clear').then(() => {
                    this.$store
                        .dispatch('products/products:fetch')
                        .then(() => {
                            this.getProductOpened();
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });

                this.$store.dispatch('transactions/transactions:clear');
            }
        },

        updated() {
            this.getProductOpened();
        },

        mounted() {
            if (!this.productsFetched) {
                this.fetchData();
            }
        }
    };
</script>

<style scoped lang="scss"></style>
