<template>
    <v-container class="ma-0 pa-0 full-width fill-height">
        <limit-not-available-dialog :dialog="limitDialog" />
        <v-container class="container-card">
            <v-row>
                <div class="d-flex pa-4">
                    <div class="d-flex">
                        <img class="mt-n1" src="@/assets/icons/eshop.svg" />
                    </div>
                    <div class="d-flex ml-4 buy-products-title">
                        {{ $t('bo.dashboard.widgets.buyProductsWidget.title') }}
                    </div>
                </div>
            </v-row>
            <v-row>
                <component
                    :is="'M' + product.name + 'MiniCard'"
                    v-for="product in productsTabs.data"
                    :key="product.name"
                    :productItem="product"
                    @limitDialog:open="openLimitDialog"
                />
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import MdigiRentMiniCard from '@/components/module-user/products/digiRent/DigiRentMiniCard';
    import MefkStakeMiniCard from '@/components/module-user/products/efkStake/EfkStakeMiniCard';
    import MefkStake3MiniCard from '@/components/module-user/products/efkStake3/EfkStakeMiniCard';
    import MefkStakeRfiMiniCard from '@/components/module-user/products/efkStakeRfi/EfkStakeRfiMiniCard';
    import MefkPurchaseMiniCard from '@/components/module-user/products/efkPurchase/EfkPurchaseMiniCard';
    import MefkLiquidityMiniCard from '@/components/module-user/products/efkLiquidity/EfkLiquidityMiniCard';
    import MefkControlledLiquidityMiniCard from '@/components/module-user/products/efkControlledLiquidity/EfkControlledLiquidityMiniCard.vue';
    import MefkPurchaseStakeMiniCard from '@/components/module-user/products/efkPurchaseStake/EfkPurchaseStakeMiniCard';
    import M4transMiniCard from '@/components/module-user/products/4trans/4transMiniCard';
    import LimitNotAvailableDialog from '@/components/module-user/purchase-limits/LimitNotAvailableDialog';
    export default {
        name: 'VtWidgetProductStore',

        components: {
            LimitNotAvailableDialog,
            MdigiRentMiniCard,
            MefkStakeMiniCard,
            MefkStake3MiniCard,
            MefkPurchaseMiniCard,
            MefkLiquidityMiniCard,
            MefkPurchaseStakeMiniCard,
            MefkControlledLiquidityMiniCard,
            MefkStakeRfiMiniCard,
            M4transMiniCard
        },

        data: () => ({
            limitDialog: {
                open: false
            }
        }),

        computed: {
            productsTabs() {
                return this.$store.getters['tabs/account:products'];
            }
        },

        methods: {
            openLimitDialog(purchase) {
                this.limitDialog.purchase = purchase;
                this.limitDialog.open = true;
            }
        }
    };
</script>

<style scoped lang="scss"></style>
