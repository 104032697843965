export const CURRENT_APP_VERSION = '1.16';
export const CURRENT_APP = 'broker';
export const CURRENT_APP_URL = 'https://lbbroker.eu';
export const CURRENT_APP_DEV_URL = 'https://dev.lbbroker.eu';

// This constant is automatically detected by @lb-world/core package
export const APP_PRIMARY_COLOR = '#de5246';

export const BASE_CURRENCY = 'USD';
export const BASE_CRYPTO_CURRENCY = 'USDT';

export const PRODUCT_ID_DIGI_RENT = 'DIGI_RENT';
export const PRODUCT_ID_EFK_STAKE = 'EFK_STAKE';
export const PRODUCT_ID_EFK_STAKE_RFI = 'EFK_STAKE_RFI';
export const PRODUCT_ID_EFK_LIQUIDITY__VALUE = 'EFK_LIQUIDITY__VALUE';
export const PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY = 'EFK_CONTROLLED_LIQUIDITY';
export const PRODUCT_ID_EFK_PURCHASE = 'EFK_PURCHASE';
export const PRODUCT_ID_EFK_PURCHASE_STAKE = 'EFK_PURCHASE_STAKE';
export const PRODUCT_ID_4TRANS = 'PARTICIPATION_4TRANS';

export const DOCUMENT_PRODUCT_INTRO_DIGI_RENT = 'lbbroker-product-intro--digi-rent';
export const DOCUMENT_PRODUCT_INTRO_EFK_STAKE_RFI = 'lbbroker-product-intro--stake-efk-rfi';
export const DOCUMENT_PRODUCT_INTRO_EFK_STAKE = 'lbbroker-product-intro--stake-efk';
export const DOCUMENT_PRODUCT_INTRO_EFK_STAKE_3 = 'lbbroker-product-intro--stake-efk-iii';
export const DOCUMENT_PRODUCT_INTRO_EFK_LIQUIDITY = 'lbbroker-product-intro--liquidity-time';
export const DOCUMENT_PRODUCT_INTRO_EFK_CONTROLLED_LIQUIDITY = 'lbbroker-product-intro--controlled-liquidity';
export const DOCUMENT_PRODUCT_INTRO_EFK_PURCHASE = 'lbbroker-product-intro--efk-purchase';
export const DOCUMENT_PRODUCT_INTRO_EFK_PURCHASE_STAKE = 'lbbroker-product-intro--efk-purchase-stake';
export const DOCUMENT_PRODUCT_INTRO_4TRANS = 'lbbroker-product-intro--4trans';

export const DOCUMENT_PRODUCT_SPECS_EFK_STAKE = 'lbbroker-product-specs--stake-efk';
export const DOCUMENT_PRODUCT_SPECS_EFK_STAKE_3 = 'lbbroker-product-specs--stake-efk-iii';
export const DOCUMENT_PRODUCT_SPECS_EFK_STAKE_RFI = 'lbbroker-product-specs--stake-efk-rfi';
export const DOCUMENT_PRODUCT_SPECS_EFK_LIQUIDITY = 'lbbroker-product-specs--liquidity-value';
export const DOCUMENT_PRODUCT_SPECS_EFK_CONTROLLED_LIQUIDITY = 'lbbroker-product-specs--controlled-liquidity';
export const DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE = 'lbbroker-product-specs--efk-purchase';
export const DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE_STAKE = 'lbbroker-product-specs--efk-purchase-stake';
export const DOCUMENT_PRODUCT_SPECS_4TRANS = 'lbbroker-product-specs--4trans';
