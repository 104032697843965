<template>
    <div class="full-width">
        <activate-account-banner />
        <v-container class="ma-0 pa-0">
            <v-row class="ma-0 ma-md-5 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                    <vt-efk-wallets-container></vt-efk-wallets-container>
                </v-col>
            </v-row>
            <v-row class="ma-0 ma-md-5 pa-0">
                <v-col cols="12" class="col-md-6 ma-0 pa-0">
                    <vt-widget-product-store></vt-widget-product-store>
                </v-col>
                <v-col cols="12" class="col-md-6 ma-0 pa-0">
                    <vt-widget-last-transactions></vt-widget-last-transactions>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import VtWidgetLastTransactions from '@/components/module-user/dashboard/widgets/VtWidgetLastTransactions';
    import VtWidgetProductStore from '../../../components/module-user/dashboard/widgets/VtWidgetProductStore';
    import ActivateAccountBanner from '@/components/ui/ActivateAccountBanner';
    import VtEfkWalletsContainer from '../../../components/module-user/dashboard/widgets/VtEfkWalletsContainer';

    export default {
        name: 'Dashboard',

        components: {
            VtWidgetLastTransactions,
            VtEfkWalletsContainer,
            VtWidgetProductStore,
            ActivateAccountBanner
        }
    };
</script>

<style scoped></style>
