<template>
    <v-btn
        :small="small"
        text
        v-bind="$attrs"
        v-on="$listeners"
        class="vt-button-secondary"
        :class="[
            'justify-' + justify,
            'v-btn--secondary',
            {
                'full-width': fullWidth,
                inline,
                form,
                cardSelect,
                iconButton
            }
        ]"
    >
        <font-awesome-icon v-if="icon" :icon="[iconSet, icon]" :class="{ 'mr-2': !iconButton }"></font-awesome-icon>
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: 'VtButtonSecondary',

        props: {
            small: {
                type: Boolean
            },
            centered: {
                type: Boolean,
                default: false
            },
            iconSet: {
                type: String,
                default: 'fad'
            },
            iconButton: {
                type: Boolean
            },
            icon: {
                type: String
            },
            fullWidth: {
                type: Boolean
            },
            inline: {
                type: Boolean
            },
            form: {
                type: Boolean
            },
            justify: {
                type: String,
                default: 'center'
            },
            cardSelect: {
                type: Boolean
            }
        }
    };
</script>

<style scoped lang="scss"></style>
