import Vue from 'vue';

import { parseErrorMessageFromAPI } from '@/utils';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

import ExchangeRatesRepository from '@lb-world/core/public/api/repositories/ExchangeRatesRepository';

const state = {
    exchangeRates: new BaseDataset(),
    exchangeRatesHistory: []
};

const getters = {
    rates: state => state.exchangeRates.data,
    ratesFetched: state => state.exchangeRates.fetched,
    ratesError: state => state.exchangeRates.fetchError,

    rateHistory: state => {
        return ({ baseCurrency, quoteCurrency }) => {
            return state.exchangeRatesHistory.find(
                rh => rh.data.baseCurrency === baseCurrency && rh.data.quoteCurrency === quoteCurrency
            );
        };
    }
};

const actions = {
    fetch: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            ExchangeRatesRepository.get()
                .then(response => {
                    const exchangeRates = response.data;

                    Vue.$log.debug('[ACTION] Received response', exchangeRates);

                    if (exchangeRates) {
                        commit('store', { key: 'exchangeRates', data: exchangeRates });
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'exchangeRates');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    clear: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'exchangeRates');

            resolve();
        });
    },
    'history:fetch': ({ commit }, { quoteCurrency, baseCurrency }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            ExchangeRatesRepository.getHistory(quoteCurrency, baseCurrency)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('pushItem', { key: 'exchangeRatesHistory', data: response.data });
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    store: (state, { key, data }) => {
        state[key].storeData(data);
    },

    pushItem: (state, { key, data }) => {
        Vue.$log.debug('[MUTATION] Running mutation', key, data);

        state.exchangeRatesHistory.push({
            fetched: true,
            data
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
