<template>
    <app-card>
        <product-transactions-efk-liquidity
            :loading="!productAccountsFetched"
            :items="productAccounts"
            :pages="productAccountsPages"
            :fetch-error="productAccountsError"
            @pagination:change="fetchProductAccounts"
            @editProductDialog:open="$emit('editProductDialog:open', $event)"
        />
    </app-card>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import ProductTransactionsEfkLiquidity from '@/components/ui/tables/ProductTransactionsEfkLiquidity';

    export default {
        name: 'ActiveTransactions',

        props: ['productFetched', 'product', 'productId'],

        components: { ProductTransactionsEfkLiquidity },

        computed: {
            productAccounts() {
                return this.$store.getters['products/PAEl'];
            },
            productAccountsFetched() {
                return this.$store.getters['products/PAEl:fetched'];
            },
            productAccountsError() {
                return this.$store.getters['products/PAEl:error'];
            },
            productAccountsPages() {
                const result = this.$store.getters['products/PAEl:pages'];
                this.$emit('getCount', result?.totalCount);
                return result;
            }
        },

        methods: {
            fetchProductAccounts(page) {
                this.$store.dispatch('products/PAEl:clear').then(() => {
                    this.$store
                        .dispatch('products/PAEl:fetch', {
                            productId: this.productId,
                            page: page
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            this.$store.dispatch('products/PAEl:clear').then(() => {
                this.fetchProductAccounts();
            });
        }
    };
</script>

<style scoped></style>
